import React from "react";

import FormGroup from "../components/form/FormGroup";
import InputGroup from "../components/form/InputGroup";
import Button from "../components/form/Button";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import axios from "axios";
import Api from "../api";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pseudo: "",
      password: "",
      pseudo_empty: false,
      password_empty: false,
      errors: "",
      isValidUser: false,
      isPending: false,
      isRequestError: false,
      isActive: false,
    };

    this.handlChangeEmail = this.handlChangeEmail.bind(this);
    this.handlChangePassword = this.handlChangePassword.bind(this);
  }

  handlChangeEmail = (e) => {
    this.setState({
      pseudo: e.target.value,
    });
  };

  handlChangePassword = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  validateForm = () => {
    let pseudo = this.state.pseudo;
    let password = this.state.password;
    let isValidForm = true;

    if (!pseudo) {
      isValidForm = false;
      this.setState({
        pseudo_empty: true,
      });
      setTimeout(() => {
        this.setState({
          pseudo_empty: false,
        });
      }, 5000);
    }
    if (!password) {
      isValidForm = false;

      this.setState({
        password_empty: true,
      });

      setTimeout(() => {
        this.setState({
          password_empty: false,
        });
      }, 5000);
    }

    return isValidForm;
  };

  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === "credential_error") {
        this.setState({ isValidUser: true });
      } else if (input === "status_error") {
        this.setState({ isActive: true });
      } else if (input === "requestError") {
        this.setState({ isRequestError: false });
      }
    }, 1000);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    localStorage.clear();
    if (this.validateForm()) {
      this.setState({ isPending: true });

      axios
        .post(`${Api.basUrl}/login`, {
          pseudo: this.state.pseudo,
          password: this.state.password,
        })
        .then((response) => {
          let token = response.data.data?.user?.token;
          
          if (token) {
            let status = response.data.data?.user?.active;
            if (status) {
              localStorage.setItem("tokenCentralDomain", token);
              localStorage.setItem(
                "display_PII", 
                response.data.data?.listTenant.display_PII
                );
              localStorage.setItem(
                "user",
                JSON.stringify(response.data.data.user)
              );
              localStorage.setItem(
                "tenants",
                JSON.stringify(response.data.data.listTenant)
              );
              this.props.history.push("/tenants");
            } else {
              this.setState({
                isPending: false,
                isActive: true,
              });
              this.handleShowAndHideAlert("status_error");
            }
          } else {
            this.setState({
              isPending: false,
              isValidUser: true,
            });
            this.handleShowAndHideAlert("credential_error");
          }
        })
        .catch((error) => {
          this.setState({
            isPending: false,
            isRequestError: true,
          });
          console.log(error);
          this.handleShowAndHideAlert("requestError");
        });
    }
  };

  render() {
    const {
      pseudo,
      password,
      password_empty,
      pseudo_empty,
      isPending,
      isRequestError,
      isValidUser,
      isActive,
    } = this.state;

    return (
      <div className="container max-auto px-2">
        <div className="grid justify-items-center mt-10 mb-2">
          <img className="w-52" src={logo} alt="tet" />
        </div>
        <h1 className="text-3xl font-extrabold px-10 py-6 text-center">
          {" "}
          Se connecter{" "}
        </h1>
        <main className="py-5 rounded-xl bg-white px-6">
          <form onSubmit={this.handleSubmit}>
            <div className="mt-12">
              <FormGroup>
                <InputGroup
                  label="Identifant"
                  type="text"
                  name="pseudo"
                  onChange={(e) => this.handlChangeEmail(e)}
                  value={pseudo}
                  placeholder="E-mail ou numéro de téléphone"
                  error={pseudo_empty}
                  errorText="Ce champ ne peut être vide"
                />
              </FormGroup>

              <FormGroup>
                <Link
                  to="/forgot_password"
                  className=" text-blue pt-2 float-right"
                >
                  {" "}
                  Mot de passe oublié{" "}
                </Link>
                <InputGroup
                  label="Mot de passe"
                  type="password"
                  name="password"
                  onChange={this.handlChangePassword}
                  value={password}
                  placeholder="Votre mot de passe"
                  error={password_empty}
                  errorText="Ce champ ne peut être vide"
                />
              </FormGroup>
              {isValidUser && (
                <div className="bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm">
                  Les informations d 'identification fournies sont incorrectes
                </div>
              )}
              {isActive && (
                <div className="bg-red-200 mt-2 py-2 px-4 text-sm text-red-600 rounded-sm">
                  Votre compte MySyndic a été bloqué.Veuillez vous addresser à
                  votre syndic.
                </div>
              )}
              {isRequestError && (
                <div className="bg-red-200 mt-2 py-2 px-4 text-sm text-red-600 rounded-sm">
                  La connexion a échoué.Veuillez réessayer plus tard.
                </div>
              )}
              {!isPending ? (
                <FormGroup>
                  <Button
                    className="bg-vert_2"
                    text="Se connecter"
                    submit
                    full
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <Button
                    className="bg-blue rounded-lg text-white"
                    text="connexion en cours..."
                    full
                  />
                </FormGroup>
              )}
            </div>
          </form>
        </main>
      </div>
    );
  }
}

export default Login;
