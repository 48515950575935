import axios from "axios";
import React from "react";
import { BsDownload } from "react-icons/bs";
import { Link } from "react-router-dom";
import Api  from "./../../api";
class DownloadLink extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            fileDownloadUrl: null
        }
    }



    download = (attachment_url, fileName) =>{
       let url = attachment_url.split('/').pop()
        axios({
            url: `${Api.tenantUrl}/api/download/${url}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);   
            
        });
    }
    
    render() {
        return (
                <Link className="font-medium text-indigo-600 hover:text-indigo-500"
                    to='#' 
                    onClick={e => this.download(this.props.src, this.props.attachment )}
                    >
                    <BsDownload /> 
                </Link> 
            )
    }
}

export default DownloadLink 