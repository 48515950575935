import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import {
  addReservation,
  deleteReservation,
  updateReservation,
  fetchServices,
  setUnAvailableAreas,
  setAvailableAreas,
} from "../../features/reservations/reservationsSlice";
import { useWindowDimensions } from "../../utils/utils";
import { fetchAreas } from "../../features/reservations/reservationsSlice";
const user_id = localStorage.getItem("user_id");
const building_id = localStorage.getItem("building_id");
const notify = (msg) => toast.error(msg);

export default function CRUDReservation(props) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();
  const info = props.location?.state?.info;
  const [state, setState] = useState({
    id: info?.id === undefined ? null : info?.id,
    step: 0,
    file: null,
    renderImage: null,
    created_by: user_id,
    endDate: info?.end_date === undefined ? "" : info?.end_date,
    endTime: info?.end_time === undefined ? "" : info?.end_time,
    startDate: info?.start_date === undefined ? "" : info?.start_date,
    startTime: info?.start_time === undefined ? "" : info?.start_time,
    description: info?.comment === undefined ? "" : info?.comment,
    nbrParticipant:
      info?.nbr_participant === undefined ? "" : info?.nbr_participant,
    selectedAreas: info?.space === undefined ? [] : [info?.space?.id],
    selectedServices:
      info?.services === undefined
        ? []
        : [...info?.services?.map((item) => item.id)],
  });

  let availableAreas = [
    ...useSelector((state) => state?.reservations?.availableAreas ),
  ]?.sort((x, y) => {
    if (x.level < y.level) {
      return -1;
    }
    if (x.level > y.level) {
      return 1;
    }
    return 0;
  });

  console.log(state?.reservations?.availableAreas);
  let unAvailableAreas = [
    ...useSelector((state) => state.reservations?.unAvailableAreas),
  ]?.sort((x, y) => {
    if (x.level < y.level) {
      return -1;
    }
    if (x.level > y.level) {
      return 1;
    }
    return 0;
  });

  availableAreas = [
    ...availableAreas,
    ...unAvailableAreas.filter((item) => item.id === state.selectedAreas[0]),
  ];
  unAvailableAreas = [
    ...unAvailableAreas.filter((item) => item.id !== state.selectedAreas[0]),
  ];

  const services = [
    ...useSelector((state) => state.reservations?.services),
  ]?.sort((x, y) => {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  });

  function handleChange(event) {
    if (event.target.name === "files") {
      setState((prevState) => ({
        ...prevState,
        file: event.target.files[0],
        renderImage: URL.createObjectURL(event.target.files[0]),
      }));
    } else {
      let fieldName = event.target.name;
      setState((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));
    }
  }

  function setSelectedArea(areaId) {
    if (!state.selectedAreas.includes(areaId)) {
      setState((prevState) => ({
        ...prevState,
        selectedAreas: [areaId],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedAreas: [],
      }));
    }
    dispatch(setUnAvailableAreas(unAvailableAreas));
    dispatch(setAvailableAreas(availableAreas));
  }

  function setSelectedService(serviceId) {
    if (!state.selectedServices.includes(serviceId)) {
      setState((prevState) => ({
        ...prevState,
        selectedServices: [...prevState.selectedServices, serviceId],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedServices: prevState.selectedServices.filter(
          (id) => id !== serviceId
        ),
      }));
    }
  }

  function handleSubmit(event, fieldName) {
    const {
      id,
      created_by,
      startDate,
      endDate,
      startTime,
      endTime,
      nbrParticipant,
      selectedAreas,
      selectedServices,
      description,
    } = state;

    let reservation = {
      declared_by: created_by,
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      nbr_participant: nbrParticipant,
      comment: description,
      space_id: selectedAreas[0],
      service_id: [...selectedServices],
    };

    if (id) {
      if (fieldName === "delete") {
        dispatch(deleteReservation(id));
        history.push("/reservations");
      } else {
        reservation = {
          id,
          ...reservation,
        };
        dispatch(updateReservation(reservation, id));
        history.push("/reservations");
      }
    } else {
      reservation = {
        id,
        ...reservation,
        building_id,
      };
      dispatch(addReservation(reservation));
      history.goBack();
    }
    event.preventDefault();
  }

  function wizard() {
    switch (state.step) {
      case 0:
        return (
          <div className="card-bg h-full w-full border-white border px-2">
            <div className="py-3">
              <div className="flex mb-7 justify-between items-center">
                <span className="font-bold">Du:</span>
                <input
                  type="date"
                  name="startDate"
                  value={state.startDate}
                  onChange={handleChange}
                  className="border-gray-300 w-auto rounded my-2"
                />
                <span className="font-bold">Au:</span>
                <input
                  type="date"
                  name="endDate"
                  value={state.endDate}
                  onChange={handleChange}
                  className="border-gray-300 w-auto rounded my-2"
                />
              </div>
              <div className="flex mb-7">
                <div className="inline-block">
                  <span className="font-bold">Heure de début :</span> <br />
                  <input
                    type="time"
                    name="startTime"
                    value={state.startTime}
                    onChange={handleChange}
                    className="rounded border-gray-300 w-auto my-2"
                  />
                </div>
                <div className="inline-block ml-7">
                  <span className="font-bold">Heure de fin :</span> <br />
                  <input
                    type="time"
                    name="endTime"
                    value={state.endTime}
                    onChange={handleChange}
                    className="rounded border-gray-300 w-auto my-2"
                  />
                </div>
              </div>
              <div className="">
                <label>
                  <span className="font-bold">Nombre de participant :</span>{" "}
                  <br />
                  <input
                    type="number"
                    name="nbrParticipant"
                    value={state.nbrParticipant}
                    onChange={handleChange}
                    className="rounded h-7 border-gray-300 w-16 my-2"
                  />
                </label>
              </div>
            </div>
            <div
              className="items-baseline text-xl font-extrabold mb-16 px-5"
              hidden={state.step > 2}
            >
              <button
                className="text-xl font-extrabold text-blue uppercase px-2"
                onClick={() => wizardHandler("back")}
                hidden={!state.step > 0}
              >
                Précedent
              </button>
              <div
                className="text-xl font-extrabold text-blue uppercase float-right"
                onClick={() => wizardHandler("next")}
                hidden={state.step === 2}
              >
                Suivant
              </div>
              <div
                className="text-xl font-thin text-white uppercase float-right rounded px-3 bg-blue"
                onClick={(e, name) => handleSubmit(e, "submit")}
                hidden={state.step !== 2}
              >
                Soumettre
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="card-bg h-full border-white border px-2">
            <div className="py-3">
              {availableAreas.length === 0 && (
                <span className="font-bold">
                  {`Il n'y a pas d'espace de ${state.nbrParticipant} places disponible pour le moment à la date souhaitée.`}
                </span>
              )}
              <div hidden={availableAreas.length === 0 ? true : false}>
                <span className="font-bold">
                  Espaces disponibles à réserver :
                </span>
                <div className="flex flex-wrap content-start mt-1">
                  {availableAreas.map((item, i) => (
                    <div
                      className="justify-items-center items-center m-1.5 px-2 text-sm inline-block rounded-lg py-0.5 content-center"
                      style={
                        state.selectedAreas.includes(item.id)
                          ? selectedBtn.shape
                          : unselectedBtn.shape
                      }
                      onClick={() => setSelectedArea(item.id)}
                      key={i}
                    >
                      <span
                        style={
                          state.selectedAreas.includes(item.id)
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        {item.name}
                      </span>
                      <span
                        style={
                          state.selectedAreas.includes(item.id)
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        {" "}
                        |{" "}
                      </span>
                      <span
                        style={
                          state.selectedAreas.includes(item.id)
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        {item.level}
                      </span>
                      <span
                        style={
                          state.selectedAreas.includes(item.id)
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        {" "}
                        |{" "}
                      </span>
                      <span
                        style={
                          state.selectedAreas.includes(item.id)
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        {item.capacity + " places"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="my-5"
                hidden={unAvailableAreas.length === 0 ? true : false}
              >
                <span className="font-bold">Espaces disponibles à :</span>
                <div className="flex flex-wrap content-start mt-1">
                  {unAvailableAreas.map((item, i) => (
                    <div className="flex items-center" key={i}>
                      <div
                        className="justify-items-center items-center m-1.5 px-2 text-sm inline-block rounded-lg py-2 content-center"
                        style={
                          state.selectedAreas.includes(item.id)
                            ? selectedBtn.shape
                            : unselectedBtn.shape
                        }
                        key={i}
                      >
                        <span
                          style={
                            state.selectedAreas.includes(item.id)
                              ? selectedBtn.text
                              : unselectedBtn.text
                          }
                        >
                          {item.name}
                        </span>
                        <span
                          style={
                            state.selectedAreas.includes(item.id)
                              ? selectedBtn.text
                              : unselectedBtn.text
                          }
                        >
                          {" "}
                          |{" "}
                        </span>
                        <span
                          style={
                            state.selectedAreas.includes(item.id)
                              ? selectedBtn.text
                              : unselectedBtn.text
                          }
                        >
                          {item.level}
                        </span>
                        <span
                          style={
                            state.selectedAreas.includes(item.id)
                              ? selectedBtn.text
                              : unselectedBtn.text
                          }
                        >
                          {" "}
                          |{" "}
                        </span>
                        <span
                          style={
                            state.selectedAreas.includes(item.id)
                              ? selectedBtn.text
                              : unselectedBtn.text
                          }
                        >
                          {item.capacity + " places"}
                        </span>
                        {item.start_date && (
                          <span className="text-sm font-bold text-gray-900 ">
                            {` est reservé du ${new Date(
                              item.start_date
                            ).toLocaleDateString()} au ${new Date(
                              item.end_date
                            ).toLocaleDateString()} de ${
                              item.available_before.split(":")[0]
                            }h ${item.available_before.split(":")[1]}mn à ${
                              item.available_at.split(":")[0]
                            }h ${item.available_at.split(":")[1]}mn`}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="items-baseline text-xl font-extrabold mb-16 px-5"
              hidden={state.step > 2}
            >
              <button
                className="text-xl font-extrabold text-blue uppercase px-2"
                onClick={() => wizardHandler("back")}
                hidden={!state.step > 0}
              >
                Précedent
              </button>
              <div
                className="text-xl font-extrabold text-blue uppercase float-right"
                onClick={() => wizardHandler("next")}
                hidden={state.step === 2}
              >
                Suivant
              </div>
              <div
                className="text-xl font-thin text-white uppercase float-right rounded px-3 bg-blue"
                onClick={(e, name) => handleSubmit(e, "submit")}
                hidden={state.step !== 2}
              >
                Soumettre
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="card-bg h-full border-white border px-2">
            <div className="py-3">
              <div>
                <h4 className="font-bold">Services à reserver :</h4>
                <div className="flex flex-wrap content-start mt-1">
                  {services.map((item, i) => (
                    <div
                      className="justify-items-center items-center m-1.5 px-2 text-sm inline-block rounded-lg py-0.5 content-center"
                      style={
                        state.selectedServices.includes(item.id)
                          ? selectedBtn.shape
                          : unselectedBtn.shape
                      }
                      onClick={() => setSelectedService(item.id)}
                      key={i}
                    >
                      <span
                        style={
                          state.selectedServices.includes(item.id)
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        {item.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-5">
                <h4 className="font-bold">
                  Souhaitez-vous ajouter quelque chose ?
                </h4>
                <div className="flex flex-wrap content-start mt-1">
                  <textarea
                    type="text"
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                    placeholder="Message"
                    className="rounded border-gray-300 w-full h-32 mx-2 my-2"
                  />
                </div>
              </div>
            </div>
            <div
              className="flex flex-wrap content-start mt-1 text-xl font-extrabold mb-16 px-5 justify-between items-center"
              hidden={state.step > 2}
            >
              <button
                className="text-xl font-extrabold text-blue capitalize px-2"
                onClick={() => wizardHandler("back")}
                hidden={!state.step > 0}
              >
                Précedent
              </button>
              <div
                className="text-xl font-extrabold text-blue capitalize float-right"
                onClick={() => wizardHandler("next")}
                hidden={state.step === 2}
              >
                Suivant
              </div>
              <div
                className="text-xl font-thin text-white capitalize float-right rounded px-3 bg-blue"
                onClick={(e, name) => handleSubmit(e, "submit")}
                hidden={state.step !== 2}
              >
                Soumettre
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="card-bg border-white border px-2 m-3 flex flex-col h-60 justify-center items-center">
            <div className="">
              <div className="px-14 py-3 text-center">
                <span className="block font-bold">
                  Votre reservation à été enrégistrée !
                </span>
              </div>
            </div>
            <div className="bg-blue rounded-2xl mt-4 ml-0 p-1 px-3 cursor-pointer">
              <Link to="/reservations">
                <span className="text-white font-thin">
                  Retour à la liste des reservations
                </span>
              </Link>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  function setWizardStep(direction) {
    if (direction === "back") {
      setState((prevState) => ({
        ...prevState,
        step: prevState.step - 1,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        step: prevState.step + 1,
      }));
    }
  }

  function wizardHandler(direction) {
    if (direction === "back") {
      setWizardStep(direction);
    } else {
      if (state.step === 0) {
        if (
          state.startDate === "" ||
          state.endDate === "" ||
          state.startTime === "" ||
          state.endTime === "" ||
          state.nbrParticipant === ""
        ) {
          notify("Veuillez renseigner tous les champs.");
        } else {
          dispatch(
            fetchAreas({
              start_date: state.startDate,
              end_date: state.endDate,
              start_time: state.startTime + ":00",
              end_time: state.endTime + ":00",
              nbr_participant: state.nbrParticipant,
            })
          );
          setWizardStep(direction);
          dispatch(fetchServices());
        }
      }

      if (state.step === 1) {
        if (state.selectedAreas.length === 0) {
          notify("Veuillez choisir l'espace à réserver.");
        } else {
          setWizardStep(direction);
        }
      }

      if (state.step === 2) {
        if (state.selectedServices.length === 0) {
          setWizardStep(direction);
        } else {
          setWizardStep(direction);
        }
      }
    }
  }

  const selectedBtn = {
    shape: { backgroundColor: "#05C1BD", border: "2px solid #fff" },
    text: { color: "#fff" },
  };

  const unselectedBtn = {
    shape: { backgroundColor: "#fff", border: "2px solid #aaa" },
    text: { color: "#000" },
  };

  return (
    <div className="flex flex-col py-16 w-full">
      <div className="w-full flex justify-center items-center bg-white">
        {info?.id === undefined ? (
          <span className="text-2xl font-bold p-2">Créer une réservation</span>
        ) : (
          <span className="text-2xl font-bold p-2">Faire une mise à jour</span>
        )}
      </div>
      <Toaster position="top-center" />
      <div
        className="flex-col justify-center items-center max-h-full px-1"
        style={{
          display: width > 505 ? "flex" : "block",
        }}
      >
        {wizard()}
      </div>
    </div>
  );
}
