import React, {Component} from 'react';
import Api from './../../../api';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Success from './Success';

class Main extends Component {

    constructor(props){
        super(props);
        this.state = {
            step:1,
            title: null,
            description:null,
            checkedItemsAppart: new Map(),
            checkedItemsPartiecomes: new Map(),
            appartments:[],
            particoms:[],
            partiecoms_id:[],
            appartment_id:[],
            typeIncidents:[],
            photos:[],
            images:[],
            catSupplier_id:0,
            emergency_level: 1,
            fields:{ 
                building_id: localStorage.getItem('building_id'),
                declared_by: localStorage.getItem('user_id'),
                status : "OPEND",
                emergency_level: 1,
            },
            fields_empty:{
                empty_title:false,
                empty_description:false,
                empty_partiecoms_id:false,
                empty_appartment_id: false,
                empty_catSupplier_id: false,
            },
            incident_id: null,
            error:false,
        }

        this.handleChange = this.handleChange.bind(this)
        // this.changeAppartments = this.changeAppartments.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.doSubmit = this.doSubmit.bind(this);
      
    }

    componentDidMount = () =>{
       
        let appartments = localStorage.getItem('appartments');
        let particoms = localStorage.getItem('partiecoms');
        let typeIncidents = localStorage.getItem('typeIncidents');
        appartments = JSON.parse(appartments)
        particoms = JSON.parse(particoms)
        typeIncidents = JSON.parse(typeIncidents)

        this.setState({
            appartments : appartments,
            particoms: particoms,
            typeIncidents: typeIncidents,
        })

    }


    checkIItemsIsTrue(array){
        let result = []
         array.forEach((value, key) => {
            if (value) {
                result.push(key)
            }
        });
        return result
    }


    nextStep = () => {

        const { step } = this.state
        this.setState({
            step : step + 1
        })
    }



    validateForm = () =>{
        let isValidForm = true;
        const {fields} = this.state
       
        if ( !fields?.title) {
            isValidForm = false;
            this.setState(prevState => {
                let fields_empty = Object.assign({}, prevState.fields_empty);  
                fields_empty.empty_title = true;                   
                return { fields_empty };                                 
            })
        }
        return isValidForm
    }

    validateForm2 = () =>{

        let isValidForm = true;
        const {fields} = this.state

        if ( !fields?.catSupplier_id) {
            isValidForm = false;
            this.setState(prevState => {
                let fields_empty = Object.assign({}, prevState.fields_empty);  
                fields_empty.empty_catSupplier_id = true;                   
                return { fields_empty };                                 
            })
        }
        if ( !fields?.description) {
            isValidForm = false;
            this.setState(prevState => {
                let fields_empty = Object.assign({}, prevState.fields_empty);  
                fields_empty.empty_description = true;                   
                return { fields_empty };                                 
            })
        }
        
        return isValidForm
    }


    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        })
    }


    handleChange = input => event => {
        
        let fields = this.state.fields
        const target = event.target;
        fields[input] = target.value 

        this.setState({  fields:fields, })    
         
    }

    changeAppartments = input => e =>{
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItemsAppart: prevState.checkedItemsAppart.set(item, isChecked) }));

    }

    changePartiecoms = input => e =>{
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItemsPartiecomes: prevState.checkedItemsPartiecomes.set(item, isChecked) }));
    }

    handleChangeFile = input => event => {

        // let fileObj = []
        let fileArray = []
        let files = event.target.files
        this.setState({ photos: files })
        // fileObj.push(file)
        // for (let i = 0; i < fileObj[0].length; i++) {
        //     this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        // }
        // console.log(fileObj[0])
        for (const item of files)
        {
            // this.state.images.push(item)
            fileArray.push(URL.createObjectURL(item) )
        }
        this.setState({images:fileArray})
    }
   
    doSubmit = (e) =>{
        
        const formData = new FormData();
       
        for (const image_file of this.state.photos) {
            formData.append('file[]', image_file);
        }
        let data = this.state.fields
    
        data["appartment_id"] = this.checkIItemsIsTrue(this.state.checkedItemsAppart)
        data["partiecoms_id"] = this.checkIItemsIsTrue(this.state.checkedItemsPartiecomes) 
         Api.declaredAnIncident(data)
            .then(response =>{

                const { step } = this.state
                this.setState({
                    incident_id: response.data.data.id,
                    step : step + 1
                }) 
                    if (this.state.incident_id !== null) 
                    {
                        Api.uploadFile(formData, this.state.incident_id)
                        .then(res => {

                        })
                        .catch(error => {
                            console.log(error)
                        }) 
                    }
            })
            .catch(error => {
                console.log(error)
            }) 
       
    }
       

    render(){
        const { step,  title, description, catSupplier_id, emergency_level, appartments, 
            particoms, typeIncidents, photos, fields_empty, checkedItemsAppart, checkedItemsPartiecomes 
        } = this.state
        const values = {title, description, catSupplier_id, emergency_level, appartments,  photos}

        switch (step) {
            case 1:
                return  <Step1 
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                validateForm={this.validateForm}
                values={values}
                fields_empty={fields_empty}
                
              />
            case 2:
                return <Step2 
                particoms={particoms}
                appartments={appartments}
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                changeAppartments={this.changeAppartments}
                changePartiecoms={this.changePartiecoms}
                values={values}
                checkedItemsAppart={checkedItemsAppart}
                checkedItemsPartiecomes={checkedItemsPartiecomes}
              />
            case 3:
                return  <Step3 
                prevStep={this.prevStep}
                nextStep={this.nextStep}
                validateForm2={this.validateForm2}
                handleChange={this.handleChange}
                values={values}
                typeIncidents={typeIncidents}
                fields_empty={fields_empty}
               
              /> 
            case 4:
                return  <Step4 
                prevStep={this.prevStep}
                doSubmit={this.doSubmit}
                handleChangeFile={this.handleChangeFile}
                values={values}
                images={this.state.images}
               
              /> 
            case 5:
                return  <Success />
            default:
                return  <></>
        }
    }
}

export default Main