import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  addIntervention,
  deleteIntervention,
  updateIntervention,
} from "../../features/interventions/interventionsSlice";
import { useWindowDimensions } from "../../utils/utils";
const user_id = localStorage.getItem("user_id");
const building_id = localStorage.getItem("building_id");
const building_name = localStorage.getItem("building_name");

export default function CRUDIntervention(props) {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();
  const info = props.location?.state?.info;
  const [state, setState] = useState({
    work_id: info?.work_id === undefined ? null : info?.work_id,
    step: info?.step === undefined ? 0 : info?.step,
    file: null,
    renderImage: "",
    created_by: user_id,
    endTime: info?.end_time === undefined ? "" : info?.end_time,
    description: info?.comment === undefined ? "" : info?.comment,
    startTime: info?.start_time === undefined ? "" : info?.start_time,
    startDate: info?.start_date === undefined ? "" : info?.start_date,
  });

  function handleChange(event) {
    if (event.target.name === "files") {
      setState((prevState) => ({
        ...prevState,
        file: event.target.files[0],
        renderImage: URL.createObjectURL(event.target.files[0]),
      }));
    } else {
      let fieldName = event.target.name;
      setState((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));
      if (fieldName === "startTime") {
        let hh = parseInt(event.target.value.split(":")[0]);
        let mm = parseInt(event.target.value.split(":")[1]);
        let date = new Date();
        let endTime = new Date(date.setHours(hh + 1)).toLocaleTimeString();

        setState((prevState) => ({
          ...prevState,
          endTime:
            endTime.split(":")[0] +
            ":" +
            mm.toLocaleString("fr-FR", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
        }));
      }
    }
  }

  function handleSubmit(event, fieldName) {
    const {
      id,
      created_by,
      work_id,
      startDate,
      endDate,
      startTime,
      endTime,
      description,
      file,
    } = state;

    let intervention = {
      user_id: created_by,
      work_id: work_id,
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      comment: description,
      file,
    };

    if (state.step === 1) {
      if (fieldName === "delete") {
        dispatch(deleteIntervention(id));
        history.push("/interventions");
      } else {
        intervention = {
          work_id: intervention?.work_id,
          description: intervention?.comment,
          updated_by: intervention?.user_id,
        };
        const data = new FormData();
        Object.keys(intervention).forEach((key) => {
          data.append(key, intervention[key]);
        });
        if (file) {
          data.append("images[]", file, file.name);
        }
        dispatch(updateIntervention(data));
        setState((prevState) => ({
          ...prevState,
          step: 3,
        }));
      }
    } else {
      intervention = {
        ...intervention,
        building_id,
      };
      dispatch(addIntervention(intervention));
      setState((prevState) => ({
        ...prevState,
        step: 2,
      }));
    }
    event.preventDefault();
  }

  function wizard() {
    switch (state.step) {
      case 0:
        return (
          <div className="card-bg h-full border-white-500 border px-2 m-3">
            <div>
              <div className="py-3">
                <span className="font-thin">Immeuble : </span>
                <span className="font-bold capitalize">
                  {building_name?.toLowerCase()}
                </span>
              </div>
              <div className="flex max-w-max mb-2">
                <div className="inline-block w-96">
                  <span className="font-bold">Date de l'intervention :</span>{" "}
                  <br />
                  <input
                    type="date"
                    name="startDate"
                    value={state.startDate}
                    onChange={handleChange}
                    className="rounded border-gray-300 my-2"
                  />
                </div>
              </div>

              <div className="flex">
                <div className="inline-block">
                  <span className="font-bold">Heure de début :</span> <br />
                  <input
                    type="time"
                    name="startTime"
                    value={state.startTime}
                    onChange={handleChange}
                    className="rounded border-gray-300 w-auto my-2"
                  />
                </div>
                <div className="inline-block ml-7">
                  <span className="font-bold">Heure de fin :</span> <br />
                  <input
                    type="time"
                    name="endTime"
                    value={state.endTime}
                    onChange={handleChange}
                    className="rounded border-gray-300 w-auto my-2"
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <h4 className="font-thin">
                Souhaitez-vous ajouter quelque chose ?
              </h4>
              <div className="flex flex-wrap content-start mt-1">
                <textarea
                  type="text"
                  name="description"
                  value={state.description}
                  onChange={handleChange}
                  placeholder="Message"
                  className="rounded border-gray-300 w-full h-32 my-2"
                />
              </div>
            </div>
            {state.startDate !== "" &&
              state.startTime !== "" &&
              state.endTime !== "" && (
                <div
                  className="items-baseline text-xl font-extrabold mb-16 mt-4"
                  hidden={state.step > 2}
                >
                  <div
                    className="text-xl font-thin text-white capitalize float-right rounded px-3 bg-blue"
                    onClick={(e, name) => handleSubmit(e, "submit")}
                  >
                    Soumettre
                  </div>
                </div>
              )}
          </div>
        );
      case 1:
        return (
          <div className="card-bg h-full border-white-500 border px-2 m-3">
            <div className="">
              <div className="py-3">
                <span className="font-thin">Immeuble : </span>
                <span className="font-bold capitalize">
                  {building_name?.toLowerCase()}
                </span>
              </div>
              <div className="py-3">
                <span className="font-bold opacity-40">Incident : </span>
                <span className="font-bold capitalize">
                  {info?.title?.toLowerCase()}
                </span>
              </div>
              <div className="flex flex-wrap content-start mt-1">
                <textarea
                  type="text"
                  name="description"
                  value={state.description}
                  onChange={handleChange}
                  placeholder="Message"
                  className="rounded border-gray-300 w-full h-32 my-2"
                />
              </div>
              <div className="flex flex-wrap max-w-max content-start mb-4">
                <div className="border-2 h-40 border-dashed rounded-2xl mb-4 w-96 flex flex-col justify-center items-center text-center">
                  <div className="text-center relative flex items-center justify-center max-h-full">
                    <input
                      multiple
                      type="file"
                      name="files"
                      accept="image/*"
                      onChange={handleChange}
                      className="cursor-pointer relative block opacity-0 w-full p-20 z-50"
                    />
                    {!state.renderImage && (
                      <>
                        <svg
                          fill="none"
                          aria-hidden="true"
                          viewBox="0 0 48 48"
                          stroke="currentColor"
                          className="h-12 w-12 text-gray-400 absolute block top-7"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="absolute block font-medium text-gray-600 bottom-7">
                          <h4>
                            Cliquer pour prendre une photo
                            <br />
                            ou
                          </h4>
                          <p className="">choisir des photos</p>
                        </div>
                      </>
                    )}
                    {state.renderImage && (
                      <img
                        className="absolute max-h-full mx-auto"
                        src={state.renderImage}
                        alt={""}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {state.description !== "" && (
              <div
                className="items-baseline text-xl font-extrabold mb-16 px-5"
                hidden={state.step > 2}
              >
                <div
                  className="text-xl font-thin text-white capitalize float-right rounded px-3 bg-blue"
                  onClick={(e, name) => handleSubmit(e, "submit")}
                >
                  Enregister
                </div>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="flex flex-col card-bg h-60 justify-center items-center  m-3">
            <div className="">
              <div className="px-14 py-3 text-center">
                <span className="block font-bold">
                  La planification de l'intervention est enregistrée !
                </span>
              </div>
            </div>
            <div className="bg-blue rounded-2xl mt-4 ml-0 p-1 px-3 cursor-pointer">
              <Link to="/interventions">
                <span className="text-white font-thin">
                  Retour à la liste des interventions
                </span>
              </Link>
            </div>
          </div>
        );
      default:
        return (
          <div className="flex flex-col card-bg  h-60 justify-center items-center m-3">
            <div className="">
              <div className="px-14 py-3 text-center">
                <span className="block font-bold">
                  La mise à jour de l'intervention est enregistrée !
                </span>
              </div>
            </div>
            <div className="bg-blue rounded-2xl mt-4 ml-0 p-1 px-3 cursor-pointer">
              <Link to="/interventions">
                <span className="text-white font-thin">
                  Retour à la liste des interventions
                </span>
              </Link>
            </div>
          </div>
        );
    }
  }

  return (
    <div className="flex flex-col py-16 w-full">
      {state.step === 0 && (
        <div className="w-full flex justify-center items-center bg-white">
          <span className="text-2xl font-bold p-2">Créer une intervention</span>
        </div>
      )}
      {state.step === 1 && (
        <div className="w-full flex justify-center items-center bg-white">
          <span className="text-2xl font-bold p-2">Faire une mise à jour</span>
        </div>
      )}
      {state.step > 1 && (
        <div className="w-full flex justify-center items-center bg-white">
          <span className="text-2xl font-bold p-2 capitalize">
            Intervention {info.title.toLowerCase()}{" "}
          </span>
        </div>
      )}

      <div
        className="flex-col justify-center items-center max-h-full"
        style={{
          display: width > 505 ? "flex" : "block",
        }}
      >
        {wizard()}
      </div>
    </div>
  );
}
