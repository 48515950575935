import { api } from "../../api";
import { createSlice } from "@reduxjs/toolkit";
const building_id = localStorage.getItem("building_id");

const reservationsSlice = createSlice({
  name: "reservations",
  initialState: {
    reservations: [],
    filteredReservations: [],
    availableAreas: [],
    unAvailableAreas: [],
    services: [],
    isWaiting: false,
    error: false,
  },
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    hasError: (state) => {
      state.error = true;
      state.isWaiting = false;
    },
    getReservationsSuccess: (state, action) => {
      state.filteredReservations = action.payload.sort(
        (x, y) => new Date(y.created_at) - new Date(x.created_at)
      );
      state.reservations = state.filteredReservations;
      state.isWaiting = false;
    },
    searchFor: (state, action) => {
      state.filteredReservations = state.reservations.filter((item) => {
        let name = item?.space?.name.toLowerCase().replace(/ /g, "");
        let text = action.payload.toLowerCase().replace(/ /g, "");

        return name.normalize().includes(text.normalize()) ? item : null;
      });
    },
    getAreasSuccess: (state, action) => {
      state.availableAreas = action.payload?.spaces_available;
      state.unAvailableAreas = action.payload?.spaces_available_from;
      state.isWaiting = false;
    },
    setAvailableAreas: (state, action) => {
      state.availableAreas = action.payload;
    },
    setUnAvailableAreas: (state, action) => {
      state.unAvailableAreas = action.payload;
    },
    getServicesSuccess: (state, action) => {
      state.services = action.payload;
      state.isWaiting = false;
    },
    getReservationSuccess: (state) => {},
    createReservationSuccess: (state) => {
      state.isWaiting = false;
    },
    updateReservationSuccess: (state) => {
      state.isWaiting = false;
    },
    deleteReservationSuccess: (state) => {
      state.isWaiting = false;
    },
  },
});

export const {
  searchFor,
  startWaiting,
  hasError,
  getReservationSuccess,
  getReservationsSuccess,
  getAreasSuccess,
  setUnAvailableAreas,
  setAvailableAreas,
  getServicesSuccess,
  createReservationSuccess,
  updateReservationSuccess,
  deleteReservationSuccess,
} = reservationsSlice.actions;

export const fetchReservations = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.get(`/reservations/all/${building_id}`)
    .then((response) => {
      dispatch(getReservationsSuccess(response.data.data));
    });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const fetchAreas = (data) => async (dispatch) => {
  dispatch(startWaiting());
  // console.log(data);
  try {
    await api
      .get(`/reservable-spaces/${building_id}`, { params: { ...data } })
      .then((response) => {
        console.log(building_id);
        console.log(response.data);
        dispatch(getAreasSuccess(response.data.data));
      });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const fetchServices = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.get(`/services/${building_id}`).then((response) => {
      dispatch(getServicesSuccess(response.data));
    });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const fetchReservation = (id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .get("/reservations/show/:" + id)
      .then((response) => dispatch(getReservationSuccess(response.data)));
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const addReservation = (reservation) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.post("/reservations/store", reservation).then(() => {
      dispatch(createReservationSuccess());
      dispatch(fetchReservations());
    });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const updateReservation = (reservation, id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .put("/reservations/update/" + id, reservation)
      .then((response) => {
        dispatch(updateReservationSuccess(response.data));
        dispatch(fetchReservations());
      });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const deleteReservation = (id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.delete("/reservations/" + id).then((response) => {
      dispatch(deleteReservationSuccess(response.data));
      dispatch(fetchReservations());
    });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export default reservationsSlice.reducer;
