import { api } from "../../api";
import { createSlice } from "@reduxjs/toolkit";
const building_id = localStorage.getItem("building_id");
const global_id = JSON.parse(localStorage.getItem("user"))?.global_id;

const initialState = {
  generalassembliesAll:[],
  generalAssembly: {},
  newComment: {},
  totalParticipant: 0,
  confirmed: '',
  isWaiting: false,
  error: false,
}

const generalassembliesSlice = createSlice({

  name: 'generalassemblies',
  initialState,
  reducers:{

    startWaiting:(state) =>{
      state.isWaiting = true
    },

    hasError: (state) =>{
      state.error = true;
      state.isWaiting = false;
    },

    getGeneralAssemblySuccess: (state, action) =>{
      state.generalAssembly = action.payload
      state.totalParticipant = action.payload.participantsNumber
      state.confirmed = action.payload.convocation.confirmed
      state.isWaiting = false;
      // console.log(action.payload.convocation.confirmed)
    },
    
    getGeneralassembliesSuccess: (state, action) =>{
      state.generalassembliesAll = action.payload
      state.isWaiting = false
    },

    addParticipantSuccess: (state, action) => {
      state.totalParticipant = action.payload.nbrParticipant;
      state.confirmed = action.payload.confirmed;
    },

    addCommentSuccess: (state, action) => {
      state.newComment = action.payload;
    },

  },

});

export const {
  startWaiting, 
  hasError, 
  getGeneralAssemblySuccess,
  getGeneralassembliesSuccess,
  addParticipantSuccess,
  addCommentSuccess
} = generalassembliesSlice.actions;

export const fetchGeneralassemblies = () => async (dispatch) => {
    dispatch(startWaiting());
    try {
      await api.get(`/generalAssemblies/${building_id}/${global_id}`).then((res) => {
        dispatch(getGeneralassembliesSuccess( res.data.data));
        // console.log(res.data.data)
      });
    } catch (error) {
      dispatch(hasError());
      return console.error(error);
    }
};

export const fetchGeneralAssembly = (id) => async (dispatch) => {

    dispatch(startWaiting());
    try {
      await api.get(`generalAssemblies/show/${id}/${global_id}`).then((res) => {
        dispatch(getGeneralAssemblySuccess( res.data.shift()));
        
      });
    } catch (error) {
      dispatch(hasError());
      return console.error(error);
    }
};

export const addParticipant =
  (ag_id, confirmed) => async (dispatch) => {
    // dispatch(startWaiting());
    try {
      await api
        .put(`generalAssemblies/convocations/${ag_id}/${global_id}`, {confirmed: confirmed})
        .then((response) => {
          dispatch(addParticipantSuccess(response.data));
        });
    } catch (error) {
      dispatch(hasError(error.message));
      return console.error(error);
    }
  };

  export const addComment = (comment) => async (dispatch) => {
    // dispatch(startWaiting());
    try {
      await api
        .post("generalAssemblies/comments", {
          ...comment,
        })
        .then((response) => {
          dispatch(addCommentSuccess(response.data));
        });
    } catch (error) {
      dispatch(hasError(error.message));
      return console.error(error);
    }
  };

export default generalassembliesSlice.reducer;