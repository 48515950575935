import React from "react";
import PageTitle from '../../common/PageTitle'
import Progressbar from "../../common/Progressbar";


class Suivibudget extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            budgets:[]
        }
    }


    componentDidMount = () => {
        let budgets = localStorage.getItem('budgets')
        budgets = JSON.parse(budgets);
        let budget = budgets.sort(function(a, b){
            return (a.pourcentage > b.pourcentage) ? -1 : 1
        })
        this.setState({
            budgets: budget
        }) 
    }

    render(){
        const {budgets} = this.state;
        
        return(
            
            <div className="container mx-auto">
                <PageTitle title="Suivi du budget en cours" />
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="py-6 sm:px-0">
                        <div className=" bg-white border-gray-200 rounded-lg px-4 py-4 mb-20">
                            <ul className="list-non list-inside space-y-8">
                              <li className="text-blue text-sm font-bold">Détails du budget</li>
                               {budgets.map((item,key) =>
                                <li key={key} className="text-sm font-bold">{item.name}
                                <span className={`bg-blue text-white font-bold px-1 ml-2 rounded`}>{Math.round(item.pourcentage)}%</span>
                                <Progressbar  className="h-full bg-blueabsolute  " style={Math.round(item.pourcentage)} />
                               </li>
                               )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default Suivibudget ;