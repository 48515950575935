import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";



const  viewSlice = createSlice({

    name:'views',
    initialState: {
        allViews: [],
        countViews: 0,
        error: false,
    },
    reducers:{
        hasError: (state) =>{
            state.error = true;
            state.isWaiting = false;
        },

        incrementViewSuccess:(state, action) =>{
            state.allViews = action.payload;
            state.countViews = action.payload.length;
            // console.log(state.views)
        }
    }

});


export const {
    incrementViewSuccess,
    hasError
} = viewSlice.actions


export const incrementView = (data) => async (dispatch) => {
    try {
      await api
        .post("/views", data)
        .then((response) => {
          dispatch(incrementViewSuccess(response.data));
        });
    } catch (error) {
      dispatch(hasError(error));
      return console.error(error);
    }
};

export default viewSlice.reducer