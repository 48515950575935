import React from "react";
import { useState } from "react";
import { FiUser } from "react-icons/fi";
import { formatDate } from "../../../utils/utils";

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main p-4 rounded-lg">{children}</section>
    </div>
  );
};

export default function Evaluation({ info }) {
  const [state, setState] = useState({
    show: false,
  });

  function toggleModal() {
    setState((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  }

  return (
    <div className="card-bg py-3 mt-3">
      {state.show && (
        <div id="modal" onClick={toggleModal}>
          <Modal show={state.show} handleClose={toggleModal}>
            {info?.views?.map((view, i) => (
              <div className="flex items-center m-2" key={i}>
                <div className="inline-block">
                  {view?.viewer?.avatar ? (
                    <img
                      className="rounded-full w-14 h-12 object-cover"
                      src={view?.viewer?.avatar}
                      alt=""
                    />
                  ) : (
                    <FiUser className="rounded-full w-11 h-11 bg-gray-200" />
                  )}
                </div>
                <span className="inline-block ml-1 text-sm">
                  {view?.viewer?.name}
                </span>
              </div>
            ))}
          </Modal>
        </div>
      )}
      {/* Cart header div */}
      <div className="px-4">
        {/* First part */}
        <div className="flex w-view">
          <div className="inline-block">
            <div className="w-12 h-12 -ml-2 bg-gray-200">
              <p className="font-extrabold text-center p-2 text-2xl">
                {info?.evaluable_id.charAt(0)}
              </p>
            </div>
          </div>
          <div className="flex-row inline-block w-full">
            <div className="flex justify-between">
              <div className="inline-block text-sm ml-1 font-bold">
                <span>Nouvelle Evaluation</span>
              </div>
              <div
                className="inline-block text-sm rounded-xl border-blue-700 border-2 ml-3"
                style={{ borderColor: "#05c1bd" }}
              >
                <span
                  className="px-2 text-blue-500"
                  style={{ color: "#05c1bd" }}
                >
                  Prestataire
                </span>
              </div>
            </div>
            <div className="inline-block text-xs ml-1 align-top text-gray-400">
              <span>Posté le {formatDate(info.date_envoi)}</span>
            </div>
          </div>
        </div>
        {/* Second part */}
        <div className="mt-3">
          <div className="inline-block text-sm font-bold">
            <span>
              {info.title
                .toLowerCase()
                .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
            </span>
          </div>

          <div className="flex justify-around">
            {/* Views */}
            <div className="inline-block">
              <div className="flex-row">
                <span>Qui ? : </span>
                <span className="inline-block font-bold">
                  {info.evaluable.name
                    ?.toLowerCase()
                    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
                </span>
              </div>
            </div>
            {/* Comments */}
            <div className="inline-block">
              <div className="flex-row">
                <span className="font-thin">Date de cloture : </span>
                <span className="inline-block font-bold">
                  {formatDate(info.closed_at)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
