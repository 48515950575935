import React from "react";
import Empty from "../../../common/EmptyData";
import Evaluation from "../evaluation/evaluation";
// import { GiEmptyHourglass } from "react-icons/gi";
import Incident from "../incident/incident";
import Feed from "./feed";
import { useSelector } from "react-redux";

const Feeds = ({ feeds }) => {
  const filterKey = useSelector((state) => state.feeds.filterKey);
  if (feeds.length > 0) {
    return feeds.map((item, i) => {
      switch (item.type.toLowerCase()) {
        case "incident":
          return <Incident info={item} key={i} />;
        // case "travaux":
        //   return <Incident info={item} key={i} />;
        case "evaluation":
          return <Evaluation info={item} key={i} />;
        case "actuality":
          return <Feed info={item} key={i} />;
        case "annoucement":
          return <Feed info={item} key={i} />;
        case "event":
          return <Feed info={item} key={i} />;
        default:
          break;
      }
      return null;
    });
  } else {
    let text="";
    switch (filterKey) {
      case "event":
        text = `Il y a aucun événement pour le moment.`
        break;
      case "annoucement":
        text = `Il y a aucune annonce pour le moment.`
        break;
      case "actuality":
        text = `Il y a aucune actualité pour le moment.`
        break;
      case "incident":
        text = `Il y a aucun incident pour le moment.`
        break;
      case "evaluation":
        text = `Il y a aucune evaluation pour le moment.`
        break;
      default:
        text = `Le babillard est vide, dites quelques choses.`
        break;
    }
    return (
      <div className="bg-white my-7 flex flex-col justify-center items-center h-60">
        <Empty text={text} />
      </div>
    );
  }
};

export default Feeds;
