import React from 'react';

import PageTitle from '../../../common/PageTitle';
import FormGroup from '../../form/FormGroup';

class Step3 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            note: 0,
        }

    }



    saveAndContinue = (e) => {
        e.preventDefault()
        if (this.props.validateForm2()) {
            this.props.nextStep()
        }
        
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }


    render(){
        const { values, fields_empty} = this.props
        const hasError = fields_empty?.empty_catSupplier_id
        const hasError_description = fields_empty?.empty_description
        const finalClass = `w-full border border-gray-300 rounded-lg px-4 py-3 mt-2 h-32 outline-none transition-colors duration-150 ease-in-out focus:border-blue-400 
        ${
            hasError_description && `border-red-600`
          }`
    //    const classSelect = "block appearance-none w-full bg-white rounded-lg pl-4 py-3 pr-8 cursor-pointer focus:outline-none"
       const classSelect = `block appearance-none w-full bg-white rounded-lg pl-4 py-3 pr-8 cursor-pointer focus:outline-none ${
        hasError && `border-red-600`
      }`
        

        return(
            <>
                <PageTitle title="Déclarer un incident" />
                <main className="w-full bg-white border-gray-200 rounded-lg px-2 py-6 pb-10 mt-4 mb-4">
                    <form className="mb-10">
                        <FormGroup>
                            <label  className={`text-base text-gray-600 ${hasError && "text-red-600"}`}> Type d'incident </label>
                            <select  
                                className={classSelect} 
                                name="catSupplier_id" 
                                onChange={this.props.handleChange('catSupplier_id')}>
                                <option defaultValue=""> Selectionner un type d'incident</option>
                                {this.props.typeIncidents?.map( item =>
                                    <option key={item.id} value={item.id}> {item.category} </option>
                                )}
                            </select>
                            {fields_empty?.empty_catSupplier_id  && (
                            <div className="bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm">
                                Le champ type d'incident est obligatoire
                            </div>
                            )}
                        </FormGroup>
                        
                       
                     <FormGroup>
                        <label className={`text-base text-gray-600 ${hasError_description && "text-red-600"}`}>Description</label>
                        <textarea cols="30" rows="8" className={finalClass}
                            placeholder="Description"
                            onChange={this.props.handleChange('description')}
                            defaultValue={values.description}
                        ></textarea>
                        {hasError_description  && (
                            <div className="bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm">
                                Le champ description est  obligatoire
                            </div>
                            )}
                     </FormGroup>
                       
                    </form>
                </main>
                <div className="items-baseline pt-6 text-xl font-extrabold mb-28">
                    <button  onClick={this.back} className="text-xl font-extrabold text-blue uppercase px-2">Précedent</button>
                    <button  onClick={ e => this.saveAndContinue(e) } className="text-xl font-extrabold text-blue uppercase float-right">suivant</button>
                </div>
                <br/>
            </>
        );
    }
}


export default Step3;