import Reply from "./reply"
import Pusher from "pusher-js"
import Echo from 'laravel-echo';

// import APP_ENV from "../../configs/env";
import { useEffect, useRef, useState } from "react";
import DetailsComment from "./detailsComment"



function Comment(props) {
    
    const {chats} = props;
    const [ comment, setComment] = useState({})
    const myRef = useRef(null)
    
    const options = {
        broadcaster: 'pusher',
        key: 'dev',
        cluster: 'dev',
        wsHost: `master.testmysyndic.app`,
        wssPort: 443, 
        disableStats: true, 
        forceTLS: true,
        enabledTransports: ['ws', 'wss'],
       
    };
      
    const echo = new Echo(options);
    const id = localStorage.getItem('agId')
    
    useEffect(() =>{

        echo.channel('channel-chat')
        .listen('EventChat', (e) => {

            let newMessage = e.message;
            if (e.message.chatable_id === id){
                setComment(newMessage)
            }
        }) 
        myRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })

        return echo
    } )
   
    

    const handlClick = (id, usename) => props.onClick &&  props.onClick(id, usename)
    

    return( 
        
        <div>
            {chats?.map((chat, i) =>(
                <div key={i}>
                    <DetailsComment 
                     details={chat}
                     onClick={handlClick}
                    />
        
                    {chat?.replies?.map((reply, key) => (
                        <Reply  key={key} replies={reply} />
                    ))}
                    {Object.keys(comment).length > 0 && parseInt(comment.chat_id) === chat.id && (
                        <Reply replies={comment} />
                    )}
                </div>
            ))}
            {Object.keys(comment).length > 0 && comment.chat_id === null &&(
             <DetailsComment 
                ref={myRef}
                details={comment}
                onClick={handlClick}
            /> 
            )}
       </div>
    )
}

export default Comment