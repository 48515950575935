import React from "react";

import Button from "../components/form/Button";
import FormGroup from "../components/form/FormGroup";
import InputGroup from "../components/form/InputGroup";
import Alert from "../common/Alert";
import Api from "./../api";
import PageTitle from "../common/PageTitle";
import axios from "axios";

class EditPassword extends React.Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    this.state = {
      data: { email: user?.email },
      emptyCurrentPassword: false,
      emptyPassword: false,
      emptyConfirmPassword: false,
      isRequestError: false,
      isValidUser: false,
      isPending: false,
      showForm: true,
      isconfirmPassword: true,
    };
    this.handlChange = this.handlChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlChange = (e) => {
    let data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({ data: data });
    // console.log(this.state.data)
  };

  validateForm = () => {
    let { data } = this.state;
    let isValidForm = true;
    // setTimeout(() => {
    if (!data.current_password) {
      isValidForm = false;
      this.setState({
        emptyCurrentPassword: true,
        isPending: false,
      });
    }
    if (!data.password) {
      isValidForm = false;
      this.setState({
        emptyPassword: true,
        isPending: false,
      });
    }
    if (!data.confirm_password) {
      isValidForm = false;
      this.setState({
        emptyConfirmPassword: true,
        isPending: false,
      });
    }

    // }, 5000);
    return isValidForm;
  };

  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === "credential_error") {
        this.setState({
          isValidUser: true,
        });
      }
      if (input === "success") {
        this.setState({
          showForm: false,
        });
      }

      if (input === "requestError") {
        this.setState({
          isRequestError: false,
        });
      }
    }, 1000);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isPending: true });
    let data = this.state.data;
    const object = localStorage.getItem("user");
    const user = JSON.parse(object);
    if (this.validateForm()) {
      if (data.password === data.confirm_password) {
        Api.updatePassword(data)
          .then((res) => {
            if (res.data.success === true) {
              this.setState({
                isPending: false,
                showForm: false,
              });
              this.handleShowAndHideAlert("success");
              // logout after changer password
              axios
                .get(`${Api.basUrl}/logout/${user.global_id}`)
                .then((res) => {
                  window.location = "/";
                  localStorage.clear();
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              this.setState({ isPending: false });
              this.handleShowAndHideAlert("credential_error");
            }
          })
          .catch((error) => {
            this.setState({
              isPending: false,
              isRequestError: true,
            });
            this.handleShowAndHideAlert("requestError");
          });
      } else {
        this.setState({
          isPending: false,
        });
        this.setState({ isconfirmPassword: false });
        // this.handleShowAndHideAlert('credential_error');
      }
    }
  };

  render() {
    const {
      data,
      emptyConfirmPassword,
      emptyPassword,
      emptyCurrentPassword,
      showForm,
      isRequestError,
      isPending,
      isValidUser,
      isconfirmPassword,
    } = this.state;
    return (
      <div className="py-1 px-6 mb-20 relative top-20">
        {showForm ? (
          <form
            onSubmit={this.handleSubmit}
            className="w-full mx-auto text-center mb-20"
          >
            <div>
              <PageTitle title="Changer de mot de passe" />
            </div>
            <div className="mt-12">
              <div className="py-1">
                <InputGroup
                  type="password"
                  name="current_password"
                  value={data.current_password}
                  onChange={(e) => this.handlChange(e)}
                  placeholder="Mot de passe actuel"
                  error={emptyCurrentPassword}
                  errorText="Ce champ ne peut être vide"
                />
              </div>
              <div className="py-1">
                <InputGroup
                  type="password"
                  name="password"
                  value={data.password}
                  onChange={(e) => this.handlChange(e)}
                  placeholder="Nouveau mot de passe"
                  error={emptyPassword}
                  errorText="Ce champ ne peut être vide"
                />
              </div>
              <div className="py-1">
                <InputGroup
                  type="password"
                  name="confirm_password"
                  value={data.confirm_password}
                  onChange={(e) => this.handlChange(e)}
                  placeholder="Retapez le nouveau mot de passe"
                  error={emptyConfirmPassword}
                  errorText="Ce champ ne peut être vide"
                />
                {!isconfirmPassword && (
                  <div className="bg-red-200 mt-2 py-2 px-4 text-base text-red-600 rounded-sm">
                    les deux mot de passe ne correspond pas
                  </div>
                )}
              </div>
              {isValidUser && (
                <div className="bg-red-200 mt-2 py-2 px-4 text-base text-red-600 rounded-sm">
                  Cet email n'existe pas
                </div>
              )}
              {isRequestError && (
                <div className="bg-red-200 mt-2 py-2 px-4 text-sm text-red-600 rounded-sm">
                  La connexion a échoué. Veuillez réessayer plus tard.
                </div>
              )}

              {!isPending ? (
                <>
                  <FormGroup>
                    <Button
                      className="bg-vert_2"
                      text="Metrre à jour le mot de passe"
                      submit
                      full
                    />
                  </FormGroup>
                </>
              ) : (
                <FormGroup>
                  <Button
                    className="bg-vert_2 text-white"
                    text="Mise à jour en cours"
                    full
                  />
                </FormGroup>
              )}
            </div>
          </form>
        ) : (
          <div>
            <h2 className="text-xl font-bold text-gray-800 mb-10 mt-20">
              Changer de mot de passe
            </h2>
            <Alert
              className="bg-green-500"
              text="Le changement de mot de passe réussie."
            />
          </div>
        )}
      </div>
    );
  }
}

export default EditPassword;
