import { useState } from "react";

import { IoEyeOutline, IoPersonCircleOutline } from "react-icons/io5";
import EmptyData from "../../common/EmptyData";

const user_id = localStorage.getItem("user_id");

const Modal = ({views, type}) => {
    
    const [show, setShow] = useState(false);
    
    const toggleModal = () =>{  setShow(!show) };
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
      <>
      <div className="inline-block rounded-xl border-blue-400 bg-gray-200 px-6 py-1 shadow-lg">
          <div className="flex-row" onClick={toggleModal}>
              <IoEyeOutline className="inline-block" />{" "}
              <span className="inline-block">
                  {views?.length}
              </span>
          </div>
        </div>
        <div className={showHideClassName} onClick={toggleModal}>
          <section className="modal-main p-2 rounded-lg">
            {show && (
              <div id="modal">
                  {views.map((view, i) => (
                    <div className="flex items-center" key={i}>
                      <div className="inline-block">
                        {view?.user?.avatar ? (
                          <img
                            className="rounded-full w-14 h-12 object-cover"
                            src={view?.user?.avatar}
                            alt=""
                          />
                        ) : (
                          <IoPersonCircleOutline size={40} color="#808080"/>
                        )}
                      </div>
                      <span className="inline-block ml-2 text-sm">
                        {view.user.id === parseInt(user_id) ? ' Vous ' : view?.user?.name }
                      </span>
                    </div>
                  ))}
                  {views.length === 0 && (
                    <EmptyData
                      text={`Personne n'a encore vu ${
                        {
                          event: "l'evenement",
                          annoucement: "l'annonce",
                          actuality: "l'actualité",
                          generalAssembly: "l'assemblée général",
                        }[type]
                      }.`}
                    />
                  )}
                {/* </Modal> */}
              </div>
            )}
          </section>
        </div>
      </>
    );
  };

export default Modal