import { GoPrimitiveDot } from "react-icons/go";

export default function ListItem({ title, description}) {
    return (
      <li className="px-4 mt-4">
        <div className="flex">
            <div className="-ml-2">
              <GoPrimitiveDot  size={25}/>
            </div>
            <h1 className="text-base font-bold ">
                {title}
            </h1>
        </div>
        <dd className="text-gray-500 py-4 text-left">
            {description}
        </dd>
      </li>
    )
  }