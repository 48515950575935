import React, { useState, useEffect } from "react";
import EmptyData from "../../common/EmptyData";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { IoSearchOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/utils";
import APP_ENV from "../../configs/env";
import { searchFor } from "../../features/reservations/reservationsSlice";

const Tabs = ({ tabOne, tabTwo, newReservations, oldReservations }) => {
  const dispatch = useDispatch();
  const [openedTab, setOpenedTab] = useState(1);
  const [state, setState] = useState({
    text: "",
  });

  useEffect(() => {
    dispatch(searchFor(state.text));
  }, [dispatch, state.text]);

  function handleChange(event) {
    let fieldName = event.target.name;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb -px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase py-3 block leading-normal " +
                  (openedTab === 1
                    ? "text-vert_2 border-b-2 border-black border-opacity-100"
                    : "text-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenedTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                {tabOne}
              </a>
            </li>
            <li className="-mb -px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase py-3 block leading-normal " +
                  (openedTab === 2
                    ? "text-vert_2 border-b-2 border-black border-opacity-100"
                    : "text-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenedTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                {tabTwo}
              </a>
            </li>
          </ul>
          <div className="px-2">
            <IoSearchOutline
              size={24}
              className="absolute z-20 m-1 mt-2 text-blue-400 font-bold"
            />
            <input
              type="text"
              name="text"
              value={state.text}
              autoComplete="off"
              onChange={handleChange}
              placeholder="Rechercher"
              className="pl-8 p-1 mb-6 bg-gray-200 w-full text-blod rounded-xl h-10 relative outline-none border-gray-200"
            />
          </div>
          <div className="relative flex flex-col min-w-0 break-words px-0.5 rounded-sm">
            <div className="px-4 py-2 flex-auto bg-white border">
              <div className="tab-content tab-space">
                <div
                  className={openedTab === 1 ? "block" : "hidden"}
                  id="link1"
                >
                  <div className="max-h-full">
                    {newReservations.map((reservation, i) => (
                      <Link
                        to={{
                          pathname: `/reservations/` + reservation?.id,
                          state: {
                            info: {
                              ...reservation,
                            },
                          },
                        }}
                        key={i}
                        className="px-1 mb-1 flex flex-row sm:flex-row items-center justify-between"
                      >
                        <img
                          className="rounded h-32 w-32 object-cover "
                          src={`${APP_ENV.tenantURL}/${reservation.space.image}`}
                          alt=""
                        />

                        <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400 w-0 pb-2">
                          <p className="text-sm font-black capitalize truncate">
                            {reservation.space?.name}
                          </p>
                          <p className="text-sm font-medium text-gray-500">
                            {reservation.space?.level}
                          </p>
                          <p className="text-sm font-medium text-gray-500">
                            {reservation.space?.capacity + " places"}
                          </p>
                          <p className="text-sm font-medium text-gray-500">
                            {formatDate(reservation.start_date)}
                          </p>
                          <p className="pt-1 overflow-hidden">
                            <span
                              className="px-2 text-sm border-2 rounded"
                              style={
                                reservation.status === "WITNG"
                                  ? {
                                      backgroundColor: "#FF8800",
                                      border: "2px solid #FF8800",
                                      color: "#fff",
                                    }
                                  : reservation.status === "RFUSE"
                                  ? {
                                      backgroundColor: "#FF0000",
                                      border: "2px solid #FF0000",
                                      color: "#fff",
                                    }
                                  : reservation.status === "APPRV"
                                  ? {
                                      backgroundColor: "#009E6B",
                                      border: "2px solid #009E6B",
                                      color: "#fff",
                                    }
                                  : {
                                      backgroundColor: "#aaa",
                                      border: "2px solid #aaa",
                                      color: "#fff",
                                    }
                              }
                            >
                              {
                                {
                                  WITNG: "En Attente",
                                  RFUSE: "Rejetée",
                                  APPRV: "Validée",
                                  CANCL: "Annulée",
                                  CLOSE: "Fermée",
                                }[reservation.status]
                              }
                            </span>
                          </p>
                          <p className="flex justify-center pr-4 -mt-8 items-end float-right">
                            <IoChevronForwardOutline />
                          </p>
                        </dd>
                      </Link>
                    ))}
                    {newReservations.length === 0 && (
                      <EmptyData text="Aucune reservation pour le moment." />
                    )}
                  </div>
                </div>{" "}
                {/* end tabs 1 */}
                <div
                  className={openedTab === 2 ? "block" : "hidden"}
                  id="link2"
                >
                  <div>
                    {oldReservations.map((reservation, i) => (
                      <Link
                        to={{
                          pathname: `/reservations/` + reservation?.id,
                          state: {
                            info: {
                              ...reservation,
                            },
                          },
                        }}
                        key={i}
                        className="px-1 mb-1 flex flex-row sm:flex-row items-center justify-between"
                      >
                        <img
                          className="rounded h-32 w-32 object-cover"
                          src={`${APP_ENV.tenantURL}/${reservation.space.image}`}
                          alt=""
                        />
                        <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400 w-0 pb-2">
                          <p className="text-sm font-black capitalize truncate">
                            {reservation.space?.name}
                          </p>
                          <p className="text-sm font-medium text-gray-500">
                            {reservation.space?.level}
                          </p>
                          <p className="text-sm font-medium text-gray-500">
                            {reservation.space?.capacity + " places"}
                          </p>
                          <p className="text-sm font-medium text-gray-500">
                            {formatDate(reservation.start_date)}
                          </p>
                          <p className="pt-1">
                            <span
                              className="px-2 text-sm border-2 rounded"
                              style={
                                reservation.status === "TAKE" ||
                                reservation.status === "WITNG"
                                  ? {
                                      backgroundColor: "#FF8800",
                                      border: "2px solid #FF8800",
                                      color: "#fff",
                                    }
                                  : reservation.status === "RFUSE"
                                  ? {
                                      backgroundColor: "#FF0000",
                                      border: "2px solid #FF0000",
                                      color: "#fff",
                                    }
                                  : reservation.status === "APPRV"
                                  ? {
                                      backgroundColor: "#009E6B",
                                      border: "2px solid #009E6B",
                                      color: "#fff",
                                    }
                                  : {
                                      backgroundColor: "#aaa",
                                      border: "2px solid #aaa",
                                      color: "#fff",
                                    }
                              }
                            >
                              {
                                {
                                  TAKE: "En Attente",
                                  WITNG: "En Attente",
                                  RFUSE: "Rejetée",
                                  APPRV: "Validée",
                                  CANCL: "Annulée",
                                  CLOSE: "Fermée",
                                }[reservation.status]
                              }
                            </span>
                          </p>
                          <p className="flex justify-center pr-4 -mt-8 items-end float-right">
                            <IoChevronForwardOutline />
                          </p>
                        </dd>
                      </Link>
                    ))}
                    {oldReservations.length === 0 && (
                      <EmptyData text="Aucune reservation pour le moment." />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Tabs.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  incidentOuvert: PropTypes.array,
  incidentCloses: PropTypes.array,
};

Tabs.defaultProps = {
  incidentOuvert: [],
  incidentCloses: [],
};
export default Tabs;
