import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../common/PageTitle";

class Success extends React.Component {
  render() {
    return (
      <>
        <PageTitle title="Déclarer un incident" />
        <main className="py-5 flex items-center justify-center">
          <div className="flex flex-col card-bg rounded-3xl h-60 justify-center items-center max-w-max">
            <div className="">
              <div className="px-14 py-3 text-center">
                <span className="block font-bold">
                  Merci d'avoir déclaré cet incident !
                </span>
              </div>
            </div>
            <div className="bg-blue rounded-2xl mt-4 ml-0 p-1 px-3 cursor-pointer">
              <Link to="/incidents">
                <span className="text-white font-thin">
                  Retour à la liste des incidents
                </span>
              </Link>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Success;
