import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Step3 from './Step3';
import Success from './Success';
import PageTitle from '../../common/PageTitle';
import Api from './../../api';

import content from '../../assets/content@2x.png';
import facher from '../../assets/facher@2x.png';
import vert from '../../assets/vert@2x.png';
import rouge from '../../assets/rouge@2x.png';
import contentCercle from '../../assets/content_cercle.png';
import facherCercle from '../../assets/facherCercle.png';
import tresContentCercle from '../../assets/tres_content_cercle.png';
import tresFacherCercle from '../../assets/tres-facher_cercle.png';



class MainEvaluation extends Component {

    constructor(props){
        super(props);
        let user = localStorage.getItem('user');
        user = JSON.parse(user)
        this.state = {
            criteres:[],
            step:1,
            note:0, 
            critere_id:0,
            commentaire:'',
            id:0,
            global_id: user?.global_id,
        }

        this.handleChange = this.handleChange.bind(this)
        this.doSubmit = this.doSubmit.bind(this);
    }

    handleChange = input => event => {
        this.setState({ [input] : event.target.value })
    }

   componentDidMount =()=>{
       
      let id = this.props.match.params.id
      this.setState({ id:id })
      this.getEvaluation(id)
    }

    getEvaluation = (id) =>{
       
        Api.getEvaluationById(id, this.state.global_id)
        .then((response) =>{
            this.setState({ criteres: response.data.data.criteres })
        })
        .catch((error) =>{
            console.log(error)
        })
    } 

    nextStep = () => {
        const { step } = this.state
        this.setState({ step : step + 1 })
    }



    prevStep = () => {
        const { step } = this.state
        this.setState({ step : step - 1 })
    }

    doSubmit = (e) =>{
    
        const building_id =  localStorage.getItem('building_id')
        let reponse = {
            critere_id: this.state.critere_id,
            evaluation_id: parseInt( this.state.id),
            building_id:   parseInt(building_id),
            commentaire: this.state.commentaire,
            note: this.state.note,
            global_id: this.state.global_id
        }

        Api.postReponseEvaluation(reponse)
        
        .then((response) =>{
            const { step } = this.state
            this.setState({
                step : step + 1
            })
        })
        .catch((error) =>{
            console.log(error, reponse)
        })
    }


    handleClick(note){

        this.setState({
            note: note
        })
    }

    handleChangeText(note){
        if (note === 5) {
            return  <h1 className="text-green-500 text-center font-extrabold py-10 text-4xl uppercase">très satisfait</h1>
        } else if (note === 4) {
           return <h1 className="text-smiles-satisfait text-center font-extrabold py-10 text-4xl uppercase">satisfait</h1>
        } else if (note === 2) {
           return <h1 className="text-smiles-facher text-center font-extrabold py-10 text-4xl uppercase">fâché</h1>
            
        } else if (note === 1){
          return  <h1 className="text-red-600 text-center font-extrabold py-10 text-4xl uppercase">très fâché</h1>
        }
    }


    render(){

     const smiles = [
            {id:1, img: tresContentCercle, img2: vert, note:5},
            {id:2, img: contentCercle, img2: content, note:4},
            {id:3, img: facherCercle, img2: facher, note:2}, 
            {id:4, img: tresFacherCercle, img2: rouge, note:1}
        ]
        const {step,  commentaire, criteres, id, note, critere_id} = this.state
        const values = {commentaire}
        const classCritereInactif = "w-full text-vert_2 bg-transparent border border-solid border-vert_2 font-bold  text-lg px-6 py-4 rounded-full outline-none focus:outline-none mr-1 mb-6";
        const classCritereActif = "w-full text-white bg-vert_2 border border-solid border-vert_2  font-bold  text-lg px-6 py-4 rounded-full outline-none focus:outline-none mr-1 mb-6";
        const classBtn = 'text-blue font-extrabold uppercase float-right px-2'
        const classBtnDisabled = 'text-blue font-extrabold uppercase float-right px-2 disabled:opacity-50'
        switch (step) {
            case 1:
               return  ( 
                    <>
                    <PageTitle title="Evaluation" />
                    <main className="py-5 w-full mb-20">
                        <div className="bg-white border-gray-200 rounded-lg px-2 py-4 pb-10 mb-20">
                            <p className="text-sm font-sans font-bold pb-3 text-black">Indiquez votre satisfaction par rapport à la prestation</p>
                            <p> Cliquer sur un des icones ci dessous </p>
                            <form>
                            <div className="grid grid-cols-4 py-6 gap-4 mt-6">
                                {smiles.map(( item) => (
                                    <div key={item.id} > 
                                        <Link to="#" onClick={e => this.handleClick(item.note)} >
                                        <img src={note === item.note ? item.img2 : item.img}
                                            className="w-16 h-16 rounded-full object-cover"
                                            alt=""
                                        />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            </form>
                            {this.handleChangeText(note)}
                            <div className="items-baseline pt-6 text-xl font-extrabold">
                                <Link to={`/details-sondage/${id}`} className="text-blue uppercase px-2">precedent</Link>
                                {note !== 0
                                    ? <button onClick={this.nextStep } className={classBtn}  >suivant</button>
                                    : <button className={classBtnDisabled}  disabled>suivant</button>
                                }

                            </div>
                        </div>
                    </main>
                </>
            )
            case 2:
                return(
                    <>
                        <PageTitle title="Evaluation" />
                        <main className="py-5 mb-20">
                            <div className="bg-white border-gray-200 rounded-lg px-2 py-4 pb-10 mb-10">
                                <p className="text-lg text-center font-bold pb-6 pt-6 text-black">Qu'avez vous apprécié le plus ?</p>
                                {criteres.map(critere =>
                                 <div key={critere.id} >
                                    <button  className={critere_id === critere.id ? classCritereActif : classCritereInactif } type="button" style={{ transition: "all .15s ease" }}
                                     onClick={(e) => this.setState({critere_id: critere.id})}>
                                        {critere.name}
                                    </button>
                                 </div>
                                )}
                               
                                <div className="items-baseline pt-6 text-xl font-extrabold">
                                  <Link to={"#"} className="text-blue uppercase px-2"onClick={this.prevStep}>precedent</Link>
                                    {critere_id !== 0
                                        ? <button onClick={this.nextStep } className={classBtn}  >suivant</button>
                                        : <button className={classBtnDisabled}  disabled>suivant</button>
                                    }
                                </div>
                            </div>
                           
                        </main>
                    </>
                );
            case 3:
               return  <Step3 
                        doSubmit={this.doSubmit}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values.commentaire}
                      />
            case 4:
               return  <Success />
            default:
                return (
                    <></>
                )
              
        }
       
    }
}

export default MainEvaluation