import React from "react";
import NavItem from "../NavItem";
import { 
    IoBarChartOutline, 
    IoCashOutline, 
    IoConstructOutline, 
    IoWarningOutline
 } from "react-icons/io5";

import List from "./List";
import ListItem from "./ListItem";
import { BiCalendarCheck } from "react-icons/bi";
import meeting from "../../assets/group.png";


const Submenu = ({dropdownPopoverShow, popoverDropdownRef}) => {

  return (
   
      <div 
        ref={popoverDropdownRef}
        className={
        (dropdownPopoverShow ? "block " : "hidden ") +
        "-top-64 md:-top-48 lg:-top-40 -left-1 z-30 py-3 md:py-4 lg:py-0 bg-white w-full h-auto border-b border-gray-300 "
        }>

        <List>
            <ListItem>
                <NavItem href="/incidents"  customClasses="flex">
                    <IoWarningOutline size={30} />
                    <span className="px-3 pt-1 ">Incident</span>
                </NavItem>
            </ListItem>
            <ListItem>
                <NavItem href="/works" customClasses="flex">
                    <IoConstructOutline size={30} />
                    <span className="px-3 pt-1 ">Travaux</span>
                </NavItem>
            </ListItem>
            <ListItem>
                <NavItem href="/appel"  customClasses="flex">
                    <IoCashOutline size={30} />
                    <span className="px-3 pt-1 ">Fonds</span>
                </NavItem>
            </ListItem>
            <ListItem>
                <NavItem href="/suivibudget"  customClasses="flex">
                    <IoBarChartOutline size={30} />
                    <span className="px-3 pt-1 ">Budget</span>
                </NavItem>
            </ListItem>
            <ListItem>
                <NavItem href="/reservations" customClasses="flex">
                    <BiCalendarCheck size={30} />
                    <span className="px-3 pt-1 ">Reservations</span>
                </NavItem>
            </ListItem>
            <ListItem>
                <NavItem href="/generalAssemblies"  customClasses="flex">
                    <img className="w-10 h-10 " src={meeting} alt="AG"/>
                    <span className="px-3 pt-2">AG</span>
                </NavItem>
            </ListItem>
        </List>
        
      </div>
  );
};

export default Submenu;
