import React from "react";
import { Link } from "react-router-dom";
import "./fab.css";

const Fab = (props) => {
  return (
    <div className="fab-container">
      <div className="fab fab-icon-holder">
        <i className="fas fa-plus"></i>
      </div>

      <ul className="fab-options">
        <Link
          to={{
            pathname: `/add-intervention/`,
            state: {
              info: {
                work_id: props.info.id,
                title: props.info?.title,
              },
            },
          }}
        >
          <li>
            <span className="fab-label">Créer une intervention</span>

            <div className="fab-icon-holder">
              <i className="far fa-calendar-alt"></i>
            </div>
          </li>
        </Link>
        <Link
          to={{
            pathname: `/add-intervention/`,
            state: {
              info: {
                step: 1,
                work_id: props.info?.id,
                title: props.info?.title,
              },
            },
          }}
        >
          <li>
            <span className="fab-label">Faire une mise à jour</span>
            <div className="fab-icon-holder">
              <i className="far fa-edit"></i>
            </div>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default Fab;
