import { useState } from "react";
import { Switch } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { updateDisplay } from "../features/settings/settingsSlice";

const style = {
    fontSize: '13px'
}

export default function Settings() {

    let display_PII = localStorage.getItem("display_PII");
    const [enabled, setEnabled] = useState(parseInt(display_PII))
    const dispatch = useDispatch();
    
    const handleChange = (e) => {
        setEnabled(!enabled)
        dispatch(updateDisplay(!enabled))
    }


  return (
    <div className="container mx-auto">
        <div className="bg-white overflow-hidden sm:rounded-lg py-20 h-full">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Paramètres de l'application</h3>
            </div>
            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-gray-50 py-5 grid grid-cols-3 gap-x-12 px-4">
                        <dt className="col-span-2 text-base font-medium text-black">
                            Afficher les Infos personnels
                        </dt>
                        <dd className="flex justify-end">
                        <Switch
                            checked={enabled}
                            onChange={handleChange}
                            className={`${
                                enabled ? 'bg-blue' : 'bg-gray-300'
                            } relative inline-flex items-center h-6 rounded-full w-11`}
                            >
                            <span className="sr-only">Enable notifications</span>
                            <span
                                className={`${
                                enabled ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-4 h-4 transform bg-white rounded-full`}
                            />
                        </Switch>
                        </dd>
                        <dd className="col-span-full pt-2 text-gray-500" style={style}>
                            <small> Apparaître dans l'annuaire des copropriétaires </small>
                        </dd>
                    </div>
                </dl>
            </div>
            
        </div>
     </div>   
  )
}
