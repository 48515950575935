import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyA-E4CUa8WAnyuvb8tPU2PBgF9lo__WM4k",
  authDomain: "mysyndic-45fbf.firebaseapp.com",
  databaseURL: "https://mysyndic-45fbf-default-rtdb.firebaseio.com",
  projectId: "mysyndic-45fbf",
  storageBucket: "mysyndic-45fbf.appspot.com",
  messagingSenderId: "935963752386",
  appId: "1:935963752386:web:a2147af3d1152c843a885b",
  measurementId: "G-M0SC5SMG4G"
};

firebase.initializeApp(firebaseConfig);
   
export default firebase;