import React from "react";

const Alert = ({ className, first_mot, text, p, icon }) => {
  const [showAlert, setShowAlert] = React.useState(true);
  return (
    <>
      {showAlert ? (
        <div
          className={
            "text-white px-6 py-4 border-0 rounded relative mb-4 " + className
          }
        >
          <span className="text-xl inline-block mr-5 align-middle">{icon}</span>
          <span className="inline-block align-middle mr-8">
            <b className="capitalize">{first_mot}!</b> {text}
            <p>{p}</p>
          </span>
          <button
            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={() => setShowAlert(false)}
          >
            <span>×</span>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Alert;
