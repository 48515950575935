import { IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import BadgeOutline from "../../common/badgets/badgetOutline";

import * as moment from "moment";
import "moment/locale/fr";

function removeHTMLTags(str){ 
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}

const ListItem = ({ assembly }) => {
  
//  const setId = (id) => localStorage.setItem('id', id);

  return (
    <article>
      <Link
        to={`/generalAssemblies/${assembly.id}`}
        className="px-1 mb-8 flex flex-row sm:flex-row items-center justify-between"
        onClick={(e) => localStorage.setItem('agId', assembly.id)}
      >
        <dd className="text-left mt-0 pb-1 flex-1 border-b border-gray-400 w-0">
          <p className="w-64 text-sm font-black capitalize truncate">
            {assembly.title}
          </p>
          <p className="w-60 mb-1 text-sm font-medium text-gray-500 truncate">
            {removeHTMLTags(assembly.description) }
            </p>
            {assembly.status === "VALIDATED" ? (
              <BadgeOutline text="validé" type="success" />
            ) : assembly.status === "INPROGRESS" ? (
              <BadgeOutline text="en cours" type="warning" />
            ) : assembly.status === "CONFIRMED" ? (
              <BadgeOutline text="confirmé" type="success" />
            ) : assembly.status === "STARTED" ? (
              <BadgeOutline text="démarré" type="info" />
            ): (
              <BadgeOutline text="annulé" type="danger" />
            )}
          <p className="flex justify-center pr-2 -mt-9 items-end float-right">
            <IoChevronForwardOutline />
          </p>
          <p className="flex justify-center items-end float-right text-gray-500 text-sm">
            {moment(assembly.held_date).format("ll")}
          </p>
        </dd>
      </Link>
    </article>
  );
};

ListItem.propTypes = {
  assembly: PropTypes.object,
};

ListItem.defaultProps = {
  assembly: {},
};
export default ListItem;
