import React from "react";
import PropTypes from "prop-types";

const PageTitle = ({ title }) => {
  return (
    <header>
      <div className="max-w-7xl mx-auto pt-20 pl-2">
        <h1 className="text-2xl mt-2 font-bold leading-tight text-gray-900">
          {title}
        </h1>
      </div>
    </header>
  );
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
};

export default PageTitle;
