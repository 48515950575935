import PropTypes from "prop-types";

import Currency from "../../common/Currency";
import FormatDate from "../../common/FormatDate";

const CustomTocomeup = ({ paymentTocomeup}) =>{
return (
    <>
    {paymentTocomeup.map((payment) =>
      <div key={payment.id} className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="py-1 sm:px-0">
            <div className="bg-white border-gray-200 rounded-lg px-4 py-4">
                <div className="grid grid-cols-6 gap-4 text-md font-bol">
                    <div className="col-start-1 col-end-7 font-bold ">
                        {payment.payment_periodicity}
                    </div>
                    <div className=" col-start-1 col-end-8 ">
                        Date limite de paiement:<span className="text-md font-bold"> {FormatDate(payment.payment_deadline)}</span>
                    </div>
                    <div className=" col-start-1 col-end-8 text-md">
                        <ul>
                            {payment.apparts.map((appart)=>
                                <li key={appart.title}>
                                    <span className="text-gray-600 opacity-100">{appart.title}: </span> 
                                    <span className=" font-bold">{Currency(appart.amount)} CFA</span>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )}
    </>    
);
}

CustomTocomeup.propTypes = {
    /**
     * The page title.
     */

     paymentTocomeup: PropTypes.array,
   
  };

  CustomTocomeup.defaultProps = {

    paymentTocomeup: []
  }
export default CustomTocomeup