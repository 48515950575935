import React from "react";

import { IoChatboxOutline, IoLinkOutline, IoLocationOutline, IoSend } from "react-icons/io5";
import { Link } from "react-router-dom";
import PageTitle from "../../common/PageTitle";
import Spinner from "../../common/Spinner";
import Comment from "../../components/generalAssembly/comment";
import {addComment, addParticipant, fetchGeneralAssembly} from '../../features/generalAssemblies/generalassembliesSlice';
import {connect} from 'react-redux';
import { incrementView } from "../../features/views/viewSlice";
import viewableType from "../../viewableType";
import Modal from "../../components/generalAssembly/modal";

import moment from "moment";
import "moment/locale/fr";
// import FormateDateString from "../../common/FormateDateString";

const user_id = localStorage.getItem("user_id");

class GeneralAssembly extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          generalassembly_id: this.props.match.params.id,
          show: false,
          comment: "",
          confirmed: '',
          numberParticipant: 0,
          message: "",
          reply_chat_id: '',
          reply_chat_name: null,
          view_fields:{
            user_id : user_id,
            viewable_type : viewableType[0],
            viewable_id : this.props.match.params.id
          }
        };
      }

    componentDidMount = ()  => {
        let id = this.props.match.params.id
        this.props.getGeneralAssembly(id);
        this.props.onIncrementView(this.state.view_fields);
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.confirmed !== this.state.confirmed) {
        let confirmed =  this.state.confirmed ;
        this.setState({confirmed});
      }
    }
    
    static getDerivedStateFromProps(nextProps, prevState){
      if(nextProps.defaultConfirmed !== prevState.confirmed){
       
        return {confirmed : nextProps.defaultConfirmed};
      }
      else return null;
    }

    confirmParticipation = async (status) => {
      
      this.setState({ confirmed: status}) 
      await this.props.onAddParticipant(this.state.generalassembly_id, status);
  
    }

    handleChange = (event) => {
      var fieldName = event.target.name;
      this.setState((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));
    };

    handlClick = (reply_id, reply_username) =>{
      this.setState({
        reply_chat_id: reply_id,
        reply_chat_name: reply_username,
      })
    }
    
    handleSubmit = async (event) => {
      event.preventDefault();
      let comment = {
        user_id: user_id,
        chatable_type: viewableType[0],
        chatable_id: this.state.generalassembly_id,
        chat_id: this.state.reply_chat_id,
        message: this.state.message,
      };
 
      this.setState((prevState) => ({
        ...prevState,
        message: "",
        reply_chat_name: "",
        reply_chat_id: ""
      }));
      await this.props.onAddComment(comment);
      
  
    };

    renderParticipants = () => {
      
        if (this.props.totalParticipant === 0 && this.state.confirmed !== "YES") {
          return <span>Il y'a aucun participants pour le moment</span>;
        } else {
          if (this.state.confirmed === "YES") {
            
            return (
              <span>
                Vous et {this.props.totalParticipant} personnes vont
                participer
              </span>
            );
          } else {
            return (
              <span>
                Il y'a {this.props.totalParticipant} participants pour le
                moment
              </span>
            );
          }
        }
    };
    
    render() {

      const {  confirmed, message } = this.state;
      const { generalassembly } = this.props;
      const selectedBtn = {
        shape: { backgroundColor: "#05C1BD" , color: "#fff" },
        text: { color: "#fff" },
      };
      
      const unselectedBtn = {
        shape: { backgroundColor: "#fff" },
        text: { color: "#000" },
      };
      const finalClass = `mr-2 bg-blue rounded-full p-2 ${
        message === "" && `disabled:opacity-50`
      }`
      
      const classParticipant = `inline-block rounded-lg py-1 px-2 border-2 ${
        generalassembly.status !== "INPROGRESS" && "disabled:opacity-50" 
      }`
        return ( 
            <div className="container mx-auto mb-40 bg-white h-screen" >
                <PageTitle title="Assemblée générale" /> 
                {this.props.isWaiting ? < Spinner /> 
                 : 
                    <main className="py-5 bg-white" >
                      <div className="bg-white">
                        <div className="border-b border-gray-300 px-2 font-bold">
                            <div className="flex mt-3" >
                              <IoLocationOutline className="inline-block" />
                              <span className="inline-block ml-3 text-sm">
                                {moment(generalassembly.held_date).format("dddd, DD MMMM YYYY")} de {generalassembly.start_time} à {generalassembly.end_time}
                              </span> 
                            </div> 
                            <div className="flex mt-3" >
                              <IoLinkOutline className="inline-block" />
                              <a className="inline-block ml-3 text-sm"
                                href={generalassembly.meet_link}
                                target="_blank"
                                rel="noreferrer noopener" >
                                <span className="text-blue" > 
                                  {generalassembly.meet_link}
                                </span>
                              </a>
                            </div> 
                            <p className="text-sm py-2">Date limite pour les modifications: {moment(generalassembly.deadline).format("dddd, DD MMMM YYYY")} </p> 
                            <p className="text-sm py-2 pb-6">Objet: 
                              <span className="pl-2 text-black">{generalassembly.title}</span>
                            </p> 
                        </div>
                        <dd className="py-6 px-2 break-words text-gray-600 border-b border-gray-300 mb-4">
                          <div dangerouslySetInnerHTML={{ __html: generalassembly.description }}></div>
                            <div className="relative py-8">
                              <Link 
                                to={{
                                  pathname: `/agenda/${generalassembly.id}`,
                                  state: { agendas: generalassembly.agendas }
                                }}
                                className="absolute bottom-0 right-0 bg-blue px-4 py-2 text-white rounded-md mt-16 ">
                                  Voir l'ordre du jour
                              </Link>
                            </div>
                        </dd>
                        {/* convocation  */}
                       
                        <div className="flex justify-between px-2 mb-4">
                          <div>
                              <button 
                                disabled={generalassembly.status === 'INPROGRESS' ? false : true}
                                className={classParticipant}
                                style={
                                  confirmed === "NO"
                                      ? selectedBtn.shape
                                      : unselectedBtn.text
                                }
                                onClick={() => this.confirmParticipation("NO")}
                              > Je participe pas </button>
                          </div>
                            <div>
                              <button 
                              disabled={generalassembly.status === 'INPROGRESS' ? false : true}
                              className={classParticipant}
                                style={
                                  confirmed === "YES"
                                    ? selectedBtn.shape
                                    : unselectedBtn.text
                                }
                                onClick={() => this.confirmParticipation("YES")}
                              > Je participe </button>
                            </div>
                            <div>
                                <button 
                                  disabled={generalassembly.status === 'INPROGRESS' ? false : true}
                                  className={classParticipant}
                                  style={
                                  confirmed === "MAYBE"
                                      ? selectedBtn.shape
                                      : unselectedBtn.text
                                  }
                                  onClick={() => this.confirmParticipation("MAYBE")}
                                > Peut-être </button>
                            </div>
                        </div>
                        <p className="border-t border-b border-gray-300 p-2 w-72 ml-2 mb-6">
                          {this.renderParticipants()}
                        </p>

                        <div className="flex justify-between px-6 mb-10">
                            <Modal views={this.props.views} />
                            {/* Comments */}
                            <div className="inline-block rounded-xl border-blue-400 bg-gray-200 px-6 py-1 shadow-lg">
                                <div className="flex-row">
                                    <IoChatboxOutline className="inline-block" />{" "}
                                    <span className="inline-block">
                                        {generalassembly.nbrComments}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <hr className="my-2" />
                      </div>
                        <div className="flex-col w-full px-2 mb-32 ">
                            <Comment  
                              chats={generalassembly.chats}
                              onClick={this.handlClick}
                            />
                        </div>
                        <div className="flex flex-wrap fixed bottom-14 md:bottom-20 w-full shadow-xl bg-white py-1 -m-1 md:-mb-2 border-t border-gray-300">
                          {this.state.reply_chat_id !== "" &&
                            <div className="w-full text-sm px-4">
                              <span> Repondre à <b>{this.state.reply_chat_name}</b></span>
                              <button className="pl-2 text-right" 
                                onClick={(e) => this.setState({reply_chat_id: ""})} 
                              >annuler</button>
                            </div>
                          }
                          <div className="px-2 pt-2 flex-auto">
                            <textarea
                                type="text"
                                name="message"
                                value={this.state.message}
                                onChange={this.handleChange}
                                placeholder="Laissez un commentaire..."
                                className="w-custom rounded-2xl text-sm bg-gray-50 h-12 px-2"
                            />
                            </div>
                            <div className="flex-none mt-4">
                            
                              <button 
                                disabled={message === "" ? true : false} 
                                className={finalClass} 
                                onClick={this.handleSubmit} >
                                <IoSend size={20} color="#FFF" />{" "}
                              </button>
                             
                            </div>
                          </div>
                    </main> 
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

    generalassembly:  state.generalassemblies.generalAssembly,
    totalParticipant: state.generalassemblies.totalParticipant,
    defaultConfirmed: state.generalassemblies.confirmed,
    isWaiting: state.generalassemblies.isWaiting,
    countViews: state.views.countViews,
    views: state.views.allViews,
});


  const mapDispatchToProps = (dispatch) => {
    return {
      getGeneralAssembly:(id) => {
        dispatch(fetchGeneralAssembly(id))
      },
      onAddParticipant: (ag_id, confirmed) => {
        dispatch(addParticipant(ag_id, confirmed));
      },
      onIncrementView: ( fields) => {
        dispatch(incrementView(fields));
      },
      onAddComment: (comment) => {
        dispatch(addComment(comment));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(GeneralAssembly);