import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { IoSearchOutline } from "react-icons/io5";

import { searchFor } from "../../features/feeds/feedsSlice";
import ListTabs from "./listTabs";

const Tabs = ({ title1, title2, openIncidents, incidentsClosed }) => {
  const dispatch = useDispatch();
  const [openTab, setOpenTab] = useState(1);
  const [state, setState] = useState({
    text: "",
  });

  useEffect(() => {
    dispatch(searchFor(state.text));
  }, [dispatch, state.text]);

  function handleChange(event) {
    let fieldName = event.target.name;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul className="flex mb-0 list-none pt-3 pb-4 flex-row" role="tablist">
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase py-3 block leading-normal " +
                  (openTab === 1
                    ? "text-vert_2 border-b-2 border-black border-opacity-100"
                    : "text-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                {title1}
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase py-3 block leading-normal " +
                  (openTab === 2
                    ? "text-vert_2 border-b-2 border-black border-opacity-100"
                    : "text-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                {title2}
              </a>
            </li>
          </ul>
          <div className="px-2">
            <IoSearchOutline
              size={24}
              className="absolute z-20 m-1 mt-2 text-blue-400 font-bold"
            />
            <input
              type="text"
              name="text"
              value={state.text}
              autoComplete="off"
              onChange={handleChange}
              className="pl-8 p-1 mb-6 bg-gray-200 w-full text-blod rounded-xl h-10 relative outline-none border-gray-200"
              placeholder="Rechercher"
            />
          </div>
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="px-4 py-2 flex-auto bg-white border">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <ListTabs incidents={openIncidents} />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <ListTabs incidents={incidentsClosed} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Tabs.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  incidentOuvert: PropTypes.array,
  incidentCloses: PropTypes.array,
};

Tabs.defaultProps = {
  incidentOuvert: [],
  incidentCloses: [],
};
export default Tabs;
