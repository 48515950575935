import React from "react"

export default function BadgeOutline({
  className = "",
  text = "New",
  type = "primary",
  ...newProps
}) {
  let finalClass = `${className} px-2 text-sm border  rounded-full capitalize`
  if (type === "primary") finalClass += " text-blue border-blue"
  else if (type === "secondary") finalClass += " text-gray-500 border-gray-500"
  else if (type === "success") finalClass += " text-green-500 border-green-500"
  else if (type === "danger") finalClass += " text-red-500 border-red-500"
  else if (type === "warning") finalClass += " text-yellow-500 border-yellow-500"
  else if (type === "info") finalClass += " text-indigo-700 border-indigo-700"
  else if (type === "light") finalClass += " text-gray-200 border-gray-200"
  else if (type === "dark") finalClass += " text-gray-900 border-gray-900"
  return (
    <span className={finalClass} {...newProps}>
      {text}
    </span>
  )
}
