import PropTypes from "prop-types";

import Currency from "../../common/Currency";
import FormatDate from "../../common/FormatDate";

const CustomUnpai = ({periodicity, payment_date, apparts_payments}) =>{
    return (

        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="py-1 sm:px-0">
                <div className="bg-white border-gray-200 rounded-lg px-4 py-4">
                    <div className="grid grid-cols-6 gap-4 text-md font-bol">
                        <div className="col-start-1 col-end-7 font-bold ">
                            {periodicity}
                        </div>
                        <div className=" col-start-1 col-end-8 ">
                            Date limite de paiement:<span className="text-md font-bold"> {FormatDate(payment_date)}</span>
                        </div>
                        <div className=" col-start-1 col-end-8 text-md">
                            <ul>
                                {apparts_payments.map((appart_payment)=>
                                    <li key={appart_payment.title}>
                                        <span className="text-gray-600 opacity-100">{appart_payment.title}: </span> 
                                        <span className=" font-bold">{Currency(appart_payment.amount)} CFA</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    );
}

CustomUnpai.propTypes = {

    payment_date: PropTypes.string,
    periodicity: PropTypes.string,
    appart_payment: PropTypes.array,
  
};

CustomUnpai.defaultProps = {

    appart_payment: []
 }
export default CustomUnpai