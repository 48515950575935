import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IoSearchOutline } from "react-icons/io5";
import ListCoowner from "./ListCoowner";
import ListPersonnel from "./ListPersonnel";
import Listresident from "./ListResident";

import Permission from "@easytool/react-permission";
import Role from "../../permission";

const Tabs = (props) => {
  const { title1, title2, title3, coowners, personnel, residents } = props;
  const [state, setState] = useState({
    text: " ",
    coowners: coowners,
    personnel: personnel,
    residents: residents,
  });
  const [openTab, setOpenTab] = useState(1);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      coowners,
      personnel,
      residents,
    }));
  }, [coowners, personnel, residents]);

  function handleChange(event) {
    let fieldName = event.target.name;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));

    setState((prevState) => ({
      ...prevState,
      coowners: coowners.filter((item) => {
        let fullname =
          item?.first_name.toLowerCase().replace(/ /g, "") +
          item?.last_name.toLowerCase().replace(/ /g, "");
        let text = event.target.value.toLowerCase().replace(/ /g, "");

        return fullname.normalize().includes(text.normalize()) ? item : null;
      }),
      personnel: personnel.filter((item) => {
        let fullname =
          item?.first_name.toLowerCase().replace(/ /g, "") +
          item?.last_name.toLowerCase().replace(/ /g, "");
        let text = event.target.value.toLowerCase().replace(/ /g, "");

        return fullname.normalize().includes(text.normalize()) ? item : null;
      }),
      residents: residents.filter((item) => {
        let fullname =
          item?.first_name.toLowerCase().replace(/ /g, "") +
          item?.last_name.toLowerCase().replace(/ /g, "");
        let text = event.target.value.toLowerCase().replace(/ /g, "");

        return fullname.normalize().includes(text.normalize()) ? item : null;
      }),
    }));
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <ul className="flex mb-0 list-none pt-3 pb-4 flex-row" role="tablist">
          <Permission hasPermission={Role}>
            <li
              permission="coowner"
              className="-mb-px mr-2 last:mr-0 flex-auto text-center"
            >
              <a
                className={
                  "text-sm font-bold uppercase px-0 py-3 block leading-normal " +
                  (openTab === 1
                    ? "text-vert_2 border-b-2 border-black border-opacity-100"
                    : "text-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                {title1}
              </a>
            </li>
          </Permission>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-sm font-bold uppercase px-0 py-3 block leading-normal " +
                (openTab === 2
                  ? "text-vert_2 border-b-2 border-black border-opacity-100"
                  : "text-blue")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              {title2}
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-sm font-bold uppercase px-0 py-3 block leading-normal " +
                (openTab === 3
                  ? "text-vert_2 border-b-2 border-black border-opacity-100"
                  : "text-blue")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              {title3}
            </a>
          </li>
        </ul>
        <div className="px-2">
          <IoSearchOutline
            size={24}
            className="absolute z-20 m-1 mt-2 text-blue-400"
          />
          <input
            type="text"
            name="text"
            value={state.text}
            autoComplete="off"
            onChange={(e) => handleChange(e)}
            placeholder="Rechercher"
            className="pl-8 p-1 mb-6 bg-gray-200 w-full text-blod rounded-xl h-10 relative outline-none border-gray-200"
          />
        </div>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full ">
          <div className="px-1 py-1 flex-auto">
            <div className="tab-content tab-space">
              <ListCoowner coowners={state.coowners} openTab={openTab} />
              <ListPersonnel personnel={state.personnel} openTab={openTab} />
              <Listresident residents={state.residents} openTab={openTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  /**
   * The page title.
   */
  title1: PropTypes.string,
  title2: PropTypes.string,
  coowners: PropTypes.array,
  personnel: PropTypes.array,
};

Tabs.defaultProps = {
  coowners: [],
  personnel: [],
};
export default Tabs;
