import React from 'react';

import PageTitle from '../../../common/PageTitle';
import FormGroup from '../../form/FormGroup';

class Step2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkedItemsAppart:[],
            checkedItemsPartiecoms:[],
            isChecked: true,
            isCheckedAppart: false,
        }

    }


    handleChangePartiecomes = (e) => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    handleChangeAppartments = (e) => {
        this.setState({
            isCheckedAppart: !this.state.isCheckedAppart
        })
    }


    

    handleClickAppart = (e) => {

        let log = {}
        log["name"] = e.target.value;
        log["isChecked"] = e.target.checked;
        if (e.target.checked) {
            this.state.checkedItemsAppart.push(log)
        }else{
            const result = this.state.checkedItemsAppart.find( ({ name }) => name === e.target.value );
            const filtered = this.state.checkedItemsAppart.filter( ({ name }) => name !== result.name );
            this.setState({checkedItemsAppart:filtered})
        }
    }

    // if particomes is checked
    handleClickParticomes = (e) => {

        let log = {}
        log["name"] = e.target.value;
        log["isChecked"] = e.target.checked;
        if (e.target.checked) {
            this.state.checkedItemsPartiecoms.push(log)
        }else{
            const result = this.state.checkedItemsPartiecoms.find( ({ name }) => name === e.target.value );
            const filtered = this.state.checkedItemsPartiecoms.filter( ({ name }) => name !== result.name );
            this.setState({checkedItemsPartiecoms:filtered})
        }
    }


    changeBg(index){
        for (const iterator of this.state.checkedItemsAppart){
            if (index ===  parseInt(iterator.name)) {
                return "bg-vert_2 border-2 text-white border-vert_2"
            } 
        }
    }

    changeBgParticomes(index){
        for (const iterator of this.state.checkedItemsPartiecoms){
            if (index ===  parseInt(iterator.name)) {
                return "bg-vert_2 border-2 text-white border-vert_2"
            } 
        }
    }

    saveAndContinue = (e) => {
        e.preventDefault()
        // if (this.props.validateForm2()) {
            this.props.nextStep()
        // }
        
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }


    render(){
        const checkboxClass = "w-8 min-w-full py-2 border-2 rounded-lg border-gray-400 text-center uppercase"
       
        return(
            <>
                <PageTitle title="Déclarer un incident" />
                <main className="w-full bg-white border-gray-200 rounded-lg px-2 py-6 pb-10 mt-4 mb-4">
                    <form className="mb-10">
                        <p className="text-base text-gray-600 py-4  mb-2 uppercase">Lieu de l'incident</p>
                        <div className="flex items-center mb-10">
                            <input 
                                className={`rounded h-6 w-6`} 
                                id="appartment" 
                                type="checkbox" 
                                name="mon_appart"
                                defaultChecked={!this.state.isCheckedAppart}
                                onChange={this.handleChangeAppartments}
                                value={1}
                            />
                            <label htmlFor="appartment" className="pl-2 block text-lg font-medium text-gray-700"> Mon appartement </label>
                        </div>

                        {!this.state.isCheckedAppart &&
                         <FormGroup>
                            <div className="grid grid-cols-2 gap-2">
                              {this.props.appartments.map(item =>
                                <div key={item.id} className="mb-6">
                                <input 
                                    className="hidden" 
                                    type="checkbox" 
                                    id={item.id}
                                    name={item.id} 
                                    defaultChecked={this.props.checkedItemsAppart.get(item.id)}
                                    onClick={ this.handleClickAppart}
                                    onChange={this.props.changeAppartments('appart')}
                                    defaultValue={item.id}
                                />
                                    <label htmlFor={item.id}>
                                        <div className={`${checkboxClass} ${this.changeBg(item.id)}`}>{item.title}</div>
                                    </label>
                                </div>
                              )}
                            </div>
                        </FormGroup>
                        }
                        <div className="flex items-center mb-10">
                            <input 
                                className={`rounded h-6 w-6`}
                                id="particommes" 
                                type="checkbox" 
                                name="particommes"
                                defaultChecked={!this.state.isChecked}
                                onChange={this.handleChangePartiecomes}
                                value={2}
                            />
                            <label htmlFor="particommes" className="pl-2 block text-lg font-medium text-gray-700 "> Parties communes</label>
                        </div>
                        {!this.state.isChecked &&
                         <>
                            {/* <hr className="w-20 border-gray-700"/>
                                <h5 className="text-center -mt-3">Parties communes</h5>
                            <hr className="w-20 border-gray-700 float-right -mt-2 " /> */}
                         <FormGroup>
                           <div className="grid grid-cols-2 gap-2">
                            {this.props.particoms.map(item =>
                                <div key={item.id} className="mb-2">
                                    <input 
                                        className="hidden" 
                                        id={item.id} 
                                        name={item.id}
                                        type="checkbox" 
                                        defaultChecked={this.props.checkedItemsPartiecomes.get(item.id)}
                                        onClick={ this.handleClickParticomes}
                                        onChange={this.props.changePartiecoms('checked')}
                                        defaultValue={item.id}
                                    />
                                    <label htmlFor={item.id}>
                                        <div className={`${checkboxClass} ${this.changeBgParticomes(item.id)}`}>{item.name}</div>
                                    </label>
                                </div>
                            )}
                            </div>
                         </FormGroup>
                         </>
                        }
                    
                    </form>
                </main>
                <div className="items-baseline pt-6 text-xl font-extrabold mb-28">
                    <button  onClick={this.back} className="text-xl font-extrabold text-blue uppercase px-2">Précedent</button>
                    <button  onClick={ e => this.saveAndContinue(e) } className="text-xl font-extrabold text-blue uppercase float-right">suivant</button>
                </div>
                <br/>
            </>
        );
    }
}


export default Step2;