import React from "react";
import PageTitle from "../../common/PageTitle";
import Spinner from "../../common/Spinner";
import Tabs from "../../components/works/Tabs";
import Api from "../../api";


class Works extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openWorks:[],
            closedWorks:[],
        }
    }
  componentDidMount = () => {
    Api.getOpendWorks()
    .then(response =>{
        this.setState({
            openWorks: response.data.data
        })
    })
    .catch(error => console.log(error))

   
    Api.getClosedWorks()
    .then(response =>{
        this.setState({
            closedWorks: response.data.data
        })
    })
    .catch(error => console.log(error))

  };

  render() {

    const {openWorks, closedWorks} = this.state;
    return (
      <div className="container mx-auto">
        <PageTitle title="Liste des travaux" />
        {this.props.isWaiting && <Spinner />}
        {!this.props.isWaiting && (
          <main className="py-5 mb-20">
            <Tabs
              title1="travaux ouverts"
              title2="travaux fermés"
              openWorks={openWorks}
              closedWorks={closedWorks}
            />
          </main>
        )}
      </div>
    );
  }
}



export default Works