export default function EmptyData({ text }) {
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-2">
      <div className="py-1">
        <div className="py-4">
          <div className="flex justify-center item -mt-2 text-gray-500 opacity-70">{text}</div>
        </div>
      </div>
    </div>
  );
}
