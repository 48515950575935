
import { createSlice } from "@reduxjs/toolkit";
import { API_CENTRAL_DOMAIN } from "../../api";

const globalId = JSON.parse(localStorage.getItem("user"))?.global_id;

export const settingdSlice = createSlice({
    name: 'settings',
    initialState: {
        isWaiting: false,
        error: false,
        success: false,
    },
    reducers: {
        startWaiting: (state) => {
            state.isWaiting = true;
        },

        hasError: (state) => {
            state.error = true;
            state.isWaiting = false;
        },

        setDisplayPIISuccess: (state, action) => {
            state.success = true;
        }
    }
});

export const {
    startWaiting,
    hasError,
    setDisplayPIISuccess,
} = settingdSlice.actions;

export const updateDisplay = (data) => async (dispatch) => {

    try {
        await API_CENTRAL_DOMAIN.patch(`display_personal_infos/${globalId}`, {
                displayPII: data,
            })
            .then((response) => dispatch(setDisplayPIISuccess(response.data)))
    } catch (error) {
        dispatch(hasError(error.message));
        return console.error(error);
    }
};


export default settingdSlice.reducer;