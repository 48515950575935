import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Progressbar = ({ style, className, ...attrs }) => {
  const classes = classNames(
    className,
    "h-full bg-blue  absolute",
   
  );

  return (
    <div className="h-1 mt-4 relative max-w-xl  overflow-hidden">
        <div className="w-full h-full bg-gray-200 absolute"></div>
        <div className={classes}  {...attrs}  style={{ width: style +'%' }}></div>
  </div>
  )
};

Progressbar.propTypes = {
  /**
   * The style.
   */
  style: PropTypes.number,
  
};

export default Progressbar;
