
import Badge from "../../common/badgets/Badget";
import Currency from "../../common/Currency";
import FormatDate from "../../common/FormatDate";

export default function Payment ({payment_date, payment_periodicity, apparts_payment=[], status}){

    return (

        <li className="mb-2 ml-2 border-b boder-gray-100">
            <div className="flex items-center mb-10">
                <div className="-ml-2 bg-black rounded-full h-4 w-4 z-10"></div>
                <div className="flex-1 ml-4 font-extrabold">{FormatDate(payment_date)}
                    <span className="absolute right-2 font-semibold text-blue opacity-100">Paiement</span>
                </div>
            </div>
            <div className="flex-1 items-center mb-1">
                <div className="ml-6 -mt-6">
                    <span className="text-gray-600">Appels de fonds: </span>
                    <span className="absolute right-2 font-extrabold text-sm">{payment_periodicity}</span>
                </div>
            </div>
            {apparts_payment.map((appart_payment, index )=>

                <div key={index} className="flex-1 items-center mb-1">
                    <div className="ml-6">
                        <span className="text-gray-600">{appart_payment.appartment_title}: </span>
                        <span className="absolute right-2 font-extrabold text-sm">{Currency(appart_payment.total_paid)} CFA 
                            <span className="text-gray-600 opacity-80 font-normal"> 
                            {appart_payment.balance === 0 
                                ? ' (payé)'
                                : ' (acompte)'
                            }
                            </span>
                        </span>
                        
                    </div>
                </div>
                
            )}
            <div className="flex-1 items-center mb-4">
                <div className="ml-6 pt-2">
                    {status === "PAID" 
                        ? <Badge text="payé" type="success" />
                        : <Badge text="acompte" type="warning" />
                    }
                </div>
            </div>
        </li>
    );
}