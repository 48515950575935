import React from "react";
import { Link } from "react-router-dom";
import "./fab.css";

const Fab = () => {
  return (
    <div className="fab-container">
      <div className="fab fab-icon-holder z-10">
        <i className="fas fa-plus"></i>
      </div>

      <ul className="fab-options">
        <Link
          to={{
            pathname: `/feeds/`,
            state: {
              info: { type: "actuality" },
            },
          }}
        >
          <li>
            <span className="fab-label">Actualité</span>
            <div className="fab-icon-holder">
              <i className="fas fa-newspaper"></i>
            </div>
          </li>
        </Link>
        <Link
          to={{
            pathname: `/feeds/`,
            state: {
              info: { type: "annoucement" },
            },
          }}
        >
          <li>
            <span className="fab-label">Annonce</span>
            <div className="fab-icon-holder">
              <i className="fas fa-bullhorn"></i>
            </div>
          </li>
        </Link>
        <Link
          to={{
            pathname: `/feeds/`,
            state: {
              info: { type: "event" },
            },
          }}
        >
          <li>
            <span className="fab-label">Evenement</span>
            <div className="fab-icon-holder">
              <i className="fas fa-calendar-alt"></i>
            </div>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default Fab;
