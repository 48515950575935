

function Currency(money){
  if(money ){
      return  new Intl.NumberFormat().format(money);
  } else {
    return 0
  }
}

export default Currency