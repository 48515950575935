import { useState, useEffect } from 'react';

/**
 * This file holds all the Utilities of the project
 */

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
 * 
 * @param {*} date 
 * @returns 
 */
export const formatDate = (date) => {
  let opt = { year: "numeric", month: "long", day: "numeric" };
  let weekday_opt = { weekday: "long" };
  let dateToFormat = new Date(date);
  let weekday = toTitleCase(
    dateToFormat.toLocaleDateString("fr-FR", weekday_opt)
  );
  return weekday + ", " + dateToFormat.toLocaleDateString("fr-FR", opt);
};


export function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  });

  return windowDimensions;
}
