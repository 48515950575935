

import React  from "react"

import Button from "../components/form/Button"
import FormGroup from "../components/form/FormGroup"
import InputGroup from "../components/form/InputGroup"
import forgotPageword from './img/forgot_password.png';
import axios from "axios";
import Alert from "../common/Alert";
import { Link } from "react-router-dom";
import Api from "./../api";


class ForgotPassword extends React.Component {

    constructor(props){
      super(props);
      this.state ={
        email:'',
        email_empty:false,
        isRequestError:false,
        isValidUser:false,
        isPending:false,
        showForm:true
      }
      this.handlChange = this.handlChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
    }

      handlChange = (e) => {
        this.setState({
            email: e.target.value
        });

      }

      validateForm = () => {

        let email = this.state.email;
        let isValidForm = true;
       
          if (!email) {

              isValidForm = false ;
              this.setState({
                email_empty:true
              });
              setTimeout(() => {

                this.setState({
                  email_empty:false
                });

              }, 5000);
          }
        return isValidForm;
    }


    handleShowAndHideAlert(input){
      setTimeout(() => {
        if(input === 'credential_error'){
          this.setState({
            isValidUser: true
          });
        }
        if(input === 'success'){
          this.setState({
            showForm: false
          });
        }
       
        if(input === 'requestError'){
          this.setState({
            isRequestError: false
          });
        }
  
      }, 8000);
    }
      handleSubmit = (e) => {
        e.preventDefault()
        this.setState({isPending:true})
        if (this.validateForm()) {
          axios.post(`${Api.basUrl}/sendResetLink`, {
                email: this.state.email,
          })
          .then((res) => {
            // console.log(res.data)
              if (res.data.success === true) {
                
                this.setState({
                  isPending:false,
                  showForm: false
                });

                this.handleShowAndHideAlert('success');

              } else {

                this.setState({
                  isPending:false,
                });

                this.handleShowAndHideAlert('credential_error');
                
              }

          })
          .catch((error) =>{

            this.setState({
              isPending:false,
              isRequestError:true,
            });

            this.handleShowAndHideAlert('requestError');
          })
        }
      }



 render() {
      const {email, email_empty, showForm, isRequestError, isPending, isValidUser} = this.state
      return (
        <div className="py-6 px-6 ">
          
          {showForm ?
          <>
          <div className="ml-10 mt-10 mb-10">
              <img
              className="w-60 h-30"
              src={forgotPageword}
              alt="tet"
              />
          </div>
          <form onSubmit={this.handleSubmit} className="w-full mx-auto text-center">
            <div>
              <h2 className="text-xl font-bold text-gray-800">Réinitialisez votre mot de passe</h2>
              <p className=" py-2 text-sm text-gray-500">
                Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.
              </p>
            </div>
            <div className="mt-12">
              <FormGroup>
                <InputGroup
                  type="email"
                  name="email"
                  value={email}
                  onChange={e => this.handlChange(e)}
                  placeholder="Entrez votre adresse e-mail"
                  error={email_empty}
                  errorText="Ce champ ne peut être vide"
                />
              </FormGroup>
              {isValidUser && (
                <div className="bg-red-200 mt-2 py-2 px-4 text-base text-red-600 rounded-sm">
                      Cet email n'existe pas
                </div>
              )}
              {isRequestError && (
                <div className="bg-red-200 mt-2 py-2 px-4 text-sm text-red-600 rounded-sm">
                  La connexion a échoué. Veuillez réessayer plus tard.
                </div>
              )}
              
              {!isPending ? 
                <FormGroup>
                  <Button className="bg-blue" text="Réinitialiser le mot de passe" submit full />
                </FormGroup>
              :
                <FormGroup>
                  <Button className="bg-vert_2 text-white" text="Réinitialisation en cours"  full />
                </FormGroup>
              }
            </div>
          </form>
          </>
          :
          <div>
              <h2 className="text-xl font-bold text-gray-800 mb-10 mt-20">Réinitialisez votre mot de passe</h2>
              <Alert 
                className="bg-green-500"  
                first_mot="Bonjour"
                text="Nous venons de vous envoyer un email dans lequel vous trouverez le lien qui vous permettra de modifier votre mot de passe."
                p="Si vous ne voyez pas le message dans votre boîte de réception, nous vous suggérons de consulter vos spams"
                />
            </div>
        }
           <Link to="/" className="text-blue pt-2 italic">Retour à la page de connexion</Link>
        </div>
      )
       
   }
}

export default ForgotPassword