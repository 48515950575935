import PropTypes from "prop-types";

import {
    IoChevronForwardOutline,
    IoWarningOutline,
  } from "react-icons/io5";
import { Link } from "react-router-dom";
import TypeIncident from "../../common/TypeIncident";
import EmptyData from "../../common/EmptyData";


const OpenWorks = ({openWorks}) =>{
    // console.log(openWorks)

    return (
        <div className="max-h-full">
        {openWorks.map((work) => (
          <Link
            to={`/incident/` + work?.id}
            key={work.id}
            className="px-1 mb-1 flex flex-row sm:flex-row items-center justify-between"
          >
            {TypeIncident[work.catSupplier_id] ? (
              <img
                className="mx-auto h-11 w-11"
                src={TypeIncident[work.catSupplier_id]}
                alt=""
              />
            ) : (
              <IoWarningOutline size={44} />
            )}
            <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400 w-0">
              <p className="text-sm font-black capitalize truncate">
                {work.title.substring(0, 35).toLowerCase()}
                {work.title.length > 35 && "..."}
              </p>
              <p className="text-sm font-medium text-gray-500">
                <span>{work.catSupplier_id}</span>
                <span className="text-base font-bold"> .</span>
                {work.appartments.length > 0 && (
                  <span className="ml-1">Appartments</span>
                )}
                {work.partiecoms.length > 0 && (
                  <span className="ml-1">| Partie coms</span>
                )}
              </p>
              <p className="text-sm font-medium text-gray-500 truncate mr-2">
                {work.description}
              </p>

              <p className="flex justify-center pr-4 -mt-10 items-end float-right">
                <IoChevronForwardOutline size={15} />
              </p>
              <div className="flex justify-between items-center my-1"> 
                <p className="">
                  {work.emergency_level === 2 && (
                    <span className="px-2 text-sm border-2 text-red-500 border-red-500 rounded-full capitalize">
                      Urgent
                    </span>
                  )}
                </p>
                <p className="flex justify-center items-end float-right text-gray-500 text-sm">
                  {work.timeLineLabel}
                </p>
              </div>
            </dd>
          </Link>
        ))}
        {openWorks.length === 0 && (
          <EmptyData text="Aucun travaux ouvert pour le moment." />
        )}
      </div>
    )
}

OpenWorks.propTypes = {

    openWorks: PropTypes.array,
};
  
OpenWorks.defaultProps = {
    openWorks: []
};
  
export default OpenWorks;

