import axios from "axios";

//localStorage
let user = localStorage.getItem("user");
const building_id = localStorage.getItem("building_id");
const tenant_id = localStorage.getItem("tenant_id");
const domain = localStorage.getItem("domain");
user = JSON.parse(user);

//propd

// const basUrl = 'https://master.mysyndic.app/api'
// const tenantUrl = 'https://'+domain+'.mysyndic.app'
// const fileEnpoint = tenantUrl+'/storage/tenant'+tenant_id

// test en prod
const basUrl = "https://master.testmysyndic.app/api";
const tenantUrl = "https://" + domain + ".testmysyndic.app";
const fileEnpoint = tenantUrl + "/storage/tenant" + tenant_id;

//development

// const basUrl = 'http://teranga.localhost:8000/api'
// const tenantUrl = 'http://'+domain+'.teranga.localhost:8000'
// const fileEnpoint = tenantUrl+'/storage/tenant'+tenant_id

const token = localStorage.getItem("token");
const instanceWithSubdomain = axios.create({
  baseURL: tenantUrl,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
});

const Api = {
  basUrl,
  tenantUrl,
  fileEnpoint,

  getIncidentUpdatebyincidentId: (id) =>
    instanceWithSubdomain({
      method: "GET",
      url: `/api/incident/incidentupdate/${id}`,
    }),

  getIncidentbyId: (id) =>
    instanceWithSubdomain({
      method: "GET",
      url: `/api/incident/show/${id}`,
    }),

  getEvaluations: () =>
    instanceWithSubdomain({
      method: "GET",
      url: `/api/evaluations/${building_id}/${user?.global_id}`,
    }),

  getEvaluationById: (id) =>
    instanceWithSubdomain({
      method: "GET",
      url: `/api/show_evaluation/${id}/${user?.global_id}`,
    }),

  getIncidentOpend: () =>
    instanceWithSubdomain({
      method: "GET",
      url: `/api/incident/openIncidents/${building_id}/${user?.global_id}`,
    }),

  postReponseEvaluation: (data) =>
    instanceWithSubdomain({
      method: "POST",
      url: `/api/reponseEvaluation`,
      data: data,
    }),

  declaredAnIncident: (data) =>
    instanceWithSubdomain({
      method: "POST",
      url: `/api/incident/create`,
      data: data,
    }),

    uploadFile: (data, id) =>
    instanceWithSubdomain({
      method: "POST",
      url: `/incident/upload_file/${id}`,
      data: data,
    }),

    getDocument: () =>
      instanceWithSubdomain({
          'method':'GET',
          'url': `/api/documents/${building_id}`
      }),
    
   

  markNotificationAsRead: (data) =>
    instanceWithSubdomain({
      method: "POST",
      url: `/api/mark-as-read`,
      data: data,
    }),

  updatePassword: (data) =>
    instanceWithSubdomain({
      method: "POST",
      url: `/api/update_password`,
      data: data,
    }),
};

export default Api;
