import axios from "axios";
import APP_ENV from "../configs/env";

/**
 * Local variables fetching ...
 */
const global_id = JSON.parse(localStorage.getItem("user"))?.global_id;
const building_id = localStorage.getItem("building_id");
const tokenCentralDomain = localStorage.getItem("tokenCentralDomain");
const token = localStorage.getItem("token");

export const API_CENTRAL_DOMAIN = axios.create({
  baseURL: APP_ENV.centralDomain,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${tokenCentralDomain}`,
  },
});

export const api = axios.create({
  baseURL: APP_ENV.baseURL,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

// The Api object is there to make the legacy code work well.
const Api = {
  basUrl: APP_ENV.centralDomain,
  tenantUrl: APP_ENV.tenantURL,
  fileEnpoint: APP_ENV.fileENDPOINT,

  getIncidentUpdatebyincidentId: (id) =>
    api({
      method: "GET",
      url: `/incident/incidentupdate/${id}`,
    }),

  getIncidentbyId: (id) =>
    api({
      method: "GET",
      url: `/incident/show/${id}`,
    }),

  getEvaluations: () =>
    api({
      method: "GET",
      url: `/evaluations/${building_id}/${global_id}`,
    }),
    
  getEvaluationById: (id) =>
    api({
      method: "GET",
      url: `/show_evaluation/${id}/${global_id}`,
    }),

  getIncidentOpend: () =>
    api({
      method: "GET",
      url: `/incident/openIncidents/${building_id}/${global_id}`,
    }),

  getOpendWorks: () =>
    api({
      method: "GET",
      url: `/work/openWorks/${building_id}/${global_id}`,
    }),

  getClosedWorks: () =>
    api({
      method: "GET",
      url: `/work/closedWorks/${building_id}/${global_id}`,
    }),

  postReponseEvaluation: (data) =>
    api({
      method: "POST",
      url: `/reponseEvaluation`,
      data: data,
    }),

  declaredAnIncident: (data) =>
    api({
      method: "POST",
      url: `/incident/create`,
      data: data,
    }),

  uploadFile: (data, id) =>
    api({
      method: "POST",
      url: `/incident/upload_file/${id}`,
      data: data,
    }),

  markNotificationAsRead: (data) =>
    api({
      method: "POST",
      url: `/mark-as-read`,
      data: data,
    }),

  updatePassword: (data) =>
    api({
      method: "POST",
      url: `/update_password`,
      data: data,
    }),
};

export default Api;