import React from 'react'
import { IoPersonCircleOutline } from 'react-icons/io5'
import PageTitle from '../../common/PageTitle'



class  Personnel extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
           personnel:{}
        }
    }

    componentDidMount = () =>{
        let id = this.props.match.params.id;
        let annuaire =  localStorage.getItem('annuaire');
        annuaire = JSON.parse(annuaire);

        let personnel =  annuaire?.contacts.filter(function(f) {
            return f.id === parseInt(id)
        })
       
        if (personnel.length > 0) {
            this.setState({
                personnel: personnel[0],
            })
        }
    }




render(){
    const {personnel} = this.state
    return ( 
        <>
         <PageTitle title="Information du personnel" />
        <div className="flex items-center h-screen w-full justify-center ">
             <div className="w-full">
                <div className="bg-white rounded-lg py-3 -mt-48">
                    <div className="photo-wrapper p-2 ml-28">
                        {/* <img className="w-32 h-32 rounded-full mx-auto" src="https://www.gravatar.com/avatar/2acfb745ecf9d4dccb3364752d17f65f?s=260&d=mp" alt="John Doe" /> */}
                      <IoPersonCircleOutline size={100}/>
                    </div>
                    <div className="p-2">
                        <h3 className="text-center text-xl text-gray-900 font-medium leading-8">{personnel.first_name}  &nbsp;{personnel.last_name}</h3>
                        <div className="text-center text-gray-400 text-xs font-semibold">
                            <p>{personnel.function}</p>
                        </div>
                        <table className="text-xs my-3">
                            <tbody><tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold">Adresse</td>
                                <td className="px-2 py-2">{personnel.adresse}-{personnel.ville}</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold">Téléphone</td>
                                <td className="px-2 py-2">{personnel.phone}</td>
                            </tr>
                            {personnel.whatsapp !== null &&
                            <tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold">Numéro whatsapp</td>
                                <td className="px-2 py-2">{personnel.whatsapp}</td>
                            </tr>
                            }
                            <tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold">E-mail 1</td>
                                <td className="px-2 py-2">{personnel.email1}</td>
                            </tr>
                            {personnel.email2 !== null &&
                            <tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold">E-mail 2</td>
                                <td className="px-2 py-2">{personnel.email2}</td>
                            </tr>
                            }
                        </tbody>
                        </table>

                        {/* <div className="text-center my-3">
                            <a className="text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium" href="#">View Profile</a>
                        </div> */}

                    </div>
                </div>
            </div>

        </div>
        </>
    );
  }
}

export default Personnel