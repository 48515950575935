import React from "react";
import { FiUser } from "react-icons/fi";
import { formatDate } from "../../utils/utils";

const Comment = ({ info }) => {
  let comments = [...info?.comments];
  return comments
    ?.sort((x, y) => new Date(y.created_at) - new Date(x.created_at))
    .map((comment, i) => (
      <div className="flex mt-3 w-full" key={i}>
        <div className="inline-block">
          {info?.avatar ? (
            <img
              className="rounded-full w-14 h-12 object-cover"
              src={info.avatar}
              alt=""
            />
          ) : (
            <FiUser className="rounded-full w-11 h-11 bg-gray-200" />
          )}
        </div>
        <div className="ml-1 w-full">
          <div className="flex-col rounded-lg bg-gray-200 w-full p-2">
            <div className="flex justify-between items-center">
              <div className="inline-block">
                <span className="font-bold">{comment?.created_by?.name}</span>
              </div>
              <div className="inline-block text-xs font-thin ml-8 text-gray-400">
                <span>{formatDate(comment?.created_at)}</span>
              </div>
            </div>

            <div className="mt-2">
              <p>{comment?.body}</p>
            </div>
          </div>
        </div>
      </div>
    ));
};

export default Comment;
