import { api } from "../../api";
import { createSlice } from "@reduxjs/toolkit";
const building_id = localStorage.getItem("building_id");
const global_id = JSON.parse(localStorage.getItem("user"))?.global_id;

const interventionsSlice = createSlice({
  name: "interventions",
  initialState: {
    intervenorInterventions: [],
    formatedInterventions: [],
    interventions: [],
    intervention: [],
    isWaiting: false,
    error: false,
  },
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    hasError: (state) => {
      state.error = true;
      state.isWaiting = false;
    },
    getInterventionsSuccess: (state, action) => {
      state.interventions = action.payload
      .sort(
        (x, y) => new Date(y.created_at) - new Date(x.created_at)
      );
      state.isWaiting = false;
    },
    getInterventionSuccess: (state, action) => {
      state.intervention = Object.keys(action.payload).map(function (key) {
        return action.payload[key];
      });
      state.isWaiting = false;
    },
    getIntervenorInterventionsSuccess: (state, action) => {
      state.intervenorInterventions = action.payload;

      let interventions = action.payload.filter(
        (item) => item.title === "Intervention"
      );
      let set = Array.from(
        new Set(
          interventions.map((item) =>
            new Date(
              ...new Date(item.created_at).toDateString().split("/")
            ).getTime()
          )
        )
      );

      let good = [];

      set.forEach((item) => {
        good.push({
          date: item,
          interventions: interventions.filter(
            (intervention) =>
              new Date(
                ...new Date(intervention.created_at).toDateString().split("/")
              ).getTime() === item
          ),
        });
      });

      state.formatedInterventions = good;

      state.isWaiting = false;
    },
    createInterventionSuccess: (state) => {
      state.isWaiting = false;
    },
    updateInterventionSuccess: (state) => {
      state.isWaiting = false;
    },
    deleteInterventionSuccess: (state) => {
      state.isWaiting = false;
    },
  },
});

export const {
  getIntervenorInterventionsSuccess,
  startWaiting,
  hasError,
  getInterventionSuccess,
  getInterventionsSuccess,
  createInterventionSuccess,
  updateInterventionSuccess,
  deleteInterventionSuccess,
} = interventionsSlice.actions;

export const fetchInterventions = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.get(`/interventions/${building_id}`).then((response) => {
      dispatch(getInterventionsSuccess(response.data));
    });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const fetchIntervention = (id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .get(`/incident/show-by-intervenor/${id}/${global_id}/`)
      .then((response) => {
        dispatch(getInterventionSuccess(response.data.data));
      });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const fetchIntervenorInterventions = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .get(`/intervenors/interventions/${global_id}`)
      .then((response) => {
        dispatch(getIntervenorInterventionsSuccess(response.data.data));
      });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const addIntervention = (intervention) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .post("/incident/intervention-intervenors/", intervention)
      .then((response) => {
        dispatch(createInterventionSuccess());
      });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const updateIntervention = (intervention, id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .post("/intervenors/updateWorks/", intervention)
      .then((response) => {
        dispatch(updateInterventionSuccess(response.data));
      });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export const deleteIntervention = (id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.delete("/interventions/" + id).then((response) => {
      dispatch(deleteInterventionSuccess(response.data));
      dispatch(fetchInterventions());
    });
  } catch (error) {
    dispatch(hasError());
    return console.error(error);
  }
};

export default interventionsSlice.reducer;
