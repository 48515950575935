import React from "react";
import { GoComment, GoLocation } from "react-icons/go";
import { BsClock } from "react-icons/bs";
import { IoEyeOutline } from "react-icons/io5";
import Comment from "../../customs/comment";
import { formatDate } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { FiEdit, FiUser } from "react-icons/fi";
import { connect } from "react-redux";
import {
  addComment,
  addParticipant,
} from "../../../features/feeds/feedsSlice";
import APP_ENV from "../../../configs/env";
import EmptyData from "../../../common/EmptyData";
import { incrementView} from "../../../features/views/viewSlice";
import viewableType from "../../../viewableType";

const user_id = localStorage.getItem("user_id");

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main p-4 rounded-lg">{children}</section>
    </div>
  );
};

class FeedDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: props.location.state.info,
      showMoreOrLess: "Voir Plus",
      lineClamp: false,
      show: false,
      comment: "",
      participationStatus: "may-be",
      participant: "",
      view_fields:{
        user_id : user_id,
        viewable_type : viewableType[1],
        viewable_id : props.location.state.info.id
      }
    };
  }

  componentDidMount() {
    
    this.props.onIncrementView(this.state.view_fields);
  

    const wereAlreadyParticipant = this.state.info?.participants.some(
      (el) => el.participant.id === parseInt(user_id)
    );

    if (wereAlreadyParticipant) {
      let participant = this.state.info?.participants.filter((item) => {
        return item.participant.id === parseInt(user_id);
      });

      this.setState((prevState) => ({
        ...prevState,
        participationStatus: participant[0].status,
      }));
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };

  toggleLineClamp = () => {
    this.setState((prevState) => ({
      ...prevState,
      lineClamp: !prevState.lineClamp,
      showMoreOrLess: prevState.lineClamp ? "Voir Plus" : "Voir Moins",
    }));
  };

  handleChange = (event) => {
    var fieldName = event.target.name;
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  };

  handleSubmit = async (event) => {
    let comment = {
      created_by: user_id,
      feed_id: this.state.info.id,
      body: this.state.comment,
    };

    this.setState((prevState) => ({
      ...prevState,
      comment: "",
    }));
    await this.props.onAddComment(comment);
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          comments: [...prevState.info.comments, this.props.comment],
        },
      }));
    }, 3000);

    event.preventDefault();
  };

  getImageURI = () => {
    let uri = "";
    if (
      Object.values(this.state.info?.images)[0]?.original_url?.split(
        "storage"
      )[1]
    ) {
      uri =
        APP_ENV.fileENDPOINT +
        Object.values(this.state.info?.images)[0]?.original_url?.split(
          "storage"
        )[1];
    }
    return uri;
  };

  setParticipationStatus = async (status) => {
    this.setState((prevState) => ({
      ...prevState,
      participationStatus: status,
    }));

    await this.props.onAddParticipant(user_id, this.state.info?.id, status);

    const wereAlreadyParticipant = await this.state.info?.participants?.some(
      (el) => el.participant.id === parseInt(user_id)
    );

    if (wereAlreadyParticipant) {
      if (status !== "yes") {
        let participants = this.state.info?.participants?.filter(
          (item) => item.participant.id !== parseInt(user_id)
        );

        this.setState((prevState) => ({
          ...prevState,
          info: {
            ...prevState.info,
            participants: [...participants],
          },
        }));
      }
    } else {
      if (status === "yes") {
        setTimeout(() => {
          this.setState((prevState) => ({
            ...prevState,
            info: {
              ...prevState.info,
              participants: [
                ...prevState.info.participants,
                ...this.props.participant,
              ],
            },
          }));
        }, 3000);
      }
    }
  };

  renderParticipants = () => {
    if (this.state.info.participants.length === 0) {
      return <span>Il y'a aucun participants pour le moment</span>;
    } else {
      if (this.state.participationStatus === "yes") {
        return (
          <span>
            Vous et {this.state.info.participants.length - 1} personnes vont
            participer
          </span>
        );
      } else {
        return (
          <span>
            Il y'a {this.state.info.participants.length} participants pour le
            moment
          </span>
        );
      }
    }
  };

  render() {
    const selectedBtn = {
      shape: { backgroundColor: "#05C1BD" },
      text: { color: "#fff" },
    };

    const unselectedBtn = {
      shape: { backgroundColor: "#fff" },
      text: { color: "#000" },
    };

    return (
      <div className="py-9 card-bg">
        {this.state.show && (
          <div id="modal" onClick={this.toggleModal}>
            <Modal show={this.state.show} handleClose={this.toggleModal}>
              {this.props.views.map((view, i) => (
                <div className="flex items-center m-2" key={i}>
                  <div className="inline-block">
                    {view?.user?.avatar ? (
                      <img
                        className="rounded-full w-14 h-12 object-cover"
                        src={view?.user?.avatar}
                        alt=""
                      />
                    ) : (
                      <FiUser className="rounded-full w-11 h-11 bg-gray-200" />
                    )}
                  </div>
                  <span className="inline-block ml-1 text-sm">
                    {view.user.id === parseInt(user_id) ? ' Vous ' : view?.user?.name }
                  </span>
                </div>
              ))}
              {this.props.views?.length === 0 && (
                <EmptyData
                  text={`Personne n'a encore vu ${
                    {
                      event: "l'evenement",
                      annoucement: "l'annonce",
                      actuality: "l'actualité",
                    }[this.state.info.type]
                  }.`}
                />
              )}
            </Modal>
          </div>
        )}
        
        <div className="mt-8 mx-2">
          <div className="px-4">
            <div className="flex justify-between w-full justify-items-center">
              <div className="inline-block">
                <span className="text-3xl font-bold capitalize">
                  {this.state.info?.title?.toLowerCase()}
                </span>
              </div>
              {this.state.info.created_by.id === parseInt(user_id) && (
                <div className="inline-block">
                  <Link
                    to={{
                      pathname: `/feeds/`,
                      state: {
                        info: {
                          type: this.state.info?.type,
                          ...this.state.info,
                        },
                      },
                    }}
                  >
                    <FiEdit className="inline-block" size={20} />
                  </Link>
                </div>
              )}
            </div>
            <div>
              {this.state.info.type === "event" && (
                <>
                  {/* First line */}
                  <div className="flex mt-3">
                    <BsClock className="inline-block" />
                    <span className="inline-block ml-7 text-sm">
                      Le {formatDate(this.state.info.date)}
                    </span>
                  </div>
                  {/* Second line */}
                  <div className="flex mt-3">
                    <GoLocation className="inline-block" />
                    <span className="inline-block ml-7 text-sm">
                      {`${this.state.info.place} à ${
                        this.state?.info?.time?.split(":")[0]
                      }h ${this.state?.info?.time?.split(":")[1]}mn`}
                    </span>
                  </div>
                </>
              )}

              {/* Tird line */}
              <div className="flex items-center justify-between">
                <span className="inline-block text-sm">
                  Posté le {formatDate(this.state.info.created_at)}
                </span>
                <div className="flex items-center">
                  <div className="inline-block">
                    {this.state.info?.created_by?.avatar ? (
                      <img
                        className="rounded-full w-14 h-12 object-cover"
                        src={this.state.info?.created_by?.avatar}
                        alt=""
                      />
                    ) : (
                      <FiUser className="rounded-full w-11 h-11 bg-gray-200" />
                    )}
                  </div>
                  <span className="inline-block ml-1 text-sm">
                    {this.state.info?.created_by?.name}
                  </span>
                </div>
              </div>

              <hr className="my-2 border-gray-300"></hr>
              {this.state.info.type === "event" && (
                <>
                  <div className="flex justify-between">
                    <div
                      className="inline-block rounded-lg py-0.5 border-2"
                      style={
                        this.state.participationStatus === "no"
                          ? selectedBtn.shape
                          : unselectedBtn.shape
                      }
                      onClick={() => this.setParticipationStatus("no")}
                    >
                      {" "}
                      <span
                        className="px-2 text-sm "
                        style={
                          this.state.participationStatus === "no"
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        Je ne participe pas
                      </span>{" "}
                    </div>
                    <div
                      className="inline-block rounded-lg py-0.5 border-2"
                      style={
                        this.state.participationStatus === "may-be"
                          ? selectedBtn.shape
                          : unselectedBtn.shape
                      }
                      onClick={() => this.setParticipationStatus("may-be")}
                    >
                      {" "}
                      <span
                        className="px-2 text-sm "
                        style={
                          this.state.participationStatus === "may-be"
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        Peux-être
                      </span>{" "}
                    </div>
                    <div
                      className="inline-block rounded-lg py-0.5 border-2"
                      style={
                        this.state.participationStatus === "yes"
                          ? selectedBtn.shape
                          : unselectedBtn.shape
                      }
                      onClick={() => this.setParticipationStatus("yes")}
                    >
                      {" "}
                      <span
                        className="px-2 text-sm "
                        style={
                          this.state.participationStatus === "yes"
                            ? selectedBtn.text
                            : unselectedBtn.text
                        }
                      >
                        Je participe
                      </span>{" "}
                    </div>
                  </div>
                  <hr className="my-2 border-gray-900"></hr>
                  <div>{this.renderParticipants()}</div>
                  <hr className="my-2 border-gray-900"></hr>
                </>
              )}

              <div>
                <p
                  className={
                    !this.state.lineClamp
                      ? "line-clamp-4 leading-loose"
                      : "line-clamp-none leading-loose"
                  }
                  onClick={this.toggleLineClamp}
                >
                  {this.state.info.description}
                </p>
                <span
                  className="text-sm font-thin text-gray-400"
                  onClick={this.toggleLineClamp}
                  hidden={!(this.state.info.description.length >= 200)}
                >
                  {this.state.showMoreOrLess}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full h-2/4 mt-2">
            {/* Ici on aura le caroussel */}
            <img
              className="w-full max-w-md object-cover"
              src={this.getImageURI()}
              alt=""
            />
          </div>
          <div className="flex justify-around mt-2">
            {/* Nbr Views */}
            <div className="inline-block rounded-xl border-blue-400 bg-gray-100 px-6 py-0 shadow-lg">
              <div className="flex-row" onClick={this.toggleModal}>
                <IoEyeOutline className="inline-block" />{" "}
                <span className="inline-block">
                  {this.props.views.length}
                </span>
              </div>
            </div>
            {/* Comments */}
            <div className="inline-block rounded-xl border-blue-400 bg-gray-100 px-6 shadow-lg">
              <div className="flex-row">
                <GoComment className="inline-block" />{" "}
                <span className="inline-block">
                  {this.state.info?.comments?.length}
                </span>
              </div>
            </div>
          </div>
          <hr className="my-2"></hr>

          <div className="flex-col w-full mb-16">
            <Comment info={this.state.info} />
            <div className="flex-col mt-2 w-full items-end ">
              <textarea
                type="text"
                name="comment"
                value={this.state.comment}
                onChange={this.handleChange}
                placeholder="Ecrire un commentaire…."
                className="w-full rounded-2xl text-sm border-gray-200 h-12 grid grid-cols-1"
              />
              <input
                type="button"
                name="submit"
                value={"Envoyer"}
                hidden={this.state.comment === "" ? true : false}
                className="rounded px-3 mt-3 ml-3.5"
                onClick={this.handleSubmit}
                style={{ backgroundColor: "#1d256e", color: "#fff" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  participant: state.feeds.newParticipant,
  comment: state.feeds.newComment,
  isWaiting: state.feeds.isWaiting,
  view: state.feeds.newView,
  countViews: state.views.countViews,
  views: state.views.allViews
});

const mapDispatchToProps = (dispatch) => {
  return {
    onIncrementView: ( fields) => {
      dispatch(incrementView(fields));
    },
    onAddParticipant: (user_id, feed_id, status) => {
      dispatch(addParticipant(user_id, feed_id, status));
    },
    onAddComment: (comment) => {
      dispatch(addComment(comment));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedDetail);
