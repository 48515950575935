import DefaultLayout from './layouts/DefaultLayout';
import Tenant from './components/tenant/Tenant';
import Dashboard from './dashboard/Dashboard';
import Incident from './components/incident/Incident';
import IncidentDetails from './components/incident/IncidentDetails';
import FeedDetail from './components/babillard/feed/feed-detail';
import CRUDFeed from './components/babillard/feed/feed-crud';
import SurveyDetail from './components/babillard/survey/survey-detail';
import Annuaire from './components/annuaire/Annuaire';
import Coowner from './components/annuaire/Coowner';
import Personnel from './components/annuaire/Personnel';
import Suivibudget from './components/budget/Suivibudget'
import Notification from './components/notification/Notification';
import Evaluation from './components/evaluation/Evaluation';
import MainEvaluation from './components/evaluation/MainEvaluation';
import Main from './components/incident/addIncident/Main';
import EditPassword from './user/EditPassword';
import Evaluations from './components/evaluation/Evaluations';
import Documents from './components/document/Documents';
import Resident from './components/annuaire/Resident';
import Reservation from './screens/Reservation/index';
import ReservationDetail from './screens/Reservation/reservation-detail';
import CRUDReservation from './screens/Reservation/reservation-crud';
import Intervention from './screens/intervention';
import Calendar from './screens/calendar';
import InterventionDetail from './screens/intervention/intervention-detail';
import CRUDIntervention from './screens/intervention/intervention-crud';
import CoownerPayment from './components/coownerPayment/CoownerPayment';
import Works from './screens/works';
import GeneralAssembly from './screens/generalAssembly/GeneralAssembly';
import Agenda from './screens/generalAssembly/Agenda';
import GeneralAssemblies from './screens/generalAssembly/GeneralAssemblies';
import Settings from './layouts/settings';


const routes =  [

    {
        path: "/documents",
        exact: true,
        layout: DefaultLayout,
        component: Documents ,
    },

    {
        path: "/tenants",
        exact: true,
        layout: DefaultLayout,
        component: Tenant ,
    },
    {
        path: "/dashboard",
        exact: true,
        layout: DefaultLayout,
        component: Dashboard ,
    },
    {
        path: "/appel",
        exact: true,
        layout: DefaultLayout,
        component:  CoownerPayment,

    },
    {
        path: "/incidents",
        exact: true,
        layout: DefaultLayout,
        component:  Incident
    },
    {
        path: "/works",
        exact: true,
        layout: DefaultLayout,
        component:  Works
    },
    {
        path: "/incident/:id",
        exact: true,
        layout: DefaultLayout,
        component:  IncidentDetails
    },
    {
        path: "/feeds",
        exact: true,
        layout: DefaultLayout,
        component:  CRUDFeed
    },
    {
        path: "/feeds/:id",
        exact: true,
        layout: DefaultLayout,
        component:  FeedDetail
    },
    {
        path: "/reservations",
        exact: true,
        layout: DefaultLayout,
        component:  Reservation
    },
    {
        path: "/reservations/:id",
        exact: true,
        layout: DefaultLayout,
        component:  ReservationDetail
    },
    {
        path: "/add-reservation",
        exact: true,
        layout: DefaultLayout,
        component:  CRUDReservation
    },
    {
        path: "/interventions",
        exact: true,
        layout: DefaultLayout,
        component:  Intervention
    },
    {
        path: "/interventions/:id",
        exact: true,
        layout: DefaultLayout,
        component:  InterventionDetail
    },
    {
        path: "/add-intervention",
        exact: true,
        layout: DefaultLayout,
        component:  CRUDIntervention
    },
    {
        path: "/calendrier",
        exact: true,
        layout: DefaultLayout,
        component: Calendar ,
    },
    {
        path: "/surveys/:id",
        exact: true,
        layout: DefaultLayout,
        component:  SurveyDetail
    },
    {
        path: "/annuaire",
        exact: true,
        layout: DefaultLayout,
        component:  Annuaire
    },
    {
        path: "/coowner/:id",
        exact: true,
        layout: DefaultLayout,
        component:  Coowner
    },
    {
        path: "/resident/:id",
        exact: true,
        layout: DefaultLayout,
        component:  Resident
    },
    {
        path: "/personnel/:id",
        exact: true,
        layout: DefaultLayout,
        component:  Personnel
    },
    {
        path: "/suivibudget",
        exact: true,
        layout: DefaultLayout,
        component:  Suivibudget
    },
    {
        path: "/evaluations",
        exact: true,
        layout: DefaultLayout,
        component:  Evaluations
    },
    {
        path: "/evaluation/:id",
        exact: true,
        layout: DefaultLayout,
        component:  Evaluation
    },
    {
        path: "/notifications",
        exact: true,
        layout: DefaultLayout,
        component:  Notification
    },
    {
        path: "/post-incident",
        exact: true,
        layout: DefaultLayout,
        component:  Main
    },
    {
        path: "/main-evaluation/:id",
        exact: true,
        layout: DefaultLayout,
        component:  MainEvaluation
    },
    {
        path: "/edit_password",
        exact: true,
        layout: DefaultLayout,
        component:  EditPassword
    },
    {
        path: "/settings",
        exact: true,
        layout: DefaultLayout,
        component:  Settings
    },
    {
        path: "/generalAssemblies",
        exact: true,
        layout: DefaultLayout,
        component:  GeneralAssemblies
    },
    {
        path: "/generalAssemblies/:id",
        exact: true,
        layout: DefaultLayout,
        component:  GeneralAssembly
    },
    {
        path: "/agenda/:ag_id",
        exact: true,
        layout: DefaultLayout,
        component:  Agenda
    },
    
];

 export default routes


