import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IoSearchOutline,
  IoChevronForwardOutline,
  IoWarningOutline,
} from "react-icons/io5";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import TypeIncident from "../../common/TypeIncident";
import Empty from "../../common/EmptyData";
import { searchFor } from "../../features/feeds/feedsSlice";

const Tabs = ({ title1, title2, incidentOuvert, incidentCloses }) => {
  const [openTab, setOpenTab] = useState(1);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    text: "",
  });
  let building_name = localStorage.getItem("building_name");

  useEffect(() => {
    dispatch(searchFor(state.text));
  }, [dispatch, state.text]);

  function handleChange(event) {
    let fieldName = event.target.name;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul className="flex list-none pt-3 pb-4 flex-row" role="tablist">
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase py-3 block leading-normal " +
                  (openTab === 1
                    ? "text-vert_2 border-b-2 border-black border-opacity-100"
                    : "text-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                {title1}
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase py-3 block leading-normal " +
                  (openTab === 2
                    ? "text-vert_2 border-b-2 border-black border-opacity-100"
                    : "text-blue")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                {title2}
              </a>
            </li>
          </ul>
          <div className="px-2">
            <IoSearchOutline
              size={24}
              className="absolute z-20 m-1 mt-2 text-blue-400 font-bold"
            />
            <input
              type="text"
              name="text"
              value={state.text}
              autoComplete="off"
              onChange={handleChange}
              className="pl-8 p-1 mb-6 bg-gray-200 w-full text-blod rounded-xl h-10 relative outline-none border-gray-200"
              placeholder="Rechercher"
            />
          </div>
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="px-4 py-2 flex-auto bg-white border">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="max-h-full">
                    {incidentOuvert.map((incidentOuv) => (
                      <Link
                        to={{
                          pathname: `/interventions/${incidentOuv?.id}`,
                          state: {
                            info: {
                              ...incidentOuv,
                            },
                          },
                        }}
                        key={incidentOuv?.id}
                        className="px-1 mb-1 flex flex-row sm:flex-row items-center justify-between"
                      >
                        {TypeIncident[incidentOuv?.catSupplier_id] ? (
                          <img
                            className="mx-auto h-11 w-11"
                            src={TypeIncident[incidentOuv?.catSupplier_id]}
                            alt=""
                          />
                        ) : (
                          <IoWarningOutline size={44} />
                        )}
                        <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400 w-0">
                          <div className="flex justify-between items-center">
                            <p className="text-sm font-black capitalize truncate">
                              {incidentOuv?.title
                                ?.slice(0, 35)
                                .toString()
                                .toLowerCase()}
                              {incidentOuv?.title?.length > 35 && "..."}
                            </p>
                            <p className="text-sm text-blue capitalize truncate border rounded p-1">
                              {incidentOuv?.type?.toLowerCase()}
                            </p>
                          </div>
                          <p className="text-sm font-medium text-gray-500 capitalize">
                            <span>Type d'intervention : </span>
                            <span>
                              {incidentOuv?.catSupplier_id
                                ?.toString()
                                .toLowerCase()}
                            </span>
                          </p>
                          <p className="text-sm font-medium text-blue truncate capitalize">
                            {building_name?.charAt(0)?.toUpperCase() +
                              building_name?.slice(1)?.toLowerCase()}
                          </p>
                          <p className="flex justify-center pr-4 -mt-8 items-end float-right">
                            <IoChevronForwardOutline />
                          </p>
                          <p className="flex justify-center items-end -mt-4 float-right text-gray-500 text-sm">
                            {incidentOuv.timeLineLabel}
                          </p>
                        </dd>
                      </Link>
                    ))}
                    {incidentOuvert.length === 0 && (
                      <Empty text="Aucun incident ouvert pour le moment." />
                    )}
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div>
                    {incidentCloses.map((incidentClose) => (
                      <Link
                        to={{
                          pathname: `/interventions/${incidentClose?.id}`,
                          state: {
                            info: {
                              ...incidentClose,
                            },
                          },
                        }}
                        key={incidentClose.id}
                        className="px-1 mb-1 flex flex-row sm:flex-row items-center justify-between"
                      >
                        {TypeIncident[incidentClose.catSupplier_id] ? (
                          <img
                            className="mx-auto h-11 w-11"
                            src={TypeIncident[incidentClose.catSupplier_id]}
                            alt=""
                          />
                        ) : (
                          <IoWarningOutline size={44} />
                        )}
                        <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400 w-0">
                          <div className="flex justify-between items-center">
                            <p className="text-sm font-black capitalize truncate">
                              {incidentClose?.title
                                ?.slice(0, 35)
                                .toString()
                                ?.toLowerCase()}
                              {incidentClose?.title.length > 35 && "..."}
                            </p>
                            <p className="text-sm text-blue capitalize truncate border rounded p-1">
                              {incidentClose?.type?.toLowerCase()}
                            </p>
                          </div>
                          <p className="text-sm font-medium text-gray-500">
                            <span>Type d'intervention : </span>
                            <span>{incidentClose.catSupplier_id}</span>
                          </p>
                          <p className="text-sm font-medium text-blue truncate">
                            {building_name?.charAt(0)?.toUpperCase() +
                              building_name?.slice(1)?.toLowerCase()}
                          </p>
                          <p className="flex justify-center pr-4 -mt-8 items-end float-right">
                            <IoChevronForwardOutline />
                          </p>
                          <p className="flex justify-center items-end -mt-4 float-right text-gray-500 text-sm">
                            {incidentClose.timeLineLabel}
                          </p>
                        </dd>
                      </Link>
                    ))}
                    {incidentCloses.length === 0 && (
                      <Empty text="Aucun incident fermé pour le moment." />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Tabs.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  incidentOuvert: PropTypes.array,
  incidentCloses: PropTypes.array,
};

Tabs.defaultProps = {
  incidentOuvert: [],
  incidentCloses: [],
};
export default Tabs;
