import users from "../features/users/usersSlice";
import feeds from "../features/feeds/feedsSlice";
import calendar from "../features/calendar/calendarSlice";
import reservations from "../features/reservations/reservationsSlice";
import interventions from "../features/interventions/interventionsSlice";
import generalassemblies from "../features/generalAssemblies/generalassembliesSlice"
import views from "../features/views/viewSlice"
import settings from "../features/settings/settingsSlice"

import { configureStore } from "@reduxjs/toolkit";

export default configureStore({
  reducer: {
    generalassemblies,
    interventions,
    reservations,
    calendar,
    feeds,
    users,
    views,
    settings
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
