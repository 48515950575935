import TypeIncident from "../../../common/TypeIncident";
import { GiElectric } from "react-icons/gi";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import Api from "./../../../api";
import { formatDate } from "../../../utils/utils";

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main p-4 rounded-lg">{children}</section>
    </div>
  );
};

export default function Incident({ info }) {
  const [state, setState] = useState({
    show: false,
    lineClamp: false,
    showMoreOrLess: "Voir Plus",
  });

  function toggleLineClamp() {
    setState((prevState) => ({
      ...prevState,
      lineClamp: !prevState.lineClamp,
      showMoreOrLess: prevState.lineClamp ? "Voir Plus" : "Voir Moins",
    }));
  }

  function toggleModal() {
    setState((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  }

  const fileEnpoint = `${Api?.fileEnpoint}/images/`;

  return (
    <div className="card-bg py-3 mt-3">
      {state.show && (
        <div id="modal" onClick={toggleModal}>
          <Modal show={state.show} handleClose={toggleModal}>
            {info?.views?.map((view, i) => (
              <div className="flex items-center m-2" key={i}>
                <div className="inline-block">
                  {view?.viewer?.avatar ? (
                    <img
                      className="rounded-full w-14 h-12 object-cover"
                      src={view?.viewer?.avatar}
                      alt=""
                    />
                  ) : (
                    <FiUser className="rounded-full w-11 h-11 bg-gray-200" />
                  )}
                </div>
                <span className="inline-block ml-1 text-sm">
                  {view?.viewer?.name}
                </span>
              </div>
            ))}
          </Modal>
        </div>
      )}
      {/* Cart header div */}
      <div className="px-4">
        {/* First part */}
        <Link to={`/incident/` + info?.id}>
          <div className="flex w-view">
            <div className="inline-block">
              {info?.catSupplier_id ? (
                <img
                  className="rounded-full w-10 h-10 object-cover"
                  src={TypeIncident[info?.catSupplier_id]}
                  alt=""
                />
              ) : (
                <GiElectric className="inline-block " size={35} />
              )}
            </div>
            <div className="flex-row inline-block w-full">
              <div className="flex justify-between">
                <div className="inline-block text-sm ml-1 font-bold">
                  <span
                    className={
                      info.title.length > 35
                        ? "line-clamp-1"
                        : "line-clamp-none"
                    }
                  >
                    {info.title}
                  </span>
                </div>
                <div
                  className="inline-block text-sm rounded-xl border-blue-700 border-2 ml-2"
                  style={{ borderColor: "#FD0D0D" }}
                >
                  {" "}
                  <span
                    className="px-2 text-blue-500 capitalize"
                    style={{ color: "#FD0D0D" }}
                  >
                    {info.type}
                  </span>{" "}
                </div>
              </div>
              <div className="inline-block text-xs ml-1 align-top text-gray-400">
                <span>
                  {`Posté le ${formatDate(info.created_at)}`} 
                </span>
              </div>
            </div>
          </div>
        </Link>
        {/* Second part */}
        <div className="mt-3">
          <div className="inline-block text-sm font-bold">
            <span
              className={
                info.title.length > 35 ? "line-clamp" : "line-clamp-none"
              }
            >
              {info.title}
            </span>
          </div>
          <p
            className={!state.lineClamp ? "line-clamp-2" : "line-clamp-none"}
            onClick={toggleLineClamp}
          >
            {info.description}
          </p>
          <span
            className="text-sm font-thin text-gray-400"
            onClick={toggleLineClamp}
            hidden={!(info.description.length >= 200)}
          >
            {state.showMoreOrLess}
          </span>
        </div>
        <div className="flex justify-around mt-3">
          {/* Views */}
          <div className="inline-block">
            <div className="flex-row">
              <span className="inline-block">
                Partie |{" "}
                {info.partiecoms.map((item, i) => (
                  <span className="font-bold" key={i}>
                    {item.name
                      .toLowerCase()
                      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
                  </span>
                ))}
              </span>
            </div>
          </div>
          {/* Comments */}
          <div className="inline-block">
            <div className="flex-row">
              Déclaré par{" "}
              <span className="inline-block font-bold">
                {info?.declared_by?.name}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Link to={`/incident/` + info?.id}>
        {/* Cart content div */}
        {info?.images?.length > 0 &&
          info?.images.map((item, i) => (
            <div className="w-full h-2/4 mt-2 " key={i}>
              {/* Ici on aura le caroussel */}
              <img src={`${fileEnpoint}/${item.image}`} alt="" className="w-full object-cover"/>
            </div>
          ))}
      </Link>
    </div>
  );
}
