import React from "react";
import PageTitle from "../../common/PageTitle";
import Tabs from "./Tabs";

class Annuaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coowners: [],
      personnel: [],
      residents: [],
    };
  }

  componentDidMount = () => {
    let annuaire = localStorage.getItem("annuaire");
    annuaire = JSON.parse(annuaire);
    this.setState({
      coowners: annuaire?.coowners,
      personnel: annuaire?.contacts,
      residents: annuaire?.residents,
    });
  };

  render() {
    const { coowners, personnel, residents } = this.state;
    return (
      <div className="container mx-auto mb-10">
        <PageTitle title="Annuaire" />
        <main className="py-5">
          <Tabs
            title1="COPROPRIÉTAIRE"
            title2="PERSONNEL"
            title3="RÉSIDENTS"
            coowners={coowners}
            personnel={personnel}
            residents={residents}
          />
        </main>
      </div>
    );
  }
}

export default Annuaire;
