import PropTypes from "prop-types";
import EmptyData from "../../common/EmptyData";

import CustomTocomeup from "./CustomTocomeup";


const Tocomeup = ({openTab, paymentTocomeup}) => {
    return (
        <div className={openTab === 4 ? "block" : "hidden"} id="link4">
            {paymentTocomeup.length > 0 ?
                
                <CustomTocomeup paymentTocomeup={paymentTocomeup}/>
            :
            <EmptyData text="Aucune donnée pour le moment" />
            }
         
        </div>
    );
}

Tocomeup.propTypes = {
    /**
     * The page title.
     */

     paymentTocomeup: PropTypes.array,
   
  };

  Tocomeup.defaultProps = {

    paymentTocomeup: []
  }
export default Tocomeup