import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronBackOutline } from "react-icons/io5";
import { GoPrimitiveDot } from "react-icons/go";
import Fab from "../../components/floating-action-button/intervention/fab";
import { fetchIntervention } from "../../features/interventions/interventionsSlice";
import Api from "../../api";
const building_name = localStorage.getItem("building_name");

export default function Intervention(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openTab, setOpenTab] = React.useState(1);
  const [state] = useState({
    info: props.location.state.info,
  });
  useEffect(() => {
    dispatch(fetchIntervention(state.info.id));
  }, [dispatch, state.info]);

  const timeline = [
    ...useSelector((state) => state.interventions?.intervention),
  ].sort((x, y) => new Date(y.created_at) - new Date(x.created_at));

  const fileEnpoint = `${Api?.fileEnpoint}/images/`;

  return (
    <div className="flex w-full pt-16 py-3 mb-14">
      <div className="w-full mb-4">
        <div className="font-bold">
          <div className="flex flex-row bg-white px-3 items-center py-2">
            <IoChevronBackOutline
              size={25}
              onClick={() => history.goBack()}
              color="#14448B"
            />
            <span className="text-xl font-bold px-2 capitalize">
              Intervention {state.info.title.toLowerCase()}
            </span>
          </div>
          <div className="h-1"></div>
          <div className="card-bg">
            <ul className="flex list-none flex-row" role="tablist">
              <li className="text-left">
                <a
                  className={
                    "text-xs font-bold capitalize px-5 py-2 block leading-normal " +
                    (openTab === 1
                      ? "text-vert_2 border-b-2 border-black border-opacity-100"
                      : "text-vert_2")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Détail
                </a>
              </li>
              <li className="text-left">
                <a
                  className={
                    "text-xs font-bold capitalize px-5 py-2 block leading-normal " +
                    (openTab === 2
                      ? "text-blue border-b-2 border-black border-opacity-100"
                      : "text-blue")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Suivi
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
          <div className="flex flex-col my-4 px-4">
            <div className="my-2">
              <span>Titre : </span>
              <span className="text-blue capitalize">
                {state.info?.title?.toLowerCase()}
              </span>
            </div>
            <div className="my-2">
              <span>Type d'intervention : </span>
              <span className="text-blue capitalize">
                {state.info?.catSupplier_id?.toLowerCase()}
              </span>
            </div>
            <div className="my-2">
              <span>Immeuble : </span>
              <span className="text-blue capitalize">
                {building_name?.charAt(0).toUpperCase() +
                  building_name?.slice(1).toLowerCase()}
              </span>
            </div>
            <div className="my-2">
              <span>Date de déclaration : </span>
              <span className="text-blue">{state.info?.timeLineLabel}</span>
            </div>
            <div className="my-2">
              <span>Status : </span>
              <span
                className="px-2 text-sm border-2 rounded capitalize"
                style={
                  state.info.status === "OPEND"
                    ? {
                        backgroundColor: "#FF8800",
                        border: "2px solid #FF8800",
                        color: "#fff",
                      }
                    : state.info.status === "RFUSE"
                    ? {
                        backgroundColor: "#FF0000",
                        border: "2px solid #FF0000",
                        color: "#fff",
                      }
                    : state.info.status === "APPRV"
                    ? {
                        backgroundColor: "#009E6B",
                        border: "2px solid #009E6B",
                        color: "#fff",
                      }
                    : {
                        backgroundColor: "#aaa",
                        border: "2px solid #aaa",
                        color: "#fff",
                      }
                }
              >
                {
                  {
                    OPEND: "Ouvert",
                    RFUSE: "Rejetée",
                    APPRV: "Validée",
                    CANCL: "Annulée",
                    CLOSE: "Fermée",
                  }[state.info?.status]
                }
              </span>
            </div>
            {state.info?.description && (
              <div className="mt-3">
                <h4>Commentaire</h4>
                <div className="flex content-start mt-1">
                  <textarea
                    type="text"
                    name="description"
                    value={
                      state.info?.description?.charAt(0).toUpperCase() +
                      state.info?.description?.slice(1).toLowerCase()
                    }
                    readOnly
                    placeholder="Message"
                    className="rounded border-gray-300 w-full h-32"
                    style={{ backgroundColor: "#F5F5F5" }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={openTab === 2 ? "flex" : "hidden"} id="link2">
          <ul className="my-5 mx-5 flex flex-col">
            {timeline.map((item, i) =>
              item?.title === "Mise à jour" ? (
                <li className="flex flex-row py-3" key={i}>
                  <GoPrimitiveDot size={25} />
                  <div className="flex flex-col px-2 flex-wrap">
                    <div>
                      <span className="font-bold text-xl">Mise à jour</span>
                      <br />
                      <span className="font-thin">Du : </span>
                      <span className="font-bold">
                        {new Date(item?.created_at).toLocaleDateString()}
                      </span>
                      <span className="font-thin"> à : </span>
                      <span className="font-bold">
                        {`${item?.start_time?.split(":")[0]}h ${
                          item?.start_time?.split(":")[1]
                        }`}{" "}
                      </span>{" "}
                      <br />
                      <p>{item?.description}</p>
                    </div>
                    {item?.images?.length > 0 &&
                      item?.images.map((item, i) => (
                        <img
                          alt=""
                          key={i}
                          src={`${fileEnpoint}/${item.image}`}
                          className="w-full max-w-md object-cover my-3"
                        />
                      ))}
                  </div>
                </li>
              ) : (
                <li className="flex flex-row py-3" key={i}>
                  <GoPrimitiveDot size={25} />
                  <div className="flex flex-col px-2">
                    <div>
                      <span className="font-bold text-xl capitalize">
                        Intervention
                      </span>{" "}
                      <br />
                      <span className="font-thin">Date : </span>
                      <span className="font-bold">
                        {new Date(item?.created_at).toLocaleDateString()}
                      </span>{" "}
                      <br />
                      <span className="font-thin">Heure de debut : </span>
                      <span className="font-bold">
                        {`${item?.start_time?.split(":")[0]}h ${
                          item?.start_time?.split(":")[1]
                        }`}
                      </span>
                      <br />
                      <span className="font-thin"> Heure de fin : </span>
                      <span className="font-bold">{`${
                        item?.end_time?.split(":")[0]
                      }h ${item?.end_time?.split(":")[1]}`}</span>
                      <br />
                      <p>{item?.comment}</p>
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
        <div className={openTab === 2 ? "block" : "hidden"} id="link2">
          <Fab info={state.info} />
        </div>
        <div className="mb-4 ml-5"></div>
      </div>
    </div>
  );
}
