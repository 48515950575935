import React from 'react';
import { Link } from 'react-router-dom';

import PageTitle from '../../../common/PageTitle';
import FormGroup from '../../form/FormGroup';


class Step1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            location: 1 ,
    
        }

    }

   
    saveAndContinue = (e) => {
        e.preventDefault()
        if (this.props.validateForm()) {
           this.props.nextStep()
        }
    }

  

    render() {

        const { values, fields_empty } = this.props
        const finalClass = "focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300"
       
        const hasError = fields_empty?.empty_title 
        const finalClassInput = `w-full border border-gray-300 rounded-lg mt-3 px-4 py-3 focus:ring-indigo-500 focus:border-indigo-500 outline-none transition-colors duration-150 ease-in-out focus:border-blue-400 ${
          hasError && `border-red-600`
        }`

        return(
            <div>
                <PageTitle title="Déclarer un incident" />
                <main className="w-full bg-white border-gray-200 rounded-lg px-2 py-6 pb-10 mt-4 mb-10">
                    <form className="mb-10">
                        <FormGroup>
                            <label className={`text-base text-gray-600 uppercase py-4 ${hasError && "text-red-600"}`}> Titre de l'incident</label>
                            <input
                                className={finalClassInput}
                                type="text"
                                onChange={this.props.handleChange('title')}
                                placeholder="Titre de l'incident"
                                defaultValue={values.title}
                            />
                             {fields_empty?.empty_title  && (
                            <div className="bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm">
                                Ce champ ne peut être vide
                            </div>
                            )}
                        </FormGroup>
                       
                        <p className="text-base text-gray-600 py-4 uppercase">Niveau d'urgence</p>
                        <div className="flex items-center mb-4">
                            <input 
                                className={finalClass} 
                                id="normal" 
                                name="emergency_level" 
                                type="radio" 
                                onChange={this.props.handleChange('emergency_level')}
                                defaultValue={1}
                                defaultChecked={true}
                            />
                            <label htmlFor="normal" className="ml-3 block text-base font-medium text-gray-700">
                                Normal
                            </label>
                            <input 
                                id="eleve" 
                                name="emergency_level" 
                                type="radio" 
                                onChange={this.props.handleChange('emergency_level')}
                                defaultValue={2}
                                className={`${finalClass} absolute right-36`}
                            />
                            <label htmlFor="eleve" className="ml-3 block text-base font-medium text-gray-700 absolute right-24">
                                Elevé
                            </label>
                        </div>
                        
                       
                    </form>
                </main>
                <div className="items-baseline text-xl font-extrabold mb-28">
                    <Link to="/incidents" className="text-xl font-extrabold text-blue uppercase px-2">Précedent</Link>
                    <button  onClick={ e => this.saveAndContinue(e) } className="text-xl font-extrabold text-blue uppercase float-right">suivant</button>
                </div>
                <br/>
            </div>
        );
    }
}


export default Step1;