import { createSlice } from "@reduxjs/toolkit";
import  { api } from "./../../api";
const building_id = localStorage.getItem("building_id");
const global_id = JSON.parse(localStorage.getItem("user"))?.global_id;

const feedsSlice = createSlice({
  name: "feeds",
  initialState: {
    feeds: [],
    openIncidents: [],
    filteredOpenIncidents: [],
    filteredClosedIncidents: [],
    closedIncidents: [],
    incidentTimeline: [],
    type: null,
    filterKey: "all",
    filteredFeeds: [],
    newComment: null,
    newParticipant: [],
    newView: null,
    isWaiting: false,
    error: false,
  },
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    hasError: (state, action) => {
      state.error = true;
      state.isWaiting = false;
    },
    getFeedsSuccess: (state, action) => {
      state.feeds = action.payload;
      state.filteredFeeds = action.payload.sort(
        (x, y) => new Date(y.created_at) - new Date(x.created_at)
      );
      state.filterKey = "all";
      state.isWaiting = false;
    },
    setFilterKey: (state, action) => {
      if (state.filterKey !== action.payload) {
        state.filterKey = action.payload;
      } else {
        state.filterKey = "all";
      }
      if (state.filterKey === "all") {
        state.filteredFeeds = state.feeds.sort(
          (x, y) => new Date(y.created_at) - new Date(x.created_at)
        );
      } else {
        state.filteredFeeds = state.feeds
          .filter((value) => {
            return state.filterKey === value.type ? value : null;
          })
          .sort((x, y) => new Date(y.created_at) - new Date(x.created_at));
      }
    },
    getFeedSuccess: (state) => {},

    getOpenIncidentsSuccess: (state, action) => {
      state.filteredOpenIncidents = action.payload;
      state.openIncidents = action.payload;
      state.isWaiting = false;
    },

    getClosedIncidentsSuccess: (state, action) => {
      state.filteredClosedIncidents = action.payload;
      state.closedIncidents = action.payload;
      state.isWaiting = false;
    },

    getIncidentTimelineSuccess: (state, action) => {
      
      state.incidentTimeline = action.payload.data.sort(
        (x, y) => new Date(y.created_at) - new Date(x.created_at)
      );
      state.type = action.payload.optional
      state.isWaiting = false;
    },

    searchFor: (state, action) => {
      state.filteredOpenIncidents = state.openIncidents.filter((item) => {
        let title = item?.title.toLowerCase().replace(/ /g, "");
        let text = action.payload.toLowerCase().replace(/ /g, "");

        return title.normalize().includes(text.normalize()) ? item : null;
      });
      state.filteredClosedIncidents = state.closedIncidents.filter((item) => {
        let title = item?.title.toLowerCase().replace(/ /g, "");
        let text = action.payload.toLowerCase().replace(/ /g, "");

        return title.normalize().includes(text.normalize()) ? item : null;
      });
    },

    createFeedSuccess: (state) => {
      state.isWaiting = false;
      state.filterKey = "all";
    },
    updateFeedSuccess: (state) => {
      state.isWaiting = false;
      state.filterKey = "all";
    },
    deleteFeedSuccess: (state) => {
      state.isWaiting = false;
      state.filterKey = "all";
    },
    incrementViewSuccess: (state, action) => {
      state.newView = action.payload;
    },
    addParticipantSuccess: (state, action) => {
      if (action.payload.status === "yes") {
        state.newParticipant = [action.payload];
      }
    },
    addCommentSuccess: (state, action) => {
      state.newComment = action.payload;
    },
  },
});

export const {
  searchFor,
  getFeedsSuccess,
  startWaiting,
  hasError,
  setFilterKey,
  filterFeeds,
  getFeedSuccess,
  getOpenIncidentsSuccess,
  getClosedIncidentsSuccess,
  createFeedSuccess,
  updateFeedSuccess,
  deleteFeedSuccess,
  incrementViewSuccess,
  addCommentSuccess,
  addParticipantSuccess,
  getIncidentTimelineSuccess
} = feedsSlice.actions;

export const fetchFeeds = () => async (dispatch) => {
  dispatch(startWaiting());
  let otherFeeds = [];
  let evaluations = [];
  let incidents = [];

  /** Fetching incidents */
  try {
    await api
      .get(`/incident/openIncidents/${building_id}/${global_id}`)
      .then((response) => {
        incidents = response.data.data
      });
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }

  /** Fetching incidents */
  try {
    await api
    .get(`/evaluations/${building_id}/${global_id}`)
    .then((response) => {
      evaluations = response.data.data.evaluationsOuvert;
    });
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }

  incidents = incidents.map((v, i) => {
    if (Object.keys(v).includes("type")) {
      Object.defineProperty(v, "type", {
        value: "incident",
        writeable: true,
        enumerable: true,
      });
    } else if (Object.keys(v).includes("type")) {
      Object.defineProperty(v, "type", {
        value: "travaux",
        writeable: true,
        enumerable: true,
      });
    }

    return v;
  });

  evaluations = evaluations.map((v, i) => {
    let newType = v.type;
    Object.defineProperty(v, "type", {
      value: "evaluation",
      writeable: true,
      enumerable: true,
    });
    Object.defineProperty(v, "newType", {
      value: newType,
      writeable: true,
      enumerable: true,
    });
    return v;
  });

  try {
    await api.get(`/feedsByBuilding/${building_id}`).then((response) => {
      otherFeeds = response.data;
    });
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }

  dispatch(getFeedsSuccess([...incidents, ...evaluations, ...otherFeeds]));
};

export const fetchOpenIncidents = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .get(`/incident/openIncidents/${building_id}/${global_id}`)
      .then((response) =>
        dispatch(getOpenIncidentsSuccess(response.data.data))
      );
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const fetchClosedIncidents = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .get(`/incident/closedIncidents/${building_id}/${global_id}`)
      .then((response) =>
        dispatch(getClosedIncidentsSuccess(response.data.data))
      );
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const fetchIncidentTimeline = (id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .get(`/incident/show/${id}`)
      .then((response) =>
        dispatch(getIncidentTimelineSuccess(response.data))
      );
  } catch (error) {
    dispatch(hasError(error.message));
    console.log(error)
    return console.error(error);
  }
};

export const fetchFeed = (id) => async (dispatch) => {
  // dispatch(startWaiting());
  try {
    await api
      .get("/feeds/" + id) // To change may be
      .then((response) => dispatch(getFeedSuccess(response.data)));
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const addFeed = (feed) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.post("/feeds", feed).then(() => {
      dispatch(createFeedSuccess());
      dispatch(fetchFeeds());
    });
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const updateFeed = (feed, id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.post("/feeds/" + id, feed).then((response) => {
      dispatch(updateFeedSuccess(response.data));
      dispatch(fetchFeeds());
    });
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const deleteFeed = (id) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api.delete("/feeds/" + id).then((response) => {
      dispatch(deleteFeedSuccess(response.data));
      dispatch(fetchFeeds());
    });
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const incrementView =
  (user_id, feed_id, feed_type = "") =>
  async (dispatch) => {
    dispatch(startWaiting());
    try {
      await api
        .post("/views", {
          user_id: parseInt(user_id),
          feed_id: feed_id,
          type: feed_type,
        })
        .then((response) => {
          dispatch(incrementViewSuccess(response.data[0]));
          dispatch(fetchFeeds());
        });
    } catch (error) {
      dispatch(hasError(error.message));
      return console.error(error);
    }
  };

export const addParticipant =
  (user_id, feed_id, status) => async (dispatch) => {
    dispatch(startWaiting());
    try {
      await api
        .post("/participants", {
          user_id: parseInt(user_id),
          feed_id: parseInt(feed_id),
          status: status,
        })
        .then((response) => {
          dispatch(addParticipantSuccess(response.data[0]));
        });
    } catch (error) {
      dispatch(hasError(error.message));
      return console.error(error);
    }
  };

export const addComment = (comment) => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .post("/comments", {
        ...comment,
      })
      .then((response) => {
        dispatch(addCommentSuccess(response.data[0]));
      });
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export default feedsSlice.reducer;
