import React, {Component} from 'react'
import PageTitle from '../../common/PageTitle';
import Spinner from '../../common/Spinner';
import Tabs from '../../components/generalAssembly/Tabs';
import {fetchGeneralassemblies} from '../../features/generalAssemblies/generalassembliesSlice';
import {connect} from 'react-redux';

class GeneralAssemblies extends Component {
  
  
      componentDidMount = () => {

        const { dispatch} = this.props;
        dispatch(fetchGeneralassemblies());

      };
    
      render() {

        return (
          <div className="container">
            <PageTitle title="Assemblées générales" />
            {this.props.isWaiting ? (
              <Spinner />
            ) : (
              <main className="py-5 px-0 mt-6 w-full rounded-lg  mb-32">
                <Tabs
                  generalAssemblies={this.props.generalAssemblies}
                />
              </main>
            )}
          </div>
        );
      }
}

const mapStateToProps = (state) => ({
  generalAssemblies: state.generalassemblies.generalassembliesAll,
  isWaiting: state.generalassemblies.isWaiting,
})

export default  connect(mapStateToProps, null)(GeneralAssemblies);