import { BrowserRouter as Router, Route } from "react-router-dom";
import React, { useEffect } from "react";
import MobileDetect from "mobile-detect";
import routes from "./routes";
import Login from "./auth/Login";
import { useDispatch } from "react-redux";
import ResetPassword from "./user/ResetPassword";
import ForgotPassword from "./user/ForgotPassword";
import firebase from "./components/firebase/firebase";
import { saveToken } from "./features/users/usersSlice";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let md = new MobileDetect(window.navigator.userAgent);
    if (!md.is("iPhone")) {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          const global_id = JSON.parse(localStorage.getItem("user"))?.global_id;
          dispatch(saveToken({ token, global_id }));
        })
        .catch((error) => {
          console.log("Error");
          console.error(error);
        });
    } else {
    }
  }, [dispatch]);

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <Route path="/" exact={true} component={Login} />
      <Route path="/forgot_password" exact={true} component={ForgotPassword} />
      <Route
        path="/reset-password/:token"
        exact={true}
        component={ResetPassword}
      />
      <div>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              }}
            />
          );
        })}
      </div>
    </Router>
  );
};

export default App;
