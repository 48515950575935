import React from "react";
import PageTitle from "../../common/PageTitle";
import { IoAddOutline } from "react-icons/io5";
import Spinner from "../../common/Spinner";
import { Link } from "react-router-dom";
import Tabs from "./Tabs";
import { connect } from "react-redux";
import {
  fetchReservations,
} from "../../features/reservations/reservationsSlice";

class Reservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newReservations: [],
      oldReservations: [],
      loading: false,
    };
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    await dispatch(fetchReservations());
  };

  render() {
    return (
      <div className="container mx-auto">
        <PageTitle title="Réservations" />
        {this.props.isWaiting && <Spinner />}
        {!this.props.isWaiting && (
          <main className="py-5 mb-20">
            <Tabs
              tabOne="NOUVELLES RESERVATIONS"
              tabTwo="ANCIENNES RESERVATIONS"
              newReservations={this.props.reservations
                .filter(
                  (item) =>
                    new Date(item.end_date).getTime() > new Date().setDate(new Date().getDate() - 1)
                )
                ?.sort(
                  (x, y) => new Date(x.start_date) - new Date(y.start_date)
                )}
              oldReservations={this.props.reservations
                .filter((item) => new Date(item.end_date).getTime() < new Date().getTime())
                ?.sort(
                  (x, y) => new Date(y.start_date) - new Date(x.start_date)
                )}
            />
          </main>
        )}
        <Link to="/add-reservation"
          className="bg-blue rounded-full text-white h-16 w-16 text-3xl fixed z-20 bottom-16 right-1.5 flex justify-center items-center opacity-80"
        >
          <span>
            <IoAddOutline className="inline-flex" />
          </span>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reservations: state.reservations.filteredReservations,
  isWaiting: state.feeds.isWaiting,
});

export default connect(mapStateToProps, null)(Reservation);
