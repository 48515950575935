import PropTypes from "prop-types";
import Badge from "../../common/badgets/Badget";
import FormatDate from "../../common/FormatDate";
import Api from "./../../api";


const EvalIncident = ({evaluation}) => {


    return (
         <>
            <div className=" px-4 py-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dd className="mt-1 text-base font-semibold text-gray-900 sm:mt-0 sm:col-span-2">
                    {evaluation.title}
                </dd>
            </div> 
            <div className=" px-4 py-4  flex">
                <dt className="text-sm font-medium text-gray-500">
                 Statut:
                </dt>
                <dd className="text-sm text-gray-900 ml-6 ">
                {evaluation.statut === "Publié" ? 
                <Badge text="Ouvert" type="success" />
                :
                <Badge text={evaluation.statut} type="danger" />
                }
                </dd>
            </div>   
            <div className=" px-4 py-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                    À qui ?:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {evaluation.evaluable_id}
                </dd>
            </div>  
            <div className=" px-4 py-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500"> Lieu de l'incident:</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {evaluation.incident?.appartments?.length > 0 ?
                    evaluation.incident?.appartments?.map((appartment) => 
                    <span className="capitalize" key={appartment.id}>{appartment.title},  &nbsp; &nbsp; &nbsp;</span>
                    ) :
                    evaluation.incident?.partiecoms?.map((partiecom) => 
                    <span className="capitalize" key={partiecom.id}>{partiecom.name}, &nbsp; &nbsp; &nbsp;</span>
                    )
                }
                </dd>
            </div> 
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                   {evaluation.incident?.description}
                </dd>
            </div>
            {evaluation?.incident?.images[0]?.image !== undefined &&
            <div className="px-4 py-4 ">
             <img
                className="w-full h-30"
                src={`${Api?.fileEnpoint}/images/${evaluation?.incident?.images[0]?.image}`}
                alt="tet"
                />
            </div>
            } 
            
            <div className="px-4 py-4  flex">
                <dt className="text-sm font-medium text-gray-500">
                    Date de declaration:
                </dt>
                <dd className="text-sm text-gray-900 absolute right-6 px-4">
                {FormatDate(evaluation.date_envoi)}
                </dd>
            </div>  
            <div className=" px-4 py-4 flex">
                <dt className="text-sm font-medium text-gray-500">
                    Date de clôture:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 absolute right-6 px-4">
                {FormatDate(evaluation.closed_at)}
                </dd>
            </div>  
            
        </> 
    );
}


EvalIncident.propTypes = {
    /**
     * The object .
     */
    evaluation: PropTypes.object,
    incident: PropTypes.object,
   
};

EvalIncident.defaultProps = {

    evaluation: {},
    incident: {},
}

export default EvalIncident