
import React from "react";
import './spinner.css'
const Spinner = () => {

  return (
       /*  <div className="absolute left-28 bottom-80">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24"></div>
        </div> */
        <>
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </>
        )
};


export default Spinner;
