import React from "react"

function Button({
  className = "",
  text = "button",
  size = "base",
  link,
  type = "primary",
  icon = null,
  iconPosition = "left",
  submit = false,
  full = false,
  ...newProps
}) {
  let finalClass = `${className} inline-block rounded-sm font-medium border border-solid cursor-pointer text-center transition-colors duration-200`
  if (size === "xs") finalClass += " text-xs py-1 px-2"
  else if (size === "sm") finalClass += " text-sm py-2 px-4"
  else if (size === "base") finalClass += " text-base py-3 px-6"
  else if (size === "lg") finalClass += " text-lg py-3 px-8"
  else if (size === "xl") finalClass += " text-xl py-3 px-12"
  if (submit)
    finalClass +=
      " text-white rounded-lg border-blue-400 hover:bg-blue-600 hover:border-blue-600"

  if (full) finalClass += "round w-full"
  let content = text
  if (icon) {
    if (iconPosition === "left")
      content = (
        <>
          {React.cloneElement(icon, { className: "mr-2" })}
          {text}
        </>
      )
    else if (iconPosition === "right")
      content = (
        <>
          {text}
          {React.cloneElement(icon, { className: "ml-2" })}
        </>
      )
  }
  let ButtonTag = link ? "a" : "div"
  
  if (submit) ButtonTag = "button"
  return (
    <ButtonTag
      href={link}
      className={finalClass}
      type={submit ? "submit" : ""}
      {...newProps}
    >
      {content}
    </ButtonTag>
  )
}

export default Button