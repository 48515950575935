
import CustomUnpai from "./CustomUnpai";
import PropTypes from "prop-types";


const Unpai = ({openTab, unpaiPerPeriodicity}) => {

   
    return (
        <div className={openTab === 3 ? "block" : "hidden"} id="link3">
            {unpaiPerPeriodicity.map((paymentunpai) =>

              <CustomUnpai 
                    key={paymentunpai.id} 
                    periodicity={paymentunpai.payment_periodicity} 
                    payment_date={paymentunpai.payment_deadline} 
                    apparts_payments={paymentunpai.apparts_payments}

                />
            )}
         
        </div>
    );
}

Unpai.propTypes = {

     unpaiPerPeriodicity: PropTypes.array,
   
};

  Unpai.defaultProps = {

    unpaiPerPeriodicity: []
  }

  export default Unpai;