import React from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import Badge from "../../common/badgets/Badget";
import FormatDate from "../../common/FormatDate";
import PageTitle from "../../common/PageTitle";
import Spinner from "../../common/Spinner";
import Api from "./../../api";
import { connect } from "react-redux";
import { fetchIncidentTimeline, incrementView } from "../../features/feeds/feedsSlice";

class IncidentDetails extends React.Component {
 
  componentDidMount = async () => {
    let id = this.props.match.params.id;
     await this.props.onFetchIncidentTimeline(id);
  };




  render() {
    const { detailIncident, isWaiting, type} = this.props;
    const fileEnpoint = `${Api?.fileEnpoint}/images/`;

    return (
      <>
        <PageTitle 
          title={type === "INCIDENT" ? "Détails incident": "Détails travaux"}
        />
        {isWaiting ? (
          <Spinner />
        ) : (
          <div className="h-screen relative m-2 mt-8 mb-28">
            <div
              className="border-r-2 border-gray-300 border-dotted absolute h-full top-0 z-0"
              style={{ left: 7 }}
            ></div>
            <ul className="list-none m-0 p-0 ">
              {detailIncident.map((incident, index) => (
                <li className="mb-2" key={index}>
                  <div className="flex items-center mb-10">
                    {incident.timeLineLabel !==
                      detailIncident[index - 1]?.timeLineLabel && (
                      <>
                        <div className="bg-black rounded-full h-4 w-4 z-10"></div>
                        <div className="flex-1 ml-4 font-extrabold">
                          {incident.timeLineLabel}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex-1 items-center mb-1">
                    <div className=" rounded-full h-4 w-4 border-indigo-600 border-2 z-10">
                      {" "}
                    </div>
                    <div className=" ml-8 font-bold -mt-4">
                      {incident.timeLine_title}
                      <span className="absolute right-2 font-normal pb-2 italic text-gray-500 text-sm">
                        {incident.time}
                      </span>
                    </div>
                  </div>
                  <div className=" px-4 ml-4 flex">
                    <dd className="mt-1 text-base text-gray-500 sm:mt-0 sm:col-span-2">
                      <span className="font-bold">
                        {incident.declared_by?.declared_by}
                      </span>
                      {incident.declared_by?.declaring_type && (
                        <span>({incident.declared_by?.declaring_type})</span>
                      )}
                    </dd>
                  </div>
                  {incident?.description === undefined &&(
                    <div className="ml-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dd className="mt-1 text-base text-gray-500 sm:mt-0 sm:col-span-2">
                        <span className="font-bold">
                          {incident.supplier_id}
                        </span>
                        <span> {incident?.intervenor_type}</span>
                      </dd>
                    </div>
                  )}
                  {incident.title !== undefined && (
                    <div className="px-4 py-2 ml-4 flex">
                      <dd className="text-lg text-gray-700 capitalize">
                        {incident.title?.toLowerCase()}
                      </dd>
                    </div>
                  )}
                  {incident.status !== undefined && (
                    <div className="ml-4 px-4 py-4  flex">
                      <dt className="text-sm font-medium text-gray-700">
                        {" "}
                        Statut:{" "}
                      </dt>
                      <dd className="text-sm text-gray-900 ml-6 ">
                        {incident.status === "OPEND" ? (
                          <Badge text="ouvert" type="success" />
                        ) : incident.status === "RESOL" ? (
                          <Badge text="résolu" type="success" />
                        ) : incident.status === "UNRES" ? (
                          <Badge text="non résolu" type="warning" />
                        ) : (
                          <Badge text="fermé" type="danger" />
                        )}
                      </dd>
                    </div>
                  )}
                  {incident?.description !== undefined && (
                    <div className="ml-4 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                        {incident.description?.charAt(0).toUpperCase() +
                          incident.description?.slice(1).toLowerCase()}
                      </dd>
                    </div>
                  )}
                  {incident?.images?.length > 0 && (
                    <div className="w-full h-2/4 mt-2 ml-2">
                      <AliceCarousel
                        autoPlay
                        autoPlayInterval="3000"
                        disableButtonsControls={true}
                      >
                        {incident.images.map((item) => (
                          <img
                            key={item.id}
                            className="w-full max-w-md object-cover"
                            src={`${fileEnpoint}/${item.image}`}
                            alt={"l'incident"}
                          />
                        ))}
                      </AliceCarousel>
                    </div>
                  )}
                  {incident.title !== undefined && (
                    <div className=" px-4 py-4 ml-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <hr className="py-2 text-gray-300" />
                      <dt className="text-base font-bold text-gray-900 uppercase">
                        Lieu(x) {type === "INCIDENT" ? "de l'incident": "des travaux"}
                      </dt>
                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                        {incident?.appartments?.length > 0 && (
                          <>
                            <div className="text-base text-black font-medium py-6 ">
                              Appartment(s):
                            </div>
                            {incident.appartments.map((appartment) => (
                              <span
                                className="capitalize text-gray-700"
                                key={appartment.id}
                              >
                                {" "}
                                {appartment.title}, &nbsp; &nbsp; &nbsp;
                              </span>
                            ))}
                          </>
                        )}
                      </dd>
                      <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2">
                        {incident?.partiecoms?.length > 0 && (
                          <>
                            <div className="text-base text-black font-medium py-6 ">
                              Partie commune(s):
                            </div>
                            {incident.partiecoms.map((partiecom) => (
                              <span
                                className="capitalize text-gray-700"
                                key={partiecom.id}
                              >
                                {" "}
                                {partiecom.name}, &nbsp; &nbsp; &nbsp;
                              </span>
                            ))}
                          </>
                        )}
                      </dd>
                    </div>
                  )}
                  {incident.status === "CLOSE" &&
                    incident?.closed_at !== undefined && (
                      <div className="ml-4 px-4 py-4 flex">
                        <dt className="text-sm font-medium text-gray-500">
                          Date de clôture:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 absolute right-6 px-4">
                          {FormatDate(incident.closed_at)}
                        </dd>
                      </div>
                    )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  detailIncident: state.feeds.incidentTimeline,
  type: state.feeds.type,
  isWaiting: state.feeds.isWaiting,
  error: state.feeds.error,
})
const mapDispatchToProps = (dispatch) => {
  return {
    onIncrementView: (user_id, feed_id, feed_type) => {
      dispatch(incrementView(user_id, feed_id, feed_type));
    },
    onFetchIncidentTimeline: (id) =>{
      dispatch(fetchIncidentTimeline(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetails);
