import React from 'react';

import PageTitle from '../../../common/PageTitle';
import Spinner from '../../../common/Spinner';


// import '../style.css'
class Step4 extends React.Component {

   constructor(props) {
       super(props)
       this.state = {
           showForm: true
       }
   }

    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }


    handleSubmit = (e) => {
        e.preventDefault()
        this.props.doSubmit()
        this.setState({showForm: false})
    }

    render(){
       

        return(
            <>
                <PageTitle title="Déclarer un incident" />
               
                {this.state.showForm?
                <div>
                <main className="w-full bg-white border-gray-200 rounded-lg px-2 py-6 pb-1 mt-4 mb-10">
                    <form className="mb-10">
                        <label className="block text-base text-gray-600">
                           <span className="font-bold text-black"> Ajouter des photos </span> ( facultatif )
                        </label>
                        <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative h-40 ">
                            <div className="space-y-1 text-center">
                                
                                <input 
                                className="cursor-pointer relative block opacity-0 w-full p-20 z-50" 
                                type="file" 
                                name="photos"
                                onChange={this.props.handleChangeFile('photos')}
                                multiple 
                                accept="image/*"
                                />
                                <svg className="mx-auto h-12 w-12 text-gray-400 absolute top-2 left-32" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div className="text-center p-10 absolute top-8 right-0 left-0 m-auto font-medium text-gray-600">
                                    <h4>
                                    Cliquer pour prendre une photo
                                        <br/>ou
                                    </h4>
                                    <p className="">choisir des photos</p>
                                </div>
                            </div>
                        </div>
                        <ul className="list-none flex flex-wrap pt-2">
                            {this.props.images.map((image) =>
                                <li 
                                className="flex items-start py-2 pr-3" 
                                key={image.toString()}>
                                    <img className="h-24 w-24 sticky object-cover" src={image}  alt={''}/>
                                </li>
                            )}
                        </ul>
                        
                    </form>
                </main>
                <div className="items-baseline mt-8 text-xl font-extrabold mb-10">
                    <button  onClick={this.back} className="text-xl font-extrabold text-blue uppercase px-2">Précedent</button>
                    <button  onClick={this.handleSubmit } className="text-xl font-extrabold text-vert_2 uppercase float-right">soumettre</button>
                </div>
                <div className="pb-16"></div>
             </div>
              :
              <Spinner />
              }
               
            </>
        );
    }
}


export default Step4;