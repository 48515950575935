import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    user: null,
    isWaiting: false,
  },
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isWaiting = false;
    },
    getUsersSuccess: (state) => {},
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.isWaiting = false;
    },
    updateUserSuccess: (state, action) => {},
  },
});

export const {
  getUsers,
  getUser,
  startWaiting,
  hasError,
  getUsersSuccess,
  getUserSuccess,
  updateUserSuccess,
} = usersSlice.actions;

export const saveToken = (data) => async (dispatch) => {
  try {
    await api.post("/save-token", data).then((res) => {});
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const fetchUsers = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    await api
      .get("/users") // A revoir
      .then((response) => dispatch(getUsersSuccess(response.data)));
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const fetchUser = () => async (dispatch) => {
  dispatch(startWaiting());
  try {
    const user = localStorage.getItem("user");
    dispatch(getUserSuccess(user));
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export const updateUser = (id) => async (dispatch) => {
  // dispatch(startWaiting());
  try {
    await api
      .get("/users/:" + id) // To Do
      .then((response) => dispatch(updateUserSuccess(response.data)));
  } catch (error) {
    dispatch(hasError(error.message));
    return console.error(error);
  }
};

export default usersSlice.reducer;
