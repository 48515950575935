import React from 'react';
import  PageTitle from './../../common/PageTitle';

class Step3 extends React.Component {

  
    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.doSubmit()
    }
    
    render(){
        const {values} = this.props
        return(
            <>
                <PageTitle title="Evaluation" />
                <main className="py-5">
                    <div className="bg-white border-gray-200 rounded-lg px-2 py-4 pb-10  mb-20">
                        <p className="text-lg text-center font-bold pb-6 pt-6 text-black">Souhaitez-vous ajouter quelque chose ?</p>
                        <form >
                            <div className="col-span-2 py-6">
                            <textarea cols="30" rows="8" className="border-solid border-gray-400 border-2 p-3 md:text-xl w-full rounded-lg" 
                                placeholder="Message"
                                onChange={this.props.handleChange('commentaire')}
                                defaultValue={values.commentaire}>
                            </textarea>
                            </div>
                            <div className="items-baseline pt-6 text-xl font-extrabold">
                                <button type="reset"  onClick={this.back} className="text-xl font-extrabold text-blue uppercase px-2">Précedent</button>
                                <button type="submit" onClick={this.handleSubmit} className="py-2 px-4 rounded-full text-white text-base bg-blue float-right">soumettre</button>
                            </div>
                        </form>
                    </div>
                   
                </main>
            </>
        );
    }
}


export default Step3;