import Installment from "./Installment";
import Payment from "./Payment";
import React from "react";
import EmptyData from "../../common/EmptyData";

class Transaction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
    };
  }

  componentDidMount = () => {
    let coownerPayment = localStorage.getItem("coownerPayment");
    let payment = JSON.parse(coownerPayment);
    let transactionsFiltred = payment?.transactions.sort((x, y) => {
      let d1 = new Date(x?.payment_date);
      let d2 = new Date(y?.payment_date);
      // d1 = d1.setHours(
      //   d1.getHours() + y?.payment_time?.split(":")[0],
      //   d1.getMinutes() + y?.payment_time?.split(":")[1]
      // );
      // d2 = d2.setHours(
      //   d2.getHours() + x?.payment_time?.split(":")[0],
      //   d2.getMinutes() + x?.payment_time?.split(":")[1]
      // );

      return new Date(d1).getTime() - new Date(d2).getTime();
    });
    this.setState({
      transactions: transactionsFiltred,
    });
  };

  render() {
    const { transactions } = this.state;

    return (
      <div
        className={
          this.props.openTab === 2 ? "block bg-white rounded-lg py-1" : "hidden"
        }
        id="link2"
      >
        <div className="relative h-auto m-4">
          {transactions.length > 0 ? (
            <>
              <div
                className="border-r-2 border-gray-300 border-dotted absolute h-32 min-h-full top-0 z-0"
                style={{ left: 7 }}
              ></div>
              <ul className="list-none m-0 p-0 ">
                {transactions.map((transaction, index) =>
                  transaction.status === "CASHED" ? (
                    <Installment
                      key={index}
                      amount={transaction.amount}
                      date_limit={transaction.payment_date}
                      time={transaction.payment_time}
                      payment_method={transaction.payment_method}
                      type={transaction.type}
                    />
                  ) : (
                    <Payment
                      key={transaction.id}
                      payment_date={transaction.payment_date}
                      payment_periodicity={transaction.payment_periodicity}
                      apparts_payment={transaction.apparts_payment}
                      status={transaction.status}
                    />
                  )
                )}
              </ul>
            </>
          ) : (
            <EmptyData text="Aucune donnée disponible." />
          )}
        </div>
      </div>
    );
  }
}

export default Transaction;
