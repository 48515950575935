import Currency from "../../common/Currency";

export default function CustomAccount ({title, amount}){
return (
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-2">
               <div className="py-1 sm:px-0">
                    <div className="bg-white border-gray-200 rounded-lg px-4 py-4">
                        <div className="grid grid-cols-6 gap-4">
                            <div className="col-start-1 col-end-7 ">{title}</div>
    
                            <div className=" col-start-1 col-end-8 text-lg font-extrabold">
                                  {Currency(amount)} CFA
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
);
}