import React from 'react';
import  PageTitle from '../../common/PageTitle';
import Tabs from './Tabs';

class CoownerPayment extends React.Component {



    render(){
        
        return(
            <div className="container mx-auto mb-10">
                <PageTitle title="Appels de fonds" />
                <main className="py-5">
                    <Tabs
                        title1 ="Compte"
                        title2 ="Transactions"
                        title3 ="Impayés"
                        title4 ="A venir"
                    />
                </main>
            </div>

        );
    }
}


export default CoownerPayment;