import React from 'react'
import { IoPersonCircleOutline } from 'react-icons/io5'
import PageTitle from '../../common/PageTitle'



class  Resident extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
           resident:{} 
        }
    }

    componentDidMount = () => {
        let id = this.props.match.params.id;
        let annuaire =  localStorage.getItem('annuaire');
        annuaire = JSON.parse(annuaire);

        let resident =  annuaire?.residents.filter(function(f) {
            return f.id === parseInt(id)
        })
       
        if (resident.length > 0) {
            this.setState({
                resident: resident[0],
            })
        }
    }




render(){
    const {resident} = this.state
    return ( 
        <>
        <PageTitle title="Information de résident" />
        <div className="flex items-center h-screen w-full justify-center ">
             <div className="w-full">
                <div className="bg-white rounded-lg py-3 -mt-48">
                    <div className="photo-wrapper p-2 ml-28">
                        {/* <img className="w-32 h-32 rounded-full mx-auto" src="https://www.gravatar.com/avatar/2acfb745ecf9d4dccb3364752d17f65f?s=260&d=mp" alt="John Doe" /> */}
                      <IoPersonCircleOutline size={100} color="#4a5568"/>
                    </div>
                    <div className="p-2">
                        <h3 className="text-center text-xl text-gray-900 font-medium leading-8">{resident.first_name} {resident.last_name}</h3>
                        <div className="text-center text-gray-400 text-xs font-semibold">
                            <p>
                            {resident.civility === "MR" 
                            ? <span>Monsieur</span> 
                            : resident.civility === "MRS"
                            ?  <span>Madame</span> 
                            :
                            <span>Mademoiselle</span>  
                            }
                            </p>
                        </div>
                        <table className="text-xs my-3">
                            <tbody>
                                <tr>
                                    <td className="px-2 py-2 text-gray-500 font-semibold w-full">Adresse</td>
                                    <td className="px-2 py-2">{resident.address}</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-2 text-gray-500 font-semibold w-full">Numéro whatsapp</td>
                                    <td className="px-2 py-2">{resident.whatsapp_number}</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-2 text-gray-500 font-semibold w-full">Téléphone</td>
                                    <td className="px-2 py-2">{resident.phone1}</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-2 text-gray-500 font-semibold w-full">Autre téléphone</td>
                                    <td className="px-2 py-2">{resident.phone2}</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-2 text-gray-500 font-semibold w-full">E-mail</td>
                                    <td className="px-2 py-2">{resident.email1}</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-2 text-gray-500 font-semibold w-full">Autre e-mail </td>
                                    <td className="px-2 py-2">{resident.email2}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div>
                            <h5 className="px-2 py-4 mb-2 text-gray-500 font-semibold" >Appartement résidé</h5>
                                <p className="ml-2 capitalize text-gray-500">{resident.appartment_title} </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    );
  }
}

export default Resident