import {
  IoPersonCircleOutline,
  IoChevronForwardOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import EmptyData from "../../common/EmptyData";

export default function ListPersonnel({ personnel, openTab }) {
  return (
    <div className={openTab === 2 ? "block" : "hidden"} id="link2">
      <div className="mt-2">
        {personnel.map((perso) => (
          <Link
            to={`personnel/${perso.id}`}
            key={perso.id}
            className="px-1 mb-8 flex flex-row sm:flex-row items-center justify-between"
          >
            <IoPersonCircleOutline size={50} />
            <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400">
              <p className="text-lg font-medium capitalize truncate w-60">
                {perso.first_name.toLowerCase()} {perso.last_name.toUpperCase()}
              </p>
              <p className="text-sm font-light capitalize text-gray-500 truncate w-60">
                {perso.function}
              </p>
            </dd>
            <IoChevronForwardOutline />
          </Link>
        ))}
        {personnel.length === 0 && (
          <EmptyData text="Aucun personnel pour le moment." />
        )}
      </div>
    </div>
  );
}
