import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addFeed,
  deleteFeed,
  updateFeed,
} from "../../../features/feeds/feedsSlice";
import { useHistory } from "react-router-dom";
const user_id = localStorage.getItem("user_id");
const building_id = localStorage.getItem("building_id");
const partieCommune = JSON.parse(localStorage.getItem("partiecoms"));
/**
 ** TO DO
 *  To get your way out of this code keep cool and read it carefully
 */

export default function CRUDFeed(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { info } = props.location.state;
  const [state, setState] = useState({
    id: info.id === undefined ? null : info.id,
    created_by: user_id,
    type: info.type,
    place: info.place === undefined ? partieCommune[0]?.name : info.place,
    date: info.date === undefined ? "" : info.date,
    time: info.time === undefined ? "" : info.time,
    title: info.title === undefined ? "" : info.title,
    description: info.description === undefined ? "" : info.description,
    file: null,
    renderImage: "",
  });

  function handleChange(event) {
    if (event.target.name === "files") {
      setState((prevState) => ({
        ...prevState,
        file: event.target.files[0],
        renderImage: URL.createObjectURL(event.target.files[0]),
      }));
    } else {
      let fieldName = event.target.name;
      setState((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));
    }
  }

  function handleSubmit(event, fieldName) {
    const {
      created_by,
      type,
      place,
      date,
      time,
      title,
      description,
      id,
      file,
    } = state;

    let feed = {
      title,
      type,
      created_by,
      description,
    };
    if (type === "event") {
      feed = {
        ...feed,
        date,
        time,
        place,
      };
    }
    if (id) {
      if (fieldName === "delete") {
        dispatch(deleteFeed(id));
        history.push("/dashboard");
      } else {
        feed = {
          id,
          ...feed,
        };
        const data = new FormData();
        Object.keys(feed).forEach((key) => {
          data.append(key, feed[key]);
        });
        if (file) {
          data.append("images", file, file.name);
        }
        dispatch(updateFeed(data, data.get("id")));
        history.push("/dashboard");
      }
    } else {
      const data = new FormData();
      Object.keys(feed).forEach((key) => {
        data.append(key, feed[key]);
      });
      if (file) {
        data.append("images", file, file.name);
      }
      data.append("building_id", building_id);

      dispatch(addFeed(data));
      history.goBack();
    }
    event.preventDefault();
  }

  return (
    <div className="py-9 card-bg h-full">
      <div className="mx-5">
        <div className="mt-12 mb-4">
          <span className="text-3xl font-bold">
            {
              {
                actuality: "Votre actualité",
                event: "Créer un événement",
                annoucement: "Votre annonce",
                incident: "Déclarer un incident",
              }[info.type]
            }
          </span>
        </div>
        <div className="mb-4">
          <label>
            Titre <br />
            <input
              type="text"
              name="title"
              value={state.title}
              onChange={handleChange}
              className="rounded h-7 border-gray-300 w-full"
            />
          </label>
        </div>
        <div className="mb-4">
          <label>
            Description <br />
            <textarea
              type="text"
              name="description"
              value={state.description}
              onChange={handleChange}
              className="rounded border-gray-300 w-full h-32"
            />
          </label>
        </div>
        {info.type === "event" && (
          <div>
            <div className="flex justify-between  w-full">
              <div className="inline-block mb-4">
                <label>
                  Date <br />
                  <input
                    type="date"
                    name="date"
                    value={state.date}
                    onChange={handleChange}
                    className="rounded border-gray-300 w-auto"
                  />
                </label>
              </div>
              <div className="inline-block mb-4">
                <label>
                  Heure <br />
                  <input
                    type="time"
                    name="time"
                    value={state.time}
                    onChange={handleChange}
                    className="rounded border-gray-300 w-auto"
                  />
                </label>
              </div>
            </div>
            <div className="inline-block mb-4">
              <label>
                Lieu <br />
                <select
                  name="place"
                  onChange={handleChange}
                  className="rounded border-gray-300 w-auto"
                  value={state.place}
                >
                  {partieCommune.map((partie, i) => (
                    <option value={partie.name} key={i}>
                      {partie.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        )}
        <div className="mb-4">
          <div className="flex flex-wrap max-w-max content-start mb-4">
            <div className="border-2 h-40 border-dashed rounded-2xl mb-4 w-96 flex flex-col justify-center items-center text-center">
              <div className="text-center relative flex items-center justify-center max-h-full">
                <input
                  multiple
                  type="file"
                  name="files"
                  accept="image/*"
                  onChange={handleChange}
                  className="cursor-pointer relative block opacity-0 w-full p-20 z-50"
                />
                {!state.renderImage && (
                  <>
                    <svg
                      fill="none"
                      aria-hidden="true"
                      viewBox="0 0 48 48"
                      stroke="currentColor"
                      className="h-12 w-12 text-gray-400 absolute block top-7"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="absolute block font-medium text-gray-600 bottom-14">
                      <h4>Cliquer pour ajouter une image</h4>
                    </div>
                  </>
                )}
                {state.renderImage && (
                  <img
                    className="absolute max-h-full mx-auto"
                    src={state.renderImage}
                    alt={""}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={"flex mb-16 " + (info.id ? "justify-around" : "")}>
          {info.id && (
            <input
              type="button"
              name="delete"
              value="Supprimer"
              onClick={(e, name) => handleSubmit(e, "delete")}
              className="rounded px-3 mt-3 py-1"
              style={{ backgroundColor: "#FD0D0D", color: "#fff" }}
            />
          )}
          <input
            type="button"
            name="submit"
            value={info.id ? "Modifier" : "Envoyer"}
            onClick={(e, name) => handleSubmit(e, "submit")}
            className="rounded px-3 mt-3 py-1"
            style={{ backgroundColor: "#1d256e", color: "#fff" }}
          />
        </div>
      </div>
    </div>
  );
}
