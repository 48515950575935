import React from "react";
import ProgressBar from "./../../progress-bar/progress-bar";

const SurveyOption = (props) => {
  return (
    <div className="flex-row w-full px-4 card-bg">
      <input type="radio" name="option" value="Good" />{" "}
      <span className="ml-2">primis in faucibus orci luctus et ultrices</span>
      <div className="ml-7 mt-2">
        <ProgressBar bgcolor={"#"} completed={30} />
      </div>
      <br />
      <input type="radio" name="option" value="Very Good" />{" "}
      <span className="ml-2">primis in faucibus orci luctus et ultrices</span>
      <div className="ml-7 mt-2">
        <ProgressBar bgcolor={"#"} completed={20} />
      </div>
      <br />
      <input type="radio" name="option" value="Better" />{" "}
      <span className="ml-2">primis in faucibus orci luctus et ultrices</span>
      <div className="ml-7 mt-2">
        <ProgressBar bgcolor={"#"} completed={50} />
      </div>
      <br />
    </div>
  );
};

export default SurveyOption;
