import * as moment from "moment";
import "moment/locale/fr";
import { IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

const ListItem = ({ evaluation }) => {
  const getFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase();
  };

  return (
    <article>
      <Link
        to={`/evaluation/${evaluation.id}`}
        className="px-1 mb-8 flex flex-row sm:flex-row items-center justify-between"
      >
        {/* <IoHappyOutline size={34}/>  */}
        <div className="w-12 h-12 -ml-2 bg-gray-200">
          <p className="font-extrabold text-center p-2 text-2xl">
            {getFirstLetter(evaluation.evaluable_id)}
          </p>
        </div>
        <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400 w-0">
          <p className="text-sm font-black capitalize truncate">
            {evaluation.title}
          </p>
          <p className="text-sm font-medium text-gray-500">
            {evaluation.evaluable_id}
          </p>
          <p className="text-sm font-medium text-blue capitalize truncate">
            {evaluation.type}
          </p>
          <p className="flex justify-center pr-2 -mt-9 items-end float-right">
            <IoChevronForwardOutline />
          </p>
          <p className="flex justify-center items-end -mt-4 float-right text-gray-500 text-sm">
            {moment(evaluation.date_envoi).format("ll")}
          </p>
        </dd>
      </Link>
    </article>
  );
};

ListItem.propTypes = {
  evaluation: PropTypes.object,
};

ListItem.defaultProps = {
  evaluation: {},
};
export default ListItem;
