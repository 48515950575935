import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Account from "./Account";
import Unpai from "./Unpai";
import Tocomeup from "./Tocomeup";
import Transaction from "./Transaction";
  
const Tabs = ({ title1, title2, title3, title4}) => {

  const [openTab, setOpenTab] = useState(1);
  const [paymentUnpai, setUnpai] = useState([]);
  const [paymentToComeUp, setPaymentToComeUp] = useState([]);

  useEffect(()=> {

        let coownerPayment =  localStorage.getItem('coownerPayment');
        let payment = JSON.parse(coownerPayment);
      
        setUnpai(payment?.unpaiPerPeriodicity)
        setPaymentToComeUp(payment?.paymentToComeUp)
  }, [])

  return (
  
        <div className="flex flex-wrap">
          <div className="w-full">
            <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" role="tablist" >
            {/* <Permission hasPermission={Role}> */}
              <li  className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a className={
                    "text-sm font-bold uppercase px-0 py-3 block leading-normal " +
                    (openTab === 1
                      ? "text-vert_2 border-b-2 border-black border-opacity-100"
                      : "text-blue")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  {title1}
                </a>
              </li>
            {/* </Permission> */}
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a className={
                    "text-sm font-bold uppercase px-0 py-3 block leading-normal " +
                    (openTab === 2
                      ? "text-vert_2 border-b-2 border-black border-opacity-100"
                      : "text-blue")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist">
                  {title2}
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a className={
                    "text-sm font-bold uppercase px-0 py-3 block leading-normal " +
                    (openTab === 3
                      ? "text-vert_2 border-b-2 border-black border-opacity-100"
                      : "text-blue")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist">
                  {title3}
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a className={
                    "text-sm font-bold uppercase px-0 py-3 block leading-normal " +
                    (openTab === 4
                      ? "text-vert_2 border-b-2 border-black border-opacity-100"
                      : "text-blue")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link4"
                  role="tablist">
                  {title4}
                </a>
              </li>
            
            </ul>
           
              <div className="relative flex flex-col min-w-0 break-words w-full mb-20  rounded">
                <div className="px-1 py-1 flex-auto">
                  <div className="tab-content tab-space">
                      <Account  openTab={openTab} />
                      <Transaction  openTab={openTab} />
                      <Unpai  openTab={openTab}  unpaiPerPeriodicity={paymentUnpai}/>
                      <Tocomeup openTab={openTab} paymentTocomeup={paymentToComeUp} />
                  </div>
                </div>
              </div>
          </div>
        </div>
  );
};

Tabs.propTypes = {
    /**
     * The page title.
     */
    title1: PropTypes.string,
    title2: PropTypes.string,
    title3: PropTypes.string,
    title4: PropTypes.string
   
  };

  Tabs.defaultProps = {

    title2:'',
    title1:'',
    title3:'',
    title4:'',
  }
export default Tabs;