import { createSlice } from "@reduxjs/toolkit";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    startDate: null,
    lastDate: null,
    startDay: null,
    lastDay: null,
    month: null,
  },
  reducers: {
    setCalendarDate: (state, action) => {
      state.month = months[action.payload.month];
      state.startDate = action.payload.startDate;
      state.startDay = action.payload.startDay;
      state.lastDate = action.payload.lastDate;
      state.lastDay = action.payload.lastDay;
    },
  },
});

export const { setCalendarDate } = calendarSlice.actions;

export default calendarSlice.reducer;
