import React from "react";
import FilterItems from "./../common/FilterItems";

import Role from "../permission";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import Permission from "@easytool/react-permission";
import Feeds from "../components/babillard/feed/feeds";
import { fetchUser } from "../features/users/usersSlice";
import { fetchFeeds } from "../features/feeds/feedsSlice";
import Fab from "../components/floating-action-button/babillard/fab";

class Dashboard extends React.Component {
 

  componentDidMount = () => {
    const { dispatch } = this.props;
   
    dispatch(fetchFeeds());
    dispatch(fetchUser());

  };

  render() {
    return (
      <div className="container">
        <FilterItems title="Tableau de bord" />
        {this.props.isWaiting && <Spinner />}

        {!this.props.isWaiting && (
          <main>
            <Permission hasPermission={Role}>
              <Feeds feeds={this.props.feeds} />
              <Fab />
              <div className="pb-16"></div>
            </Permission>
          </main>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  feeds: state.feeds.filteredFeeds,
  isWaiting: state.feeds.isWaiting,
  filterKey: state.feeds.filterKey,
});

export default connect(mapStateToProps, null)(Dashboard);
