import React from 'react';
import { Link } from 'react-router-dom';
import  PageTitle from './../../common/PageTitle';
import Api from './../../api'
import EvalIncident from './EvalIncident';
import EvalWithoutIncident from './EvalWithoutIncident';
import Spinner from '../../common/Spinner';

class Evaluation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            evaluation:{},
            id:0,
            incident: {},
            hasEval:false,
            loading:true

        }
    }

    componentDidMount =() =>{
        let id = this.props.match.params.id
        this.setState({id:id})
        this.showEvaluation(id)
    }

    showEvaluation = (id) =>{
       
        Api.getEvaluationById(id)
        .then((response) =>{
            this.setState({
                evaluation: response.data.data.evaluation,
                incident: response.data.data.evaluation.incident,
                hasEval:response.data.data.hasAnswered,
                loading:false
            })
        })
        .catch((error) => {
            setTimeout(() => {
                this.setState({ loading:false }) 
            }, 1000);
            
        })
    }


   
    render(){
        const {id, incident, evaluation, hasEval, loading} = this.state
        let finalClass = `w-full flex items-center justify-center rounded-md bg-blue text-white py-2 mt-10 mb-20`

        return(
                <div className="container mx-auto">
                <PageTitle title="Evaluations" />
                {loading && <Spinner />  }
                {!loading &&
                    <main className="py-5">
                 <div className="bg-white border-gray-200 rounded-lg px-4 py-4 pb-10 mb-20">
                    {incident === null ?
                    <EvalWithoutIncident  evaluation={evaluation} />
                    :
                    <EvalIncident  
                    evaluation={evaluation}
                     />
                    }
                </div>
                 {!hasEval &&
                     evaluation.statut  !== "Fermé" && (new Date(evaluation.closed_at) > new Date())  &&
                        <Link to={`/main-evaluation/${id}`} className={finalClass}>
                          Evaluer
                        </Link>

                  }
                </main>
                }
           </div>
        );
    }
}


export default Evaluation;