import React from 'react';
import PageTitle from "../../common/PageTitle"
import Spinner from "../../common/Spinner"
import List from '../../components/generalAssembly/agenda/List';
import ListItem from '../../components/generalAssembly/agenda/ListItem';




class Agenda extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            agendas: this.props.location.state.agendas
        }
        this.myRef = React.createRef();
    }
    componentDidMount() {
        this.myRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

    render(){
        const { isWaiting} = this.props;
        return(
            <div className="container mx-auto mb-28 bg-white" ref={this.myRef}>
                <PageTitle title="Assemblée générale" /> 
                {isWaiting ?  < Spinner /> 
                :
                <main className="py-5 h-screen" >
                    <h1 className="pl-2 text-lg font-bold">Ordre du jour</h1>
                        <List >
                        {this.state.agendas.map((agenda) =>(
                            <ListItem
                               key={agenda.id}
                               title={agenda.title} 
                               description={agenda.description}
                           />
                           )
                        )}
                      </List>
                    
                    
                </main>
                } 
            </div>
        );
    }
}


export default Agenda;