import axios from "axios";
import React from "react";
import Button from "../components/form/Button";
import FormGroup from "../components/form/FormGroup";
import InputGroup from "../components/form/InputGroup";
import Api from './../api'



class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            password_empty:false,
            email_empty: false,
            email:'',
            password:'',
            token:''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount = () => {
        let token = this.props.match.params.token
       // console.log(token)
        this.verifyToken(token)
        
    }
    
    
    verifyToken(token) {
        axios.get(`${Api.basUrl}/coownerVerifyToken/${token}`)
        .then((res) => {
            this.setState({
              email: res.data.data.email,
              token:res.data.data.token
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }


    handleChange = (e) => {
        const { data, email, token} = this.state
        data["email"] = email;
        data["token"] = token;
        data[e.target.name] = e.target.value;

        this.setState({
            data:data
        })
      
    }
    
    handleSubmit = (e) => {
        e.preventDefault()

        const { data} = this.state
        axios.post(`${Api.basUrl}/coownerResetPassword`, {
            email: data.email,
            token: data.token,
            password: data.password,
            password_confirmation: data.password_confirmation,
        })
        .then((res) =>{
            this.props.history.push('/')
        })
        .catch((error) => {
            console.log(error)
        })
    }

    render() {
        const { data,  password_empty, email, email_empty} = this.state
        return(
            <div className="container max-auto px-2">
                <main className="py-5 rounded-xl bg-white px-6 mt-14">
                    <h1 className="text-2xl font-extrabold text-center">Nouveau mot de passe</h1>
                    <form onSubmit={this.handleSubmit}>
                        <div className="mt-12">
                            <FormGroup>
                                <InputGroup
                                    label="Adresse électronique"
                                    type="email"
                                    name="email"
                                    redonly="true"
                                    onChange={e => this.handleChange}
                                    value={email}
                                    placeholder="votre adresse e-mail"
                                    error={email_empty}
                                    errorText="Ce champ ne peut être vide"
                                
                                />
                            </FormGroup>
                            <FormGroup>
                                <InputGroup
                                label="Nouveau mot de passe"
                                type="password"
                                name="password"
                                onChange={this.handleChange}
                                value={data.password}
                                placeholder="Nouveau mot de passe"
                                error={password_empty}
                                errorText="Ce champ ne peut être vide"
                                />
                            </FormGroup>
                            <FormGroup>
                                <InputGroup
                                label="Confirmer mot de passe"
                                type="password"
                                name="password_confirmation"
                                onChange={this.handleChange}
                                value={data.password_confirmation}
                                placeholder="confirmer mot de passe"
                                error={password_empty}
                                errorText="Ce champ ne peut être vide"
                                />
                            </FormGroup>
                     
                         <FormGroup>
                          <Button className="bg-vert_2" text="Envoyer" submit full />
                         </FormGroup>
                    
                           
                        </div>
                    </form>
                </main>
            </div>
        );
    }
}

export default ResetPassword