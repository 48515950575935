import { NavLink } from "react-router-dom";

export default function NavItem({ href, children, customClasses}) {

  return (
      <NavLink
        to={href}
        activeClassName="text-blue"
        className={`block px-2 pt-2 rounded-md ${customClasses}`}
      > 
       {children}
      </NavLink>
  )
}
  