import React from "react";
import { MdMood, MdNotificationsNone } from "react-icons/md";
import { FaLongArrowAltLeft, FaRegBuilding } from "react-icons/fa";
import Firebase from "../components/firebase/firebase";
import UserDropdown from "./UserDropdown";
import Nav from "./Nav";
import NavItem from "./NavItem";
import { withRouter } from "react-router-dom";
import Permission from "@easytool/react-permission";

import Role from "../permission";

const routes = [
  "tenants",
  "dashboard",
  "incidents",
  "annuaire",
  "documents",
  "appel",
  "general-assemblies",
  "works",
  "calendrier",
  "evaluations",
  "notifications",
];

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: null,
      notificationsNumber: null,
      tenant_id: localStorage.getItem("tenant_id"),
      building_id: localStorage.getItem("building_id"),
      user_id: localStorage.getItem("user_id"),
    };
  }

  componentDidMount() {
    const { tenant_id, building_id, user_id } = this.state;
    const notifRef = Firebase.database()
      .ref(tenant_id + "/notifications")
      .orderByChild("index_notifiable_id")
      .equalTo(`${building_id}-${user_id}`);
    notifRef.on("value", (snapshot) => {
      const notifications = snapshot.val();
      const notificationsList = [];
      for (let id in notifications) {
        if (notifications[id].read_at === "NULL") {
          notificationsList.push({ id, ...notifications[id] });
        }
      }
      this.setState({
        notificationsNumber: notificationsList.length,
      });
    });
  }

  render() {

    const showMenu = localStorage.getItem("building_id");
    const { notificationsNumber } = this.state;
    const pathname = this.props.location.pathname.replace(/^\/+|\/+$/g, "").split("/")[0] ;

    return (
      <div className="flex flex-col sm:flex-row sm:h-20 inset-x-0 px-4 border-b border-gray-300 bg-white fixed z-50">
        <div className="h-16 w-full flex items-center justify-between sm:h-screen">
      
           {routes.includes(pathname)   ? (
             <NavItem href="/tenants">
             <FaRegBuilding size={30} />
           </NavItem>
           ):(
            <FaLongArrowAltLeft
              size={30}
              onClick={() => this.props.history.goBack()}
            />
           )
           }
          {showMenu &&
            pathname !== "tenants" && (
            <div className="absolute right-14">
              <Nav>
                <Permission hasPermission={["coowner"]}>
                  <NavItem href="/evaluations" permission={Role}>
                    <MdMood size={30} />
                  </NavItem>
                </Permission>
                <NavItem href="/notifications">
                  <MdNotificationsNone size={30} />
                  {notificationsNumber !== 0 && (
                    <span className="absolute -mt-10 right-2 px-2 py-0 bg-red-800 rounded-full text-white text-sm">
                      {" "}
                      {notificationsNumber}{" "}
                    </span>
                  )}
                </NavItem>
              </Nav>
            </div>
          )}
          <UserDropdown />
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);
