import React from "react";
import PropTypes from "prop-types";

import MainMenu from "./MainMenu";
import Navbar from "./Navbar";

const DefaultLayout = ({ children, noNavbar, noMainMenu }) => (
  <div className="container">
    {!noNavbar && <Navbar />}

    {children}

    {!noMainMenu && <MainMenu />}
  </div>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  noMainMenu: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noMainMenu: false,
};

export default DefaultLayout;
