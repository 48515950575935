import {
  IoPersonCircleOutline,
  IoChevronForwardOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import EmptyData from "../../common/EmptyData";

export default function ListCoowner({ coowners, openTab }) {
  return (
    <div className={openTab === 1 ? "block" : "hidden"} id="link1">
      <div className="mt-2">
        {coowners.map((coowner) => (
          <Link
            to={`coowner/${coowner.id}`}
            key={coowner.id}
            className="px-1 mb-8 flex flex-row sm:flex-row items-center justify-between"
          >
            <IoPersonCircleOutline size={50} />
            <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400">
              <p className="text-lg font-medium text-gray-900 capitalize truncate w-60">
                {coowner.first_name.toLowerCase()}{" "}
                {coowner.last_name.toUpperCase()}
              </p>
              <p className="text-base lowercase text-gray-600">
                {coowner.appartments.slice(0, 2).map((appartment, i) => (
                  <span className="px-1 capitalize" key={i}>
                    {appartment.title.toLowerCase()}
                    {i + 1 < coowner.appartments.slice(0, 2).length ? "," : ""}
                  </span>
                  
                ))}
                {coowner.appartments.length > 2 && "..."}
              </p>
            </dd>
            <IoChevronForwardOutline />
          </Link>
        ))}
        {coowners.length === 0 && (
          <EmptyData text="Aucun copropriétaire pour le moment." />
        )}
      </div>
    </div>
  );
}
