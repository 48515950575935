import React from "react";
import { useState } from "react";
import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { formatDate } from "../../../utils/utils";
import { GoComment, GoLocation, GoCalendar } from "react-icons/go";
import "./modal.css";
import APP_ENV from "../../../configs/env";
import EmptyData from "../../../common/EmptyData";

const user_id = localStorage.getItem("user_id");

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main p-4 rounded-lg">{children}</section>
    </div>
  );
};

export default function Feed(props) {
  const info = props.info;
  const [state, setState] = useState({
    show: false,
    lineClamp: false,
    showMoreOrLess: "Voir Plus",
  });

  function toggleLineClamp() {
    setState((prevState) => ({
      ...prevState,
      lineClamp: !prevState.lineClamp,
      showMoreOrLess: prevState.lineClamp ? "Voir Plus" : "Voir Moins",
    }));
  }

  function toggleModal() {
    setState((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  }

  function getImageURI() {
    let uri = "";
    if (Object.values(info?.images)[0]?.original_url?.split("storage")[1]) {
      uri =
        APP_ENV.fileENDPOINT +
        Object.values(info?.images)[0]?.original_url?.split("storage")[1];
    }
    return uri;
  }

  return (
    <div className="card-bg py-3 mt-3">
      {state.show && (
        <div id="modal" onClick={toggleModal}>
          <Modal show={state.show} handleClose={toggleModal}>
            {info?.views?.map((view, i) => (
              <div className="flex items-center m-2" key={i}>
                <div className="inline-block">
                  {view?.user?.avatar ? (
                    <img
                      className="rounded-full w-14 h-12 object-cover"
                      src={view?.user?.avatar}
                      alt=""
                    />
                  ) : (
                    <FiUser className="rounded-full w-11 h-11 bg-gray-200" />
                  )}
                </div>
                <span className="inline-block ml-1 text-sm">
                  {view.user.id === parseInt(user_id) ? ' Vous ' : view?.user?.name }
                </span>
              </div>
            ))}
            {info?.views?.length === 0 && (
              <EmptyData
                text={`Personne n'a vu ${
                  {
                    event: "l'evenement",
                    annoucement: "l'annonce",
                    actuality: "l'actualité",
                  }[info.type]
                }.`}
              />
            )}
          </Modal>
        </div>
      )}
      {/* Cart header div */}
      <div className="px-4">
        {/* First part */}
        <Link
          to={{
            pathname: `/feeds/` + info?.id,
            state: {
              info: info,
            },
          }}
        >
          <div className="flex w-view">
            {/* User's avatar */}
            <div className="inline-block">
              {info?.created_by?.avatar ? (
                <img
                  className="rounded-full w-14 h-12 object-cover"
                  src={info?.created_by?.avatar}
                  alt=""
                />
              ) : (
                <FiUser className="rounded-full w-11 h-11 bg-gray-200" />
              )}
            </div>

            {/* Cart header detail */}

            <div className="flex-row w-full">
              <div className="flex justify-between">
                <div className="inline-block ml-1">
                  <span className="text-sm font-bold">
                    {info?.created_by?.name}
                  </span>
                </div>

                <div className="inline-block ml-2">
                  <span className="text-sm font-thin text-gray-400">
                    {
                      {
                        coowner: "“Copropriétaire”",
                        resident: "”Résident”",
                      }[info?.created_by?.type]
                    }
                  </span>
                </div>

                <div
                  className="inline-block rounded-xl border-blue-700 border-2 ml-2 h-7"
                  style={{ borderColor: "#05c1bd" }}
                >
                  <span
                    className="px-1 text-blue-500 text-sm"
                    style={{ color: "#05c1bd" }}
                  >
                    {
                      {
                        event: "Evenement",
                        annoucement: "Annonce",
                        actuality: "Actualité",
                      }[info.type]
                    }
                  </span>
                </div>
              </div>

              <div className="flex justify-between w-full text-xs ml-1 text-gray-400">
                <span>Posté le {formatDate(info.created_at)}</span>
              </div>
            </div>
          </div>
        </Link>

        <div className="mt-3">
          {info.title && (
            <div className="inline-block text-sm font-bold capitalize">
              <span>{info?.title.toLowerCase()}</span>
            </div>
          )}
          <p
            className={!state.lineClamp ? "line-clamp-2" : "line-clamp-none"}
            onClick={toggleLineClamp}
          >
            {info.description}
          </p>
          <span
            className="text-sm font-thin text-gray-400"
            onClick={toggleLineClamp}
            hidden={!(info.description.length >= 200)}
          >
            {state.showMoreOrLess}
          </span>
        </div>
        {info.type === "event" && (
          <div className="flex justify-around">
            <div className="inline-block">
              <div className="flex-row">
                <GoCalendar className="inline-block" />
                <span className="inline-block font-bold ml-2">
                  Le {formatDate(info.date)}
                </span>
              </div>
            </div>
            <div className="inline-block">
              <div className="flex-row">
                <GoLocation className="inline-block" />
                <span className="inline-block font-bold ml-2">
                  {info.place}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Cart content div */}
      <Link
        to={{
          pathname: `/feeds/` + info?.id,
          state: {
            info: info,
          },
        }}
        className="w-full"
      >
        <div className="w-full h-2/4 mt-2">
          {/* Ici on aura le caroussel */}
          <img
            className="w-full max-w-md object-cover"
            src={getImageURI()}
            alt=""
          />
        </div>
      </Link>
      {/* Cart bottom div */}
      <div className="flex justify-around mt-2">
        {/* Views */}
        <div className="inline-block rounded-xl bg-gray-100 px-6 py-0 shadow-lg">
          <div className="flex-row" onClick={toggleModal}>
            <IoEyeOutline className="inline-block" />
            <span className="inline-block">{info?.views?.length}</span>
          </div>
        </div>
        {/* Comments */}
        <Link
          to={{
            pathname: `/feeds/` + info?.id,
            state: {
              info: info,
            },
          }}
        >
          <div className="inline-block rounded-xl bg-gray-100 px-6 shadow-lg">
            <div className="flex-row">
              <GoComment className="inline-block" />
              <span className="inline-block">{info?.comments?.length}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
