import React from 'react'
import { IoPersonCircleOutline } from 'react-icons/io5'
import PageTitle from '../../common/PageTitle'



class  Coowner extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
           coowner:{} 
        }
    }

    componentDidMount = () => {
        let id = this.props.match.params.id;
        let annuaire =  localStorage.getItem('annuaire');
        annuaire = JSON.parse(annuaire);

        let coowner =  annuaire?.coowners.filter(function(f) {
            return f.id === parseInt(id)
        })
       
        if (coowner.length > 0) {
            this.setState({
                coowner: coowner[0],
            })
        }
    }




render(){
    const {coowner} = this.state
    return ( 
        <>
         <PageTitle title="Information du copropriétaire" />
        <div className="flex items-center h-screen w-full justify-center ">
             <div className="w-full">
                <div className="bg-white rounded-lg py-3 -mt-48">
                    <div className="photo-wrapper p-2 ml-28">
                        {/* <img className="w-32 h-32 rounded-full mx-auto" src="https://www.gravatar.com/avatar/2acfb745ecf9d4dccb3364752d17f65f?s=260&d=mp" alt="John Doe" /> */}
                      <IoPersonCircleOutline size={100} color="#4a5568"/>
                    </div>
                    <div className="p-2">
                        <h3 className="text-center text-xl text-gray-900 font-medium leading-8">{coowner.first_name} {coowner.last_name}</h3>
                        <div className="text-center text-gray-400 text-xs font-semibold">
                            <p>
                            {coowner.civility === "MR" 
                            ? <span>Monsieur</span> 
                            : coowner.civility === "MRS"
                            ?  <span>Madame</span> 
                            :
                            <span>Mademoiselle</span>  
                            }
                            </p>
                        </div>
                        <table className="text-xs my-3">
                            <tbody><tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold w-full">Adresse</td>
                                <td className="px-2 py-2">{coowner.address}</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold w-full">Numéro whatsapp</td>
                                <td className="px-2 py-2">{coowner.whatsapp_number}</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold w-full">Autre téléphone</td>
                                <td className="px-2 py-2">{coowner.other_phone}</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-2 text-gray-500 font-semibold w-full">E-mail</td>
                                <td className="px-2 py-2">{coowner.email}</td>
                            </tr>
                        </tbody>
                        </table>

                        <div>
                            <h5 className="px-2 py-4 mb-2 text-gray-500 font-semibold" >Appartements:</h5>
                        <ul className="list-disc flex flex-wrap">
                            {coowner?.appartments?.map((appartment, index) => 
                                <li className="flex items-start pr-6 py-2"  key={appartment.id}>
                                    <span className="h-6 w-6 text-white text-center rounded-full  bg-cray_2">{index+1}</span>
                                    <p className="ml-2 lowercase text-gray-500">{appartment.title} </p>
                                </li>
                            )}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    );
  }
}

export default Coowner