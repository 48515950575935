import React from "react";
import Tabs from "./Tabs";
import Spinner from "../../common/Spinner";
import PageTitle from "./../../common/PageTitle";
import {
  fetchClosedIncidents,
  fetchOpenIncidents,
} from "../../features/feeds/feedsSlice";
import { connect } from "react-redux";

class Intervention extends React.Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(fetchOpenIncidents());
    dispatch(fetchClosedIncidents());
  };

  render() {
    return (
      <div className="container mx-auto">
        <PageTitle title="Liste des interventions" />
        {this.props.isWaiting && <Spinner />}
        {!this.props.isWaiting && (
          <main className="py-5 mb-10">
            <Tabs
              title1="interventions ouvertes"
              title2="interventions fermées"
              incidentOuvert={this.props.openIncidents}
              incidentCloses={this.props.closedIncidents}
            />
          </main>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  openIncidents: state.feeds.filteredOpenIncidents,
  closedIncidents: state.feeds.filteredClosedIncidents,
  isWaiting: state.feeds.isWaiting,
});

export default connect(mapStateToProps, null)(Intervention);
