import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilterKey } from "../features/feeds/feedsSlice";

import Permission from "@easytool/react-permission";
import Role from "../permission";

export default function FilterItems() {
  const filterKey = useSelector((state) => state.feeds.filterKey);
  const dispatch = useDispatch();

  const selectedBtn = {
    shape: { backgroundColor: "#14448B" },
    text: { color: "#fff" },
  };

  const unselectedBtn = {
    shape: { backgroundColor: "#C0C0C0" },
    text: { color: "#000" },
  };

  return (
    <div className="flex pt-20 overflow-x-scroll px-1 scrollbar-hidden">
      <div
        className="inline-block rounded-lg py-0.5 mr-4"
        style={
          filterKey === "all" ? selectedBtn.shape : unselectedBtn.shape
        }
        onClick={() => {
          dispatch(setFilterKey("all"));
        }}
      >
        <span
          className="px-4 text-sm "
          style={
            filterKey === "all" ? selectedBtn.text : unselectedBtn.text
          }
        >
          Tous
        </span>
      </div>
      <div
        className="inline-block rounded-lg py-0.5 mr-4"
        style={
          filterKey === "actuality"
            ? selectedBtn.shape
            : unselectedBtn.shape
        }
        onClick={() => {
          dispatch(setFilterKey("actuality"));
        }}
      >
        <span
          className="px-4 text-sm "
          style={
            filterKey === "actuality"
              ? selectedBtn.text
              : unselectedBtn.text
          }
        >
          Actualité
        </span>
      </div>
      <div
        className="inline-block rounded-lg py-0.5 mr-4"
        style={
          filterKey === "annoucement"
            ? selectedBtn.shape
            : unselectedBtn.shape
        }
        onClick={() => {
          dispatch(setFilterKey("annoucement"));
        }}
      >
        <span
          className="px-4 text-sm "
          style={
            filterKey === "annoucement"
              ? selectedBtn.text
              : unselectedBtn.text
          }
        >
          Annonce
        </span>
      </div>
      <div
        className="inline-block rounded-lg py-0.5 mr-4"
        style={
          filterKey === "event" ? selectedBtn.shape : unselectedBtn.shape
        }
        onClick={() => {
          dispatch(setFilterKey("event"));
        }}
      >
        <span
          className="px-4 text-sm "
          style={
            filterKey === "event" ? selectedBtn.text : unselectedBtn.text
          }
        >
          Evenement
        </span>
      </div>
      {/* <div
        className="inline-block rounded-lg py-0.5 mr-4"
        style={
          filterKey === "survey"
            ? selectedBtn.shape
            : unselectedBtn.shape
        }
        onClick={() => dispatch(setFilterKey("survey"))}
      >
        <span
          className="px-4 text-sm "
          style={
            filterKey === "survey"
              ? selectedBtn.text
              : unselectedBtn.text
          }
        >
          Sondage
        </span>
      </div> */}
      <div
        className="inline-block rounded-lg py-0.5 mr-4"
        style={
          filterKey === "incident"
            ? selectedBtn.shape
            : unselectedBtn.shape
        }
        onClick={() => {
          dispatch(setFilterKey("incident"));
        }}
      >
        <span
          className="px-4 text-sm "
          style={
            filterKey === "incident"
              ? selectedBtn.text
              : unselectedBtn.text
          }
        >
          Incident
        </span>
      </div>
      <Permission hasPermission={["coowner"]}>
      <div
        permission={Role}
        className="inline-block rounded-lg py-0.5 mr-4"
        style={
          filterKey === "evaluation"
            ? selectedBtn.shape
            : unselectedBtn.shape
        }
        onClick={() => {
          dispatch(setFilterKey("evaluation"));
        }}
      >
        <span
          className="px-4 text-sm "
          style={
            filterKey === "evaluation"
              ? selectedBtn.text
              : unselectedBtn.text
          }
        >
          Evaluation
        </span>
      </div>
      </Permission>
    </div>
  );
}
