import React from "react";
import Spinner from "../../common/Spinner";
import { connect } from "react-redux";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { formatDate } from "../../utils/utils";
import { Link } from "react-router-dom";
import EmptyData from "../../common/EmptyData";
import { fetchIntervenorInterventions } from "../../features/interventions/interventionsSlice";
import { setCalendarDate } from "../../features/calendar/calendarSlice";
const building_name = localStorage.getItem("building_name");

class Calendar extends React.Component {
  componentDidMount = async () => {
    let startDate = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
    );
    let lastDate = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
    );
    let startDay = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
    ).getUTCDate();
    let lastDay = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
    ).getUTCDate();
    let month = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
    ).getMonth();

    const calendar = {
      startDate,
      lastDate,
      startDay,
      lastDay,
      month,
    };

    if (this.props.startDate === null) {
      await this.props.onSetCalendarDate(calendar);
    }

    await this.props.onFetchIntervenorInterventions();
  };

  handleDateChange = async (direction) => {
    let startDate;
    let lastDate;
    let startDay;
    let lastDay;
    let month;

    if (direction === "back") {
      startDate = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            1 -
            7
        )
      );
      lastDate = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            7 -
            7
        )
      );
      startDay = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            1 -
            7
        )
      ).getUTCDate();
      lastDay = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            7 -
            7
        )
      ).getUTCDate();
      month = new Date(
        new Date(this.props.lastDate).setDate(
          new Date(this.props.lastDate).getDate() -
            new Date(this.props.lastDate).getDay() +
            1 -
            7
        )
      ).getMonth();
    } else {
      startDate = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            1 +
            7
        )
      );
      lastDate = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            7 +
            7
        )
      );
      startDay = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            1 +
            7
        )
      ).getUTCDate();
      lastDay = new Date(
        new Date(this.props.startDate).setDate(
          new Date(this.props.startDate).getDate() -
            new Date(this.props.startDate).getDay() +
            7 +
            7
        )
      ).getUTCDate();
      month = new Date(
        new Date(this.props.lastDate).setDate(
          new Date(this.props.lastDate).getDate() -
            new Date(this.props.lastDate).getDay() +
            1 +
            7
        )
      ).getMonth();
    }

    const calendar = {
      startDate,
      lastDate,
      startDay,
      lastDay,
      month,
    };

    await this.props.onSetCalendarDate(calendar);
  };

  render() {
    return (
      <div className="container mx-auto h-full overflow-y-auto">
        <div className="w-full flex justify-start items-center bg-white pt-16">
          <span className="text-2xl font-bold p-2">Calendrier</span>
        </div>
        {this.props.isWaiting && <Spinner />}
        <div className="flex justify-between items-center bg-blue h-16 mt-3 mx-2 px-5">
          <IoChevronBackOutline
            className="text-white"
            size={25}
            onClick={() => this.handleDateChange("back")}
          />
          <span className="text-white text-sm font-bold">
            {this.props.startDay} - {this.props.lastDay} {this.props.month}{" "}
          </span>
          <IoChevronForwardOutline
            className="text-white"
            size={25}
            onClick={() => this.handleDateChange("forward")}
          />
        </div>
        <div className="mb-24">
          {this.props.formatedInterventions
            .filter((item) => {
              if (
                item.date >=
                  new Date(
                    ...new Date(this.props.startDate).toDateString().split("/")
                  ).getTime() &&
                item.date <=
                  new Date(
                    ...new Date(this.props.lastDate).toDateString().split("/")
                  ).getTime()
              ) {
                return item;
              }
              return null;
            })
            .sort((x, y) => new Date(x.date) - new Date(y.date))
            .map((item, i) => (
              <div
                className="flex flex-col justify-start items-center w-full"
                key={i}
              >
                <div className="w-full flex justify-start items-center h-12">
                  <span className="text-gray-400 mx-3 font-bold">
                    {formatDate(new Date(item.date))}
                  </span>
                </div>
                {item.interventions.map((intervention, j) => (
                  <Link
                    to={{
                      pathname: `/interventions/${intervention?.work?.id}`,
                      state: {
                        info: {
                          ...intervention?.work,
                        },
                      },
                    }}
                    key={j}
                    className="w-full bg-white h-25"
                  >
                    <div className="flex flex-row my-2">
                      <div className="flex flex-col justify-between items-center p-2 font-bold w-1/5">
                        <span>
                          {`${intervention?.start_time?.split(":")[0]}:${
                            intervention?.start_time?.split(":")[1]
                          }`}
                        </span>
                        <span>|</span>
                        <span>
                          {`${intervention?.end_time?.split(":")[0]}:${
                            intervention?.end_time?.split(":")[1]
                          }`}
                        </span>
                      </div>
                      <div className="flex flex-col justify-between items-start p-2 w-4/5 font-bold">
                        <span className="text-blue">Interventions</span>
                        <span className="capitalize">
                          {building_name.toLowerCase()},{" "}
                          {intervention?.work?.catSupplier_id.toLowerCase()}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ))}
          {this.props.formatedInterventions.filter((item) => {
            if (
              item.date >=
                new Date(
                  ...new Date(this.props.startDate).toDateString().split("/")
                ).getTime() &&
              item.date <=
                new Date(
                  ...new Date(this.props.lastDate).toDateString().split("/")
                ).getTime()
            ) {
              return item;
            }
            return null;
          }).length === 0 && (
            <div className="w-full bg-white h-20 flex justify-center items-center my-2">
              <EmptyData text="Aucun planning disponible" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  formatedInterventions: state.interventions.formatedInterventions,
  isWaiting: state.interventions.isWaiting,
  startDate: state.calendar.startDate,
  lastDate: state.calendar.lastDate,
  startDay: state.calendar.startDay,
  lastDay: state.calendar.lastDay,
  month: state.calendar.month,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCalendarDate: (date) => {
      dispatch(setCalendarDate(date));
    },
    onFetchIntervenorInterventions: () => {
      dispatch(fetchIntervenorInterventions());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
