import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { IoEyeOffOutline } from "react-icons/io5";
import APP_ENV from "../../configs/env";

class Tenant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenants: [],
      globalId: "",
    };
    localStorage.removeItem("home");
  }

  componentDidMount = () => {
    let tenants = localStorage.getItem("tenants");
    let user = localStorage.getItem("user");
    tenants = JSON.parse(tenants);
    user = JSON.parse(user);
    this.setState({
      tenants: tenants?.activeTenant,
      globalId: user?.global_id,
      user: user,
    });
  };


  getData = (domain, tenantId, buildingId, budgetId, buildingName) => {
    const { globalId } = this.state;
    // localStorage.clear()
    const tenantURL = `${process.env.REACT_APP_PROTOCOL}://${domain}.${process.env.REACT_APP_ENV_URL}`;
    axios
      .post(`${tenantURL}/api/tenantLogin`, {
        global_id: globalId,
        tenant_id: tenantId,
        building_id: buildingId,
        budget_id: budgetId,
      })
      .then((response) => {
        
        localStorage.setItem("token", response.data.data.user.token);
        localStorage.setItem("user_id", response.data.data.user.id);
        localStorage.setItem("tenant_id", tenantId);
        localStorage.setItem("home", JSON.stringify(response.data.data?.home));
        localStorage.setItem("tenantUrl", APP_ENV.tenantURL);
        localStorage.setItem("domain", domain);
        localStorage.setItem("building_id", buildingId);
        localStorage.setItem("building_name", buildingName);
        localStorage.setItem(
          "coownerPayment",
          JSON.stringify(response.data.data.coownerPayment)
        );
        
        localStorage.setItem(
          "budgets",
          JSON.stringify(response.data.data.budget)
        );
        localStorage.setItem(
          "annuaire",
          JSON.stringify(response.data.data.annuaire)
        );
        localStorage.setItem(
          "appartments",
          JSON.stringify(response.data.data.appartments)
        );
        localStorage.setItem(
          "partiecoms",
          JSON.stringify(response.data.data.partiecoms)
        );
        localStorage.setItem(
          "typeIncidents",
          JSON.stringify(response.data.data.typeIncidents)
        );
        localStorage.setItem(
          "documents",
          JSON.stringify(response.data.data.documents)
        );
       
        window.location.reload();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  render() {
    const { tenants } = this.state;

    return (
      <div className="container mx-auto">
        <main className="pt-16">
          <section className="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4">
            {tenants.map((tenant) => (
              <div key={tenant.id}>
                <header className="flex items-center justify-between">
                  <h2 className="text-2xl  mb-2 leading-10 px-2 font-medium text-black capitalize">
                    {tenant?.name?.toLowerCase()}
                  </h2>
                </header>
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4">
                  {tenant.buildings.map((building) => (
                    <li key={building.id}>
                      {building.user_active ? (
                        <Link
                          to={
                            this.state.user.type === "intervenor"
                              ? "/interventions"
                              : "/dashboard"
                          }
                          onClick={(e) =>
                            this.getData(
                              tenant?.domains[0]?.domain,
                              tenant?.id,
                              building?.id,
                              building?.budget_id,
                              building?.name
                            )
                          }
                          className="hover:bg-light-blue-500 hover:border-transparent hover:shadow-lg group block rounded-lg p-4 bg-white border border-gray-200"
                        >
                          <dl className="grid sm:block lg:grid xl:block grid-cols-1 grid-rows-2 items-center">
                            <div>
                              <dd className="group-hover:text-white leading-6 text-lg font-extrabold text-black capitalize">
                                {building?.name?.toLowerCase()}
                              </dd>
                            </div>
                          </dl>
                          <dd className="group-hover:text-light-blue-200 text-sm font-medium sm:mb-4 lg:mb-0 xl:mb-4 capitalize">
                            {building?.address?.toLowerCase()}
                          </dd>
                        </Link>
                      ) : (
                        <div className="block rounded-lg p-4 bg-white opacity-30 shadow-lg border-2 border-gray-300">
                          <dl className="grid sm:block lg:grid xl:block grid-cols-1 grid-rows-2 items-center">
                            <div>
                              <dd className="group-hover:text-white leading-6 text-lg normal-case font-extrabold text-black">
                                {building?.name}
                              </dd>
                            </div>
                          </dl>
                          <dd className="text-sm font-medium sm:mb-4 lg:mb-0 xl:mb-4 ">
                            <div className="grid grid-cols-2 gap-4">
                              <div className="col-start-1 col-end-3 text-red-600">
                                Accès bloque
                              </div>
                              <div className="col-end-7 col-span-2">
                                <IoEyeOffOutline size={25} color="#DC2626" />
                              </div>
                            </div>
                          </dd>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </section>
        </main>
      </div>
    );
  }
}

export default Tenant;
