import React from "react";
import { Link } from "react-router-dom";
import Spinner from "../../common/Spinner";
import { IoAddOutline } from "react-icons/io5";
import PageTitle from "./../../common/PageTitle";
import Tabs from "./Tabs";
import { fetchClosedIncidents, fetchOpenIncidents } from "../../features/feeds/feedsSlice";
import { connect } from "react-redux";
class Incident extends React.Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(fetchOpenIncidents());
    dispatch(fetchClosedIncidents());
  };

  render() {
    return (
      <div className="container mx-auto">
        <PageTitle title="Liste des incidents" />
        {this.props.isWaiting && <Spinner />}
        {!this.props.isWaiting && (
          <main className="py-5 mb-20">
            <Tabs
              title1="incidents ouverts"
              title2="incidents fermés"
              openIncidents={this.props.openIncidents}
              incidentsClosed={this.props.closedIncidents}
            />
          </main>
        )}
        <Link
          to="/post-incident"
          className="bg-blue rounded-full text-white h-16 w-16 text-3xl fixed bottom-16 right-1.5 flex justify-center items-center opacity-80"
        >
          <span>
            <IoAddOutline className="inline-flex" />
          </span>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  openIncidents: state.feeds.filteredOpenIncidents,
  closedIncidents: state.feeds.filteredClosedIncidents,
  isWaiting: state.feeds.isWaiting,
});

export default connect(mapStateToProps, null)(Incident);
