const domain = localStorage.getItem("domain");
const tenant = localStorage.getItem("tenant_id");

const APP_ENV = new (function () {
  this.centralDomain = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ENV_BASEURL}/api`;
  this.baseURL = `${process.env.REACT_APP_PROTOCOL}://${domain}.${process.env.REACT_APP_ENV_URL}/api`;
  this.tenantURL = `${process.env.REACT_APP_PROTOCOL}://${domain}.${process.env.REACT_APP_ENV_URL}`;
  this.fileENDPOINT = `${this.tenantURL}/storage/tenant${tenant}`;
})();

export default APP_ENV;
