import React from "react";
import Popper from "popper.js";
import Avatar from "../common/Avatart";
import Api from "./../api";
import { IoCloseOutline, IoKeyOutline, IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import axios from "axios";

import Permission from "@easytool/react-permission";


const UserDropdown = () => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const popoverDropdownRef = React.createRef();
  const object = localStorage.getItem("user");
  const btnDropdownRef = React.createRef();
  const user = JSON.parse(object);

  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const doLogout = async () => {
    await axios
      .get(`${Api.basUrl}/logout/${user.global_id}`)
      .then((res) => {
        localStorage.clear();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const finalClass =
    "text-sm py-2 p-4 font-normal block w-full whitespace-no-wrap bg-transparent max-w-max";
  return (
    <div className="">
      <a
        className="block"
        href="#logout"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <Avatar className="mt-2" size="base" />
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "transform-none text-base z-10 float-left py-10 bg-white w-full h-screen list-none text-left shadow-lg"
        }
        style={{ minWidth: "12rem", position: "fixed" }}
      >
        <div className="-mt-3 border-b">
          <span className="font-bold px-3 text-xl capitalize">
            Information du compte
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                closeDropdownPopover();
              }}
              className="float-right px-3"
            >
              <IoCloseOutline size={25} className="text-red-400" />
            </Link>
          </span>
        </div>
        <div className="border-b text-left p-4">
          <Avatar
            className="cursor-pointer -ml-2"
          />
          <p className="pt-2 text-lg font-semibold capitalize">{user?.name}</p>
          <p className="text-sm text-gray-600">{user?.email}</p>
        </div>
        <Permission hasPermission={["resident", "coowner"]}>
          <Link 
            permission={[user?.type]}
            to="/Settings" 
            className={finalClass}
          >
            <p className="flex">
              <span className="mr-2">
                <IoSettingsOutline size={20} />
              </span>
              Paramètres
            </p>
          </Link>
        </Permission>
        
        <Link to="/edit_password" className={finalClass}>
          <p className="flex">
            <span className="mr-2">
              <IoKeyOutline size={20} />
            </span>
            Changer mon mot de passe
          </p>
        </Link>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <Link to="/" className={finalClass} onClick={(e) => doLogout()}>
          Se déconnecter
        </Link>
      </div>
    </div>
  );
};

export default UserDropdown;
