import React from "react";
import { useState } from "react";
import { GoComment } from "react-icons/go";
import { IoEyeOutline } from "react-icons/io5";
import SurveyOption from "./survey-option";
import { formatDate } from "../../../utils/utils";

export default function SurveyDetail(props) {
  const { info } = props.location.state;
  const [state, setState] = useState({
    lineClamp: false,
    showMoreOrLess: "Voir Plus",
  });

  function toggleLineClamp() {
    setState((prevState) => ({
      ...prevState,
      lineClamp: !prevState.lineClamp,
      showMoreOrLess: prevState.lineClamp ? "Voir Plus" : "Voir Moins",
    }));
  }

  return (
    <div className="py-9 card-bg mb-6">
      {/* Header */}
      <div className="my-8">
        <div className="px-4">
          {/* First part */}
          <div className="flex w-view">
            <span className="text-3xl font-bold">Sondage</span>
          </div>
          <div>
            {/* Tird line */}
            <div className="flex items-center justify-between">
              <span className="inline-block text-sm ml-1 align-top">
                Posté le{" "}
                {
                  formatDate(info.created_at)
                }
              </span>
              <div className="flex items-center">
                <div className="inline-block">
                  <img
                    className="rounded-full w-8 h-8 object-cover"
                    src="https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGZhY2UlMjBibGFja3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                    alt=""
                  />
                </div>
                <span className="inline-block ml-1 text-sm">Fatou Fall</span>
              </div>
            </div>

            <hr className="my-2 border-gray-900"></hr>

            <div>
              <div>
                <span className="text-xl font-bold">{info.title}</span>
              </div>
              <div>
                <p
                  className={
                    !state.lineClamp
                      ? "line-clamp-4 leading-loose"
                      : "line-clamp-none leading-loose"
                  }
                  onClick={toggleLineClamp}
                >
                  {info.description}
                </p>
                <span
                  className="text-sm font-thin text-gray-400"
                  onClick={toggleLineClamp}
                >
                  {state.showMoreOrLess}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-2">
          <SurveyOption />
        </div>
        {/* Images div */}
        <div className="w-full h-2/4 mt-2">
          {/* Ici on aura le caroussel */}
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5XwQay5KOe4SOQVWoQQwUFM7M62UDtZAgLA&usqp=CAU" alt=""/>
        </div>
        <div className="flex justify-around mt-2">
          {/* Nbr Views */}
          <div className="inline-block rounded-xl bg-gray-100 px-6 py-0 shadow-lg">
            <div className="flex-row">
              <IoEyeOutline className="inline-block" />{" "}
              <span className="inline-block">50</span>
            </div>
          </div>
          {/* Comments */}
          <div className="inline-block rounded-xl bg-gray-100 px-6 shadow-lg">
            <div className="flex-row">
              <GoComment className="inline-block" />{" "}
              <span className="inline-block">3</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
