import React from "react";
import { FaFileCsv, FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";
import FormatDate from "../../common/FormatDate";
import EmptyData from "../../common/EmptyData";
import PageTitle from "../../common/PageTitle";
import DownloadLink from "./DownloadLink";
import Api from "../../http/Api";
import Spinner from "../../common/Spinner";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      icons: {
        csv: <FaFileCsv size={30} color="blue" />,
        xlsx: <FaFileExcel size={30} color="#10b759" />,
        pdf: <FaFilePdf size={30} color="#dc3545" />,
        docx: <FaFileWord size={30} color="#0168fa" />,
      },
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    Api.getDocument()
      .then((res) => {
        this.setState({
          documents: res.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      });
  }

  getExtension = (fileName) => {
    return fileName.split(".").pop();
  };

  render() {
    const { documents, icons, loading } = this.state;
    return (
      <div className="container mx-auto mb-10">
        <PageTitle title="Documents" />
        {loading && <Spinner />}
        <main className="py-5">
          <div className="bg-white border-gray-200 rounded-lg px-2">
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {documents.map((doc) => (
                <div
                  key={doc.id}
                  className="py-2 flex bg-white border-b border-gray-300"
                >
                  <div className="w-12">
                    {icons[this.getExtension(doc.attachment)]}
                  </div>
                  <div className="flex-auto text-sm w-0 -mt-1">
                    <div className="font-medium truncate">{doc.title}</div>
                    <div className="text-sm text-gray-500">
                      {FormatDate(doc.created_at)}
                    </div>
                  </div>
                  <div className="flex flex-col w-18 font-medium items-end">
                    <DownloadLink
                      attachment={doc.attachment}
                      src={doc.attachment_url}
                    />
                  </div>
                </div>
              ))}
              {!loading && documents.length === 0 && (
                <EmptyData text="Aucun document pour le moment." />
              )}
            </dd>
          </div>
        </main>
      </div>
    );
  }
}

export default Documents;
