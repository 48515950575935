import Badge from "../../common/badgets/Badget";
import Currency from "../../common/Currency";
import FormatDate from "../../common/FormatDate";

export default function Installment ({date_limit, time, amount, payment_method, type}){


    
return (
    <li className="mb-2 ml-2 border-b boder-gray-100">
        <div className="flex items-center mb-10">
            <div className="-ml-2 bg-black rounded-full h-4 w-4 z-10"></div>
            <div className="flex-1 ml-4 font-extrabold">{ FormatDate(date_limit) } à {time}
                <span className="absolute right-2 font-semibold text-blue opacity-100">Versement</span>
            </div>
        </div>
        <div className="flex-1 items-center mb-1">
            <div className="ml-6 -mt-6">
                <span className="text-gray-600">
                    {payment_method === "CHEQU" 
                     ? "Chèque" 
                     : payment_method === "CASH"
                     ? "Cash"
                     : payment_method === "VIREM"
                     ? "Virement"
                     : payment_method === "MOBMO"
                     ? "Mobile Money"
                     : ""
                    }
                </span>
                <span className="absolute right-2 font-extrabold">{Currency(amount)} CFA</span>
            </div>
        </div>
        <div className="flex-1 items-center mb-4">
            <div className="ml-6 pt-2">
             {type === "CREDIT" 
             ? <Badge text="Crédit" type="success" />
             : <Badge text="Débit" type="danger" />
             }
            </div>
        </div>
    </li>
    );
}