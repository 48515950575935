function FormatDate(date) {
    const date2 = new Date(date);

    const fullYear = date2.getUTCFullYear();
    const  day = date2.getUTCDate();
    const  month = date2.getMonth();

    const event = new Date(Date.UTC(fullYear,month,day));
    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
 return  event.toLocaleDateString("fr-FR", options);
}


export default  FormatDate
