import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IoSearchOutline } from "react-icons/io5";
import List from "./List";
import ListItem from "./ListItem";
import Empty from "../../common/EmptyData";

const Tabs = (props) => {
  
  const { generalAssemblies } = props;
  const [state, setState] = useState({
    text: "",
    generalAssemblies: generalAssemblies,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      generalAssemblies,
    }));
  }, [generalAssemblies]);

  function handleChange(event) {
    let fieldName = event.target.name;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));

    setState((prevState) => ({
      ...prevState,
      generalAssemblies: generalAssemblies.filter((item) => {
        let title = item?.title.toLowerCase().replace(/ /g, "");
        let text = event.target.value.toLowerCase().replace(/ /g, "");

        return title.normalize().includes(text.normalize()) ? item : null;
      }),
      
    }));
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="px-2">
            <IoSearchOutline
              size={24}
              className="absolute z-20 m-1 mt-2 text-blue-400 font-bold"
            />
            <input
              type="text"
              name="text"
              value={state.text}
              autoComplete="off"
              onChange={handleChange}
              className="pl-8 p-1 mb-6 bg-gray-200 w-full text-blod rounded-xl h-10 relative outline-none border-gray-200"
              placeholder="Rechercher"
            />
          </div>
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="px-4 py-2 flex-auto bg-white border">
              <div className="tab-content tab-space">
                    <List>
                      {state.generalAssemblies.map((currentAssembly) => (
                        <ListItem
                          key={currentAssembly.id}
                          assembly={currentAssembly}
                        />
                      ))}
                      {state.generalAssemblies.length === 0 && (
                        <Empty text="Aucune assemblée pour le moment." />
                      )}
                    </List>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Tabs.propTypes = {
  generalAssemblies: PropTypes.array,
};

Tabs.defaultProps = {
  generalAssemblies: []
};
export default Tabs;
