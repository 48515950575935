import { IoPersonCircleOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import EmptyData from "../../common/EmptyData";


export default function Listresident({residents, openTab}){

    return(
            <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                <div className="mt-2">
                    {residents.map(resident =>
                    <Link to={`resident/${resident.id}`}  key={resident.id} className="px-1 mb-8 flex flex-row sm:flex-row items-center justify-between">
                        <IoPersonCircleOutline size={50}/>
                        <dd className="text-left mt-0 ml-4 flex-1 border-b border-gray-400">
                            <p className="text-lg font-medium text-gray-900 capitalize truncate w-60">{resident.first_name.toLowerCase()} {resident.last_name.toUpperCase()}</p>
                            <p className="text-base lowercase text-gray-600">
                                <span className="px-1 truncate w-60">
                                    {resident.appartment_title} 
                                </span>
                            </p>
                        </dd>
                        <IoChevronForwardOutline />
                    </Link>
                    )}
                    {residents.length === 0 && (
                      <EmptyData text="Aucun résident pour le moment." />
                    )}
                </div>
            </div>
    )
}