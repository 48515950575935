import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "moment/locale/fr";
import Firebase from "../firebase/firebase";
import PageTitle from "./../../common/PageTitle";
import EmptyData from "../../common/EmptyData";
import FirstLetter from "../../common/firstLetter";

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      tenant_id: localStorage.getItem("tenant_id"),
      building_id: localStorage.getItem("building_id"),
      user_id: localStorage.getItem("user_id"),
    };
  }

  componentDidMount() {
    const { tenant_id, building_id, user_id } = this.state;
    const notifRef = Firebase.database()
      .ref(tenant_id + "/notifications")
      .orderByChild("index_notifiable_id")
      .equalTo(`${building_id}-${user_id}`);
    notifRef.on("value", (snapshot) => {
      const notifications = snapshot.val();
      const notificationsList = [];
      for (let id in notifications) {
        notificationsList.push({ id, ...notifications[id] });
      }
      this.setState({
        notifications: notificationsList.sort((x, y) => new Date(y.created_at) - new Date(x.created_at)),
      });
    });
  };



  updateNotification(id) {
    Firebase.database()
      .ref(`${this.state.tenant_id}/notifications/${id}`)
      .update({
        read_at: new Date(),
      });
  };

  componentWillUnmount(){
    this.updateNotification();
    // this.setState(prevStat)
  }
  render() {
    const { notifications } = this.state;
    return (
      <div className="container mx-auto mb-40">
        <PageTitle title="Notifications" />
        <main className="py-5 bg-white rounded-lg mt-6 ">
          {notifications.map((notification) => (
            <div key={notification.id} className={` px-2 py-2 `}>
              <div className="w-12 h-12 bg-gray-200">
                <p className="font-extrabold text-center p-2 text-2xl">
                  {FirstLetter(notification.data.type)}
                </p>
              </div>
              <Link
                to={notification.link.replace('work', 'incident')}
                onClick={() => this.updateNotification(notification.id)}
                className="flex justify-between ml-10 -mt-14 items-center  mb-2 "
              >
                <dl>
                  <dd className="text-left ml-6 pt-1 flex-1 ">
                    <h1
                      className={
                        notification.read_at === "NULL"
                          ? "font-bold text-black text-lg"
                          : "font-bold text-gray-600"
                      }
                    >
                      {" "}
                      {notification.data.type}
                    </h1>
                    <p
                      className={
                        notification.read_at === "NULL"
                          ? "w-72 text-sm font-bold text-black truncate"
                          : "w-72 text-sm text-gray-500 truncate"
                      }
                    >
                      {notification.data.title}
                    </p>
                    <small className="text-sm text-gray-500 font-semibold ">
                      {moment(notification.created_at).fromNow()}
                    </small>
                  </dd>
                </dl>
              </Link>
            </div>
          ))}
          {notifications.length === 0 && (
            <EmptyData text="Il y a aucune notification pour vous." />
          )}
        </main>
      </div>
    );
  }
}

export default Notification;
