import React from "react";
import Nav from "./Nav";
import NavItem from "./NavItem";
import Submenu from "./submenu/Submenu";

import { GiHammerNails } from "react-icons/gi";
import { FaCalendarAlt } from "react-icons/fa";
import {
  IoBookOutline,
  IoAlbumsOutline,
  IoDocumentOutline,
  IoLayersOutline,
  IoWarningOutline,
  IoConstructOutline,
} from "react-icons/io5";

import Permission from "@easytool/react-permission";
import Role from "../permission";
import { useWindowDimensions } from "../utils/utils";
import Popper from "popper.js";
import { NavLink, useLocation } from "react-router-dom";

const MainMenu = () => {
  const showMenu = localStorage.getItem("building_id");
  const location = useLocation();
  const pathname = location.pathname.replace(/^\/+|\/+$/g, "").split("/")[0] ;

  const { width } = useWindowDimensions();

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const popoverDropdownRef = React.createRef();
  const btnDropdownRef = React.createRef();

  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  
  if (showMenu) {
    return ( 
      pathname !== "tenants"  && (
        <>
        <div className="h-17 bg-white fixed inset-x-0 bottom-0 shadow-xl border-t border-gray-300 flex-shrink-0 overflow-x-scroll px-1 scrollbar-hidden">
          <Nav>
            <Permission hasPermission={Role}>
              <NavItem href="/dashboard" permission={Role}>
                <div className="flex-col align-middle text-center">
                  <IoAlbumsOutline size={30} className="inline-block" />
                  {width > 505 && <span className="block">Accueil</span>}
                </div>
              </NavItem>
              <NavItem href="/interventions" permission={["intervenor"]}>
                <div className="flex-col align-middle text-center">
                  <GiHammerNails size={30} className="inline-block" />
                  {width > 505 && <span className="block">Interventions</span>}
                </div>
              </NavItem>
              <NavItem href="/calendrier" permission={["intervenor"]}>
                <div className="flex-col align-middle text-center">
                  <FaCalendarAlt size={30} className="inline-block" />
                  {width > 505 && <span className="block">Calendrier</span>}
                </div>
              </NavItem>
              <NavItem href="/documents" permission="coowner">
                <div className="flex-col align-middle text-center">
                  <IoDocumentOutline size={30} className="inline-block" />
                  {width > 505 && <span className="block">Documents</span>}
                </div>
              </NavItem>
    
              <NavItem href="/annuaire" permission={Role}>
                <div className="flex-col align-middle text-center">
                  <IoBookOutline size={30} className="inline-block" />
                  {width > 505 && <span className="block">Annuaire</span>}
                </div>
              </NavItem>
              <NavLink 
                className="block px-2 pt-2 rounded-md"
                permission="coowner"
                to="#submenu" 
                ref={btnDropdownRef}
                onClick={(e) => {
                    e.preventDefault();
                    dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                }}
              >
                <div className="flex-col align-middle text-center">
                  <IoLayersOutline size={30} className="inline-block" />
                  {width > 505 && <span className="block">Autres</span>}
                </div>
            </NavLink>
            </Permission>
            <Permission hasPermission={["resident", "personnel"]}>
                  <NavItem href="/incidents" permission={Role}>
                    <div className="flex-col align-middle text-center">
                      <IoWarningOutline size={30} className="inline-block" />
                      {width > 505 && <span className="block">Incidents</span>}
                    </div>
                  </NavItem>
                  <NavItem href="/works" permission={Role}>
                    <div className="flex-col align-middle text-center">
                      <IoConstructOutline size={30} className="inline-block" />
                      {width > 505 && <span className="block">Travaux</span>}
                    </div>
                  </NavItem>
            </Permission>
          </Nav>
        </div>
        <Submenu 
         dropdownPopoverShow={dropdownPopoverShow}
         popoverDropdownRef={popoverDropdownRef}
         />
      </>
      )
     
    );

  } else {
    return <></>;
  }
};

export default MainMenu;
