import PropTypes from "prop-types";
import Badge from "../../common/badgets/Badget";
import FormatDate from "../../common/FormatDate";

const  EvalWithoutIncident = ({evaluation}) => {




    return(
        <>
            <div className=" px-4 py-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                   Titre:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {evaluation.title}
                </dd>
            </div>  
            <div className=" px-4 py-4  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                    À qui ?:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {evaluation.evaluable_id}
                </dd>
            </div>  
            <div className=" px-4 py-4  flex">
                <dt className="text-sm font-medium text-gray-500">
                   Statut:
                </dt>
                <dd className="text-sm text-gray-900 ml-6 ">
                {evaluation.statut === "Publié" ? 

                  <Badge text={evaluation.statut} type="success" />
                  :
                  <Badge text={evaluation.statut} type="danger" />
                  
                }
                </dd>
            </div>  
            <div className="px-4 py-4  flex">
                <dt className="text-sm font-medium text-gray-500">
                    Date de declaration:
                </dt>
                <dd className="text-sm text-gray-900 absolute right-6 px-4">
                {FormatDate(evaluation.date_envoi)}
                </dd>
            </div>  
            <div className=" px-4 py-4 flex">
                <dt className="text-sm font-medium text-gray-500">
                    Date de clôture:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 absolute right-6 px-4">
                  {FormatDate(evaluation.closed_at)}
                </dd>
            </div>  

        </> 
    );
}



EvalWithoutIncident.propTypes = {
    /**
     * The object .
     */
    evaluation: PropTypes.object,
   
};

EvalWithoutIncident.defaultProps = {

    evaluation: {},
}

export default  EvalWithoutIncident ;