import FirstLetter from "../../common/firstLetter"
import FormatDate from "../../common/FormatDate"


const Reply = ({replies, comment}) => {
    
    return( 

        <div className="flex px-6 w-full mb-6 ">
            <div className="inline-block">
                <div className="w-10 h-10 rounded-full mr-2 bg-gray_custom">
                <p className="font-extrabold text-center p-1 text-xl text-white">
                  {FirstLetter(replies.user.name)}
                </p>
              </div>
            </div>
            <div className="w-full mt-1">
                <div className="flex-col rounded-lg w-full p-1">
                    <div className="flex justify-between items-center">
                        <div className="inline-block">
                            <span className="font-bold">{replies.user.name}</span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <p>{replies.message }</p>
                    </div>
                    <div className="flex justify-start items-center text-sm w-full mt-2">
                        <div className="font-light text-gray-500 flex items-center justify-center space-x-1">
                            <small className="self-center">.</small>
                            <div className="hover:underline">
                               <small>{FormatDate(replies.created_at)} à {replies.time}</small>
                            </div>
                        </div>
                  </div>
                </div>
            </div>
        </div>
    )
}

export default Reply