import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/utils";
const user_id = localStorage.getItem("user_id");

export default function Reservation(props) {
  const [state] = useState({
    info: props.location.state.info,
  });

  return (
    <div className="w-full pt-20 pb-20 px-3 flex">
      <div className="flex-col w-full">
        <div className="flex justify-between items-center">
          <span className="text-3xl font-bold">Détails réservation</span>
          {state.info.declared_by.id === parseInt(user_id) && (
            <div className="inline-block">
              <Link
                to={{
                  pathname: `/add-reservation`,
                  state: {
                    info: {
                      ...state.info,
                    },
                  },
                }}
              >
                <FiEdit className="inline-block" size={25} />
              </Link>
            </div>
          )}
        </div>
        <div className="flex flex-col content-start mb-4">
          <div className="my-2">
            <span>Espace réservé :</span>
            <span className="text-blue">{state.info.space.name}</span>
          </div>
          <div className="my-2">
            <span>Lieu :</span>
            <span className="text-blue">{state.info.space.level}</span>
          </div>
          <div className="my-2">
            <span>Date de début :</span>
            <span className="text-blue">
              {formatDate(state.info.start_date)}
            </span>
          </div>
          <div className="my-2">
            <span>Date de fin :</span>
            <span className="text-blue">{formatDate(state.info.end_date)}</span>
          </div>
          <div className="my-2">
            <span className="text-blue">
              {`De ${state?.info?.start_time.split(":")[0]}h ${
                state?.info?.start_time.split(":")[1]
              }mn à ${state?.info?.end_time.split(":")[0]}h ${
                state?.info?.end_time.split(":")[1]
              }mn`}
            </span>
          </div>
          <div className="my-2">
            <span>Nombre de participants :</span>
            <span className="text-blue">{state.info.nbr_participant}</span>
          </div>
          <div className="my-2">
            <span>Services réservés :</span>
            {state.info.services.map((item, i) => (
              <span key={i}>
                <span className="text-blue">
                  {item.name}
                  {i + 1 < state.info.services.length ? "," : ""}
                  </span>
                <span>, </span>
              </span>
            ))}
          </div>
          <div className="my-2">
            <span>Status :</span>
            <span
              className="px-2 text-sm border-2 rounded"
              style={
                state.info.status === "WITNG"
                  ? {
                      backgroundColor: "#FF8800",
                      border: "2px solid #FF8800",
                      color: "#fff",
                    }
                  : state.info.status === "RFUSE"
                  ? {
                      backgroundColor: "#FF0000",
                      border: "2px solid #FF0000",
                      color: "#fff",
                    }
                  : state.info.status === "APPRV"
                  ? {
                      backgroundColor: "#009E6B",
                      border: "2px solid #009E6B",
                      color: "#fff",
                    }
                  : {
                      backgroundColor: "#aaa",
                      border: "2px solid #aaa",
                      color: "#fff",
                    }
              }
            >
              {
                {
                  WITNG: "En Attente de validation",
                  RFUSE: "Rejetée",
                  APPRV: "Validée",
                  CANCL: "Annulée",
                  CLOSE: "Fermée",
                }[state.info.status]
              }
            </span>
            {state.info?.motive && (
              <>
                <span className="pl-2">Motif :</span>
                <span className="text-blue">{state.info.motive}</span>
              </>
            )}
          </div>
          {state.info?.comment && (
            <div className="mt-5">
              <h4 className="font-bold">Commentaire</h4>
              <div className="flex flex-wrap content-start mt-1">
                <textarea
                  type="text"
                  name="description"
                  value={state.info.comment}
                  readOnly
                  placeholder="Message"
                  className="rounded border-gray-300 w-full h-32 mx-2 my-2"
                  style={{
                    backgroundColor: "#F5F5F5",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mb-4"></div>
      </div>
    </div>
  );
}
