import React from "react";

import PageTitle from "../../common/PageTitle";
import Spinner from "../../common/Spinner";
import Api from "./../../api";
import Tabs from "./Tabs";

class Evaluations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evaluationsCloses: [],
      evaluationsOuvert: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    Api.getEvaluations().then((res) => {

      let opendEval = res.data.data.evaluationsOuvert?.sort(function(a, b){
        return (a.date_envoi > b.date_envoi) ? -1 : 1
      })

      let closdEval = res.data.data.evaluationsCloses?.sort(function(a, b){
        return (a.date_envoi > b.date_envoi) ? -1 : 1
      })
      this.setState({
        evaluationsOuvert: opendEval,
        evaluationsCloses: closdEval,
        loading: false,
      });
    });
  };

  render() {
    const { evaluationsOuvert, evaluationsCloses, loading } = this.state;
    return (
      <div className="container">
        <PageTitle title="Liste des evaluations" />
        {loading ? (
          <Spinner />
        ) : (
          <main className="py-5 px-0 mt-6 w-full rounded-lg  mb-32">
            <Tabs
              title1="evaluations ouvertes"
              title2="evaluations fermées"
              evaluationsOuvert={evaluationsOuvert}
              evaluationsCloses={evaluationsCloses}
            />
          </main>
        )}
      </div>
    );
  }
}

export default Evaluations;
