import { useEffect, useState } from "react";
import Currency from "../../common/Currency";
import FormatDate from "../../common/FormatDate";
import CustomAccount from "./CustomAccount";



export default function Account ({openTab}){
   
    const [paymentInProgress, setInProgress] = useState({});
    const [apparts, setAppart] = useState([]);
    const [totalUnpai, setTotalUnpai] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [totalInstallment, setTotalInstallment] = useState(0);
    const [availableBalance, setAvailableBalance] = useState(0);

    useEffect(() =>{
        let coownerPayment =  localStorage.getItem('coownerPayment');
        let payment = JSON.parse(coownerPayment);
      
        setInProgress(payment?.paymentInProgress)
        setAppart(payment?.paymentInProgress?.apparts)
        setTotalUnpai(payment?.totalUnpai)
        setTotalPaid(payment?.totalPaid)
        setAvailableBalance(payment?.availableBalance)
        setTotalInstallment(payment?.totalInstallment)

    }, []);

    return (
        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
           {paymentInProgress &&
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="py-1 sm:px-0">
                    <div className="bg-white border-gray-200 rounded-lg px-4 py-2">
                        <div className="grid grid-cols-6 gap-4">
                            <div className="col-start-1 col-end-8 ">
                                Appels de fonds:<span className="text-md font-bold"> {paymentInProgress?.payment_periodicity}</span>
                            </div>
                            <div className="col-end-8 col-span-1 -mt-8">
                                <p className="bg-yellow_custom px-2 text-xs font-bold rounded">En cours</p>
                            </div>
                            <div className=" col-start-1 col-end-8 ">
                                Date limite de paiement: 
                                <span className="text-md font-bold"> {FormatDate(paymentInProgress?.payment_deadline)}</span>
                            </div>
                            <div className=" col-start-1 col-end-8 text-md">
                                <ul>
                                    {apparts?.map((appart)=>
                                        <li key={appart.title}>
                                            <span className="text-gray-600 opacity-100">{appart.title}: </span> 
                                            <span className=" font-bold">{Currency(appart.amount)} CFA</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           }

            <CustomAccount title="Total des impayés" amount={totalUnpai} />
            <CustomAccount title="Total des versements" amount={totalInstallment} />
            <CustomAccount title="Total des paiements" amount={totalPaid} />
            <CustomAccount title="Solde disponible" amount={availableBalance} />
        </div>
    );
}