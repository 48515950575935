import electricite from '../assets/flash-outline.svg';
import jardinage from '../assets/jardinage.svg';
import plomberie from '../assets/plomberie.svg';
import nettoyage from '../assets/nettoyage.svg';
import dechets from '../assets/dechets.svg';
import ascenseur from '../assets/ascenseur.svg';
import climatisation from '../assets/climatisation.svg';
import piscine from '../assets/piscine.svg';
import batiment from '../assets/batiment.svg';
import peinture from '../assets/peinture.svg';
import securite from '../assets/securite.svg';
import desinfection from '../assets/desinfection.svg';
import concierge from '../assets/doorman.svg';
import syndic from '../assets/team-leader.svg';

const TypeIncident = {
    "Electricité": electricite,
    "Jardinage": jardinage,
    "Plomberie": plomberie ,
    "Nettoyage": nettoyage,
    "Déchets": dechets,
    "Ascenseur":ascenseur ,
    "Climatisation": climatisation,
    "Piscine": piscine,
    "Bâtiment": batiment,
    "Peinture": peinture,
    "Securité": securite,
    "Désinfection": desinfection,
    "Concierge": concierge,
    "Syndic": syndic,
  }

export default TypeIncident